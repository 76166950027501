import { useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { WP_API_TOKEN } from "src/api/config";
import { ListItemIcon, MenuItem } from "@mui/material";
import PortfolioApi, { ServiceApi } from "./PortfolioApi";
import {useLocation} from 'react-router-dom';

/*COMPONENT*/
//--contentList | default
export default function ContentList() {

  //CONSTANTS
  const navigate = useNavigate()
  const location = useLocation();

  //STATES
  //--app states

  //FUNCTIONS
  //--navigation functions
  //__go to add new page
  function handleAddNewPageClick() {
    navigate(`/dashboard/content/add`);
  }

  function handleListItemEdit(contentItem){
    console.log("EDITING POST ITEM", contentItem);
    navigate(`/dashboard/content/edit/${location.state.content.type}/${contentItem.id}`, {
      state: {
        content: contentItem
      }
    });
  }
  
  return (
    <Box sx={{ minHeight: "100vh" }}>

      {/* Breadcrumbs / Actions */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Content
          </Typography>
        </Breadcrumbs>

        <Button
          color="primary"
          onClick={handleAddNewPageClick}
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Add New Page
        </Button>
      </Box>

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
        <ContentTable
          content={location.state.content}
          onEditItem={handleListItemEdit}
         />
      </Box>
      
    </Box>
  );
}

//--content Table | ContentTable

const ContentTable = ({ content, onEditItem }) => {

  //CONSTANTS
  const navigate = useNavigate();

  //STATES
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //FUNCTIONS
  //--navigation functions
  //--go to edit page with pageId
  function handleContentItemEdit(row){
    //use the row data to get the Item Object from the Data
    const contentItem = data.find(item => item.id === row.id);
    console.log("ABOUT TO EDITING POST ITEM", contentItem);
    onEditItem(contentItem);
  }

  function handleContentTypeListLoad(type){
    
    switch(type){
      case 'portfolio':
        return PortfolioApi;
      case 'service':
        return ServiceApi;
      default:
        return PortfolioApi;
    }
  }

  //EFFECTS
  //Check If Prop (contentType) is not empty. If Not: Show Alert
  useEffect(() => {
    if(!content){
      setIsError(true);
      console.log("No Content Provided", content);
    }
  }, []);


  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const json = await handleContentTypeListLoad(content.type).getAll();
        setData(json.data);
        setRowCount( json ? Object.keys(json).length : 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'title',
        header: 'Title',
      },
      //column definitions...
      {
        accessorKey: 'author',
        header: 'Author',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'last_modified',
        header: 'Last Modified',
      },
      //end
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: false},
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        onClick={() => {
          closeMenu();
          handleContentItemEdit(row);
        }}
      >
        <ListItemIcon>
          <Icon icon="carbon:edit" />
        </ListItemIcon>
        Edit
      </MenuItem>,

      <MenuItem>
        <ListItemIcon>
          <Icon icon="carbon:delete" />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    //--Apply Styling
    muiTablePaperProps:{
      variant: 'outlined',
      elevation: 0,
      sx:{
        border: '1px solid #3c424d',
        borderRadius: '4px',
        boxShadow: 'none',
      }
    }
  });

  return <MaterialReactTable table={table} />;
};
