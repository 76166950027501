import { Icon } from "@iconify/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterForm from "../components/RegisterForm";

export default function Register() {

  //CONSTANTS
  const navigate = useNavigate();

  //CONTEXT

  //STATES

  //FUNCTIONS
  const goToDashboard = () => {
    navigate("/dashboard");
  };

  //USE EFFECTS

  // RENDER
  return (
    <div
      className=""
      style={{
        fontFamily: "Poppins, sans-serif",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        backgroundImage: "url(/images/footer_bg.jpeg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <RegisterForm onRegisterSuccess={() => {
        goToDashboard();
      }} />

    </div>
  );
}
