/* This Functions Defines the API Request Configuration. This includes the API URL, the API Key */
import axios from 'axios';

const baseUrl = "https://api.soimagine.co.za/wp-json"
const namespace = "/sa9ecms/v1/"

//create an axios instance
const api = axios.create({
  baseURL: baseUrl + namespace,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
