import { useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
 //Note: this is TanStack React Query V5
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { WP_API_TOKEN } from "src/api/config";
import { CardContent, InputLabel, ListItemIcon, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardActions,
  Divider,
  FormControl,
  Input,
  Option,
  Select,
} from "@mui/joy";
import { motion } from "framer-motion";

/*CONSTANTS*/
const columns = [
  {
    accessorKey: "page_title",
    header: "Title",
  },
  {
    accessorKey: "page_author  ",
    header: "Author",
  },
  {
    accessorKey: "page_status",
    header: "Status",
  },
  {
    accessorKey: "page_last_modified",
    header: "Last Modified",
  },
];
const fetchSize = 25;

/*COMPONENT*/
//--blogList | default
export default function BlogList() {
  //CONSTANTS
  const navigate = useNavigate();

  //STATES
  //--app states
  const [isAddingNewBlog, setIsAddingNewBlog] = useState(false);

  //FUNCTIONS
  //--navigation functions
  function handlePageClick(pageId) {
    navigate(`/dashboard/blog/edit/${pageId}`);
  }
  //__go to add new page
  function handleAddNewPageClick() {
    setIsAddingNewBlog(true);
  }

  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* Breadcrumbs / Actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Blog
          </Typography>
        </Breadcrumbs>

        {
          !isAddingNewBlog
          ?
          <Button
          color="primary"
          onClick={handleAddNewPageClick}
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Add New Page
        </Button>
        :
        <Button
          color="danger"
          onClick={() => setIsAddingNewBlog(false)}
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Cancel
        </Button>
        }
        
      </Box>

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
          overflow: "auto",
        }}
      >
      {
        isAddingNewBlog
        ?
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <AddBlogForm onBlogAdded={()=>setIsAddingNewBlog(false)} onBlogAddCancel={()=>setIsAddingNewBlog(false)} />
        </motion.div>
        :
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <BlogTable />
        </motion.div>
      }
      </Box>
      
    </Box>
  );
}

//--blog Table | BlogTable
const BlogTable = () => {
  //CONSTANTS
  const navigate = useNavigate();

  //STATES
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //FUNCTIONS
  //--navigation functions
  //--go to edit page with pageId
  function handlePageClick(pageId) {
    navigate(`/dashboard/blog/edit/${pageId}`,{
      state: { id: pageId }
    });
  }

  //EFFECTS
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        
        const response = await fetch(
          "https://api.soimagine.co.za/wp-json/sa9ecms/v1/blog-post",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        console.log(json);
        setData(json);
        setRowCount(json ? Object.keys(json).length : 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "title",
        header: "Title",
      },
      //column definitions...
      {
        accessorKey: "author",
        header: "Author",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      //end
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: false },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        onClick={() => {
          closeMenu();
          handlePageClick(row.id);
        }}
      >
        <ListItemIcon>
          <Icon icon="carbon:edit" />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem>
        <ListItemIcon>
          <Icon icon="carbon:delete" />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    //--Apply Styling
    muiTablePaperProps: {
      variant: "outlined",
      elevation: 0,
      sx: {
        border: "1px solid #3c424d",
        borderRadius: "4px",
        boxShadow: "none",
      },
    },
  });

  return <MaterialReactTable table={table} />;
};

//--add blog Form | AddBlogForm
const AddBlogForm = ({ onBlogAdded, onBlogAddCancel }) => {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      blog_title: "",
      blog_author: "",
      blog_status: "",
      blog_content: "",
    },
    validationSchema: Yup.object({
      blog_title: Yup.string(),
      blog_author: Yup.string(),
      blog_status: Yup.string(),
      blog_content: Yup.string(),
    }),
    onSubmit: async (values) => {
      await AddNewBlog(values);
    },
  });

  //STATES
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newBlogItem, setNewBlogItem] = useState({});

  //FUNCTIONS
  async function AddNewBlog(values) {
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://api.soimagine.co.za/wp-json/sa9ecms/v1/blog/add",
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setNewBlogItem(json);

      setTimeout(()=>{
        setIsSubmitting(false);
        onBlogAdded();
      }, 2000);

    } catch (error) {
      setIsError(true);
      setIsSubmitting(false);
      console.error(error);
      return;
    }
  }

  function CancelAddNewBlog() {
    onBlogAddCancel();
  }


  //RENDER
  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        borderRadius: "4px",
        boxShadow: "none",
        border: "1px solid #3c424d",
      }}
    >
      {/*header*/}
      <Typography level={"title-md"} sx={{ mb: 2 }}>
        Add New Blog
      </Typography>
      <Divider />

      {/*form*/}
      <CardContent>
        <FormControl>
          <InputLabel htmlFor="blog_title">Title</InputLabel>
          <Input
            id="blog_title"
            name="blog_title"
            type="text"
            value={formik.values.blog_title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="blog_status">Status</InputLabel>
          <Select
            variant="outlined"
            id="blog_status"
            name="blog_status"
            defaultValue="draft"
            value={formik.values.blog_status}
            onChange={(e, val)=>formik.setFieldValue("blog_status", val)}
            onBlur={formik.handleBlur}
          >
            <Option value="draft">Draft</Option>
            <Option value="published">Published</Option>
            <Option value="trash">Trash</Option>
          </Select>
        </FormControl>

      </CardContent>

      {/*actions*/}
      <CardActions>
        <Button color="danger" onClick={onBlogAddCancel} size="sm">
          Cancel
        </Button>

        <Button
          color={isError ? "danger" : "primary"}
          disabled={isSubmitting}
          onClick={formik.handleSubmit}
          size="sm"
          startDecorator={
            isSubmitting && !isError 
            ?
            <Icon icon="svg-spinners:eclipse-half" style={{ fontSize: "16px", color: "#fff" }} />
            :
            <Icon icon="mdi:download" style={{ fontSize: "16px", color: "#fff" }} />
          }
        >
          {
            isSubmitting && !isError
            ? 
            "Adding..." 
            : 
            !isSubmitting && isError
            ?
            "Retry"
            :
            !isSubmitting && isError && newBlogItem?.id
            ?
            "Added"
            :
            "Add"
          }
        </Button>
      </CardActions>
    </Card>
  );
};
