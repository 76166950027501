import React from "react";
//Editor
import { useNode } from "@craftjs/core";
import {
  Box,
  Divider,
  Input,
  Typography,
  Button as JButton,
  Card,
  CardContent,
  Stack,
} from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

export default function IconBoxStyle2({ features, iconSrc, title, subTitle }) {
  return (
    <div className="cs_iconbox cs_style_2">
      <div className="cs_iconbox_icon">
        <img src={iconSrc} alt="Icon" />
      </div>
      <h2 className="cs_iconbox_title cs_white_color cs_medium cs_fs_29">
        {title}
      </h2>
      <p className="cs_iconbox_subtitle cs_gray_color_2">{subTitle}</p>
      <ul className="cs_iconbox_features cs_mp0 cs_white_color cs_medium">
        {features?.map((item, index) => (
          <li key={index}>– {item}</li>
        ))}
      </ul>
    </div>
  );
}

export function FeaturesCard({ features, iconSrc, title, subTitle }) {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        //justifyContent: "center",
        p: 2,
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems={"center"}
      >
        <Typography level="title-md"
        sx={{
          fontSize: 20,
          fontWeight: 600,
          color: "text.primary",
          fontFamily: 'Circular'
        }}
        >{title}
        </Typography>

        <Divider orientation="vertical" flexItem /> 
        <Box>
          <motion.img
            src={iconSrc}
            alt="Icon"
            //spin the icon infinitely
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 2,
              repeat: Infinity,
              repeatType: "loop",
              ease: "linear"
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            style={{
              width: 35,
              height: 35,
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>

      <CardContent>
        <Typography level="body-sm">{subTitle}</Typography>
        <Divider sx={{ my: 2 }} />

        <ul className="cs_iconbox_features cs_mp0 cs_white_color cs_medium">
          {features?.map((item, index) => (
            <li key={index}>– {item}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

// Define the editable version of your component
// Replace IconBox2 with your component's name
export const EditableIconBox2 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}
      <IconBoxStyle2 {...props} />
      {/* Component End */}
    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  const {
    actions: { setProp },
    title,
    subTitle,
    iconSrc,
    features,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    iconSrc: node.data.props.iconSrc,
    features: node.data.props.features,
  }));

  return (
    <Accordion title="Icon Box Settings">
      <AccordionSummary>
        <Typography variant="body2">Icon Box Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">Title</Typography>
            <Input
              value={title}
              onChange={(e) =>
                setProp((props) => (props.title = e.target.value))
              }
              placeholder="Title"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">Sub Title</Typography>
            <Input
              value={subTitle}
              onChange={(e) =>
                setProp((props) => (props.subTitle = e.target.value))
              }
              placeholder="Sub Title"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">Icon</Typography>
            <FilePickerOpener
              onPick={(file) => {
                setProp((props) => (props.iconSrc = file.url));
              }}
            >
              <JButton>Choose Icon</JButton>
            </FilePickerOpener>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">Features</Typography>
            <RichTextEditor
              value={features}
              onChange={(value) => setProp((props) => (props.features = value))}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

// Define CraftJS specific properties for your component
EditableIconBox2.craft = {
  displayName: "IconBox2",
  props: {
    title: "Title",
    subTitle: "Sub Title",
    iconSrc: "https://cdn.crystallize.com/white.png",
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  related: {
    settings: ComponentSettings,
  },
};
