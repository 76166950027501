import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";
//Editor
import { useNode } from "@craftjs/core";
import {
  Box,
  Divider,
  Input,
  Typography,
  Button as JButton,
  Stack,
} from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useResponsive } from "ahooks";
import { useLocation } from "react-router-dom";

/* Marketing Routes
-- These are the routes that are used in the Public / Marketing Site
*/
const publicRoutes = [
  {
    id: 1,
    title: "Home",
    url: "/",
    has_sub_menu: false,
    active: true,
  },
  /* {
    id: 2,
    title: "Products",
    url: "/products",
    has_sub_menu: false,
    active: false,
  }, */
  {
    id: 3,
    title: "Services",
    url: "/services",
    has_sub_menu: false,
    active: false,
  },
  {
    id: 4,
    title: "Articles",
    url: "/articles",
    has_sub_menu: false,
    active: false,
  },
  {
    id: 5,
    title: "Projects",
    url: "/portfolio",
    has_sub_menu: false,
    active: false,
  },
  {
    id: 6,
    title: "About",
    url: "/about",
    has_sub_menu: false,
    active: false,
  },
  {
    id: 7,
    title: "Contact",
    url: "/contact-us",
    has_sub_menu: false,
    active: false,
  },
];

export default function Header({
  logoUrl,
  colorVariant,
  cart,
  actionBtnText,
  actionBtnUrl,
  isEditorMode,
}) {

  //CONSTANTS
  const location = useLocation();

  //STATES
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [activeRoute, setActiveRoute] = useState("");
  
  //HOOKS
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //Get the active route
  useEffect(() => {
    const currentPath = window.location.pathname;
    const route = publicRoutes.find((item) => item.url === currentPath);
    if (route) {
      setActiveRoute(route.id);
    }
  }, [location.pathname]);

  //HOOK - Check if We should Display Mobile Menu (Responsive)
  const currentBreakpoint = useResponsive();
  useEffect(() => {
    if (currentBreakpoint === "xs") {
      setMobileToggle(false);
    }
  }, [currentBreakpoint]);

  return (
    <header
      className={`cs_style_1 ${
        colorVariant ? colorVariant : "cs_primary_color"
      } ${isSticky ? "cs_gescout_show" : ""} ${
        isEditorMode ? "" : "cs_sticky_header"
      }`}
      style={{
        position: isEditorMode ? "relative" : "absolute",
        //Glassmorphism
        backgroundColor: isSticky ? "rgba(255, 255, 255, 0.05)" : "transparent",
        backdropFilter: isSticky ? "blur(10px)" : "none",
        borderBottom: isSticky ? "1px solid rgba(255, 255, 255, 0.1)" : "none",
        zIndex: 1000,
        padding: "10px",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <div className="cs_main_header_left">
                <Link className="cs_site_branding" to="/">
                  <img src={logoUrl} alt="Logo" />
                </Link>
              </div>

              <div className="cs_main_header_center">
                <Stack direction="row" spacing={2}>
                  {publicRoutes.map((item, index) => {
                    if (item.has_sub_menu) {
                      return (
                        <Box key={index}>
                          <Link to={item.url}>
                            <JButton
                              variant={activeRoute === item.id ? "soft" : "plain"}
                              color={activeRoute === item.id ? "primary" : "default"}
                            >
                              {item.title}
                            </JButton>
                          </Link>
                          <DropDown>
                            <ul>
                              {item.sub_menu.map((subItem, subIndex) => (
                                <li key={subIndex}>
                                  <Link to={subItem.url}>{subItem.title}</Link>
                                </li>
                              ))}
                            </ul>
                          </DropDown>
                        </Box>
                      );
                    } else {
                      return (
                        <Link to={item.url}>
                          <JButton
                            variant={activeRoute === item.id ? "soft" : "plain"}
                            color={activeRoute === item.id ? "primary" : "default"}
                          >
                            {item.title}
                          </JButton>
                        </Link>
                      );
                    }
                  })}
                </Stack>
              </div>

              <div className="cs_main_header_right">
                {cart && (
                  <a href="shop-cart" className="cs_header_cart">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_307_180)">
                        <path
                          d="M19.25 5.5H16.5C16.5 4.04131 15.9205 2.64236 14.8891 1.61091C13.8576 0.579463 12.4587 0 11 0C9.54131 0 8.14236 0.579463 7.11091 1.61091C6.07946 2.64236 5.5 4.04131 5.5 5.5H2.75C2.02065 5.5 1.32118 5.78973 0.805456 6.30546C0.289731 6.82118 0 7.52065 0 8.25L0 17.4167C0.00145554 18.6318 0.484808 19.7967 1.34403 20.656C2.20326 21.5152 3.3682 21.9985 4.58333 22H17.4167C18.6318 21.9985 19.7967 21.5152 20.656 20.656C21.5152 19.7967 21.9985 18.6318 22 17.4167V8.25C22 7.52065 21.7103 6.82118 21.1945 6.30546C20.6788 5.78973 19.9793 5.5 19.25 5.5ZM11 1.83333C11.9725 1.83333 12.9051 2.21964 13.5927 2.90728C14.2804 3.59491 14.6667 4.52754 14.6667 5.5H7.33333C7.33333 4.52754 7.71964 3.59491 8.40728 2.90728C9.09491 2.21964 10.0275 1.83333 11 1.83333ZM20.1667 17.4167C20.1667 18.146 19.8769 18.8455 19.3612 19.3612C18.8455 19.8769 18.146 20.1667 17.4167 20.1667H4.58333C3.85399 20.1667 3.15451 19.8769 2.63879 19.3612C2.12306 18.8455 1.83333 18.146 1.83333 17.4167V8.25C1.83333 8.00688 1.92991 7.77373 2.10182 7.60182C2.27373 7.42991 2.50688 7.33333 2.75 7.33333H5.5V9.16667C5.5 9.40978 5.59658 9.64294 5.76849 9.81485C5.94039 9.98676 6.17355 10.0833 6.41667 10.0833C6.65978 10.0833 6.89294 9.98676 7.06485 9.81485C7.23676 9.64294 7.33333 9.40978 7.33333 9.16667V7.33333H14.6667V9.16667C14.6667 9.40978 14.7632 9.64294 14.9352 9.81485C15.1071 9.98676 15.3402 10.0833 15.5833 10.0833C15.8264 10.0833 16.0596 9.98676 16.2315 9.81485C16.4034 9.64294 16.5 9.40978 16.5 9.16667V7.33333H19.25C19.4931 7.33333 19.7263 7.42991 19.8982 7.60182C20.0701 7.77373 20.1667 8.00688 20.1667 8.25V17.4167Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_307_180">
                          <rect width={22} height={22} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="cs_header_cart_label">0</span>
                  </a>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link to={actionBtnUrl}>
                    <JButton
                      size="md"
                      variant="outlined"
                      color={colorVariant ? "primary" : "default"}
                    >
                      {actionBtnText}
                    </JButton>
                  </Link>
                </Box>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </header>
  );
}

export const EditableHeader = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  //COMPONENT STATES
  const [mobileToggle, setMobileToggle] = useState(false);

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "0px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <header
        className={`cs_site_header cs_style_1 cs_sticky_header ${
          props.colorVariant ? props.colorVariant : "cs_primary_color"
        } ${props.isSticky ? "cs_gescout_show" : ""}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link className="cs_site_branding" to="/">
                  <img src={props.logoUrl} alt="Logo" />
                </Link>
              </div>
              <div className="cs_main_header_center">
                <div className="cs_nav cs_medium cs_primary_font">
                  <ul
                    className={`${
                      mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                    }`}
                  >
                    {props.menuItems.map((item, index) => {
                      //item.has_sub_menu
                      if (item.has_sub_menu) {
                        return (
                          <li key={index} className="menu-item-has-children">
                            <Link to={item.url}>{item.title}</Link>
                            <DropDown>
                              <ul>
                                {item.sub_menu.map((subItem, subIndex) => (
                                  <li key={subIndex}>
                                    <Link to={subItem.url}>
                                      {subItem.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </DropDown>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index}>
                            <Link to={item.url}>{item.title}</Link>
                          </li>
                        );
                      }
                    })}
                  </ul>

                  <span
                    className={
                      mobileToggle
                        ? "cs_menu_toggle cs_teggle_active"
                        : "cs_menu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </div>
              </div>
              <div className="cs_main_header_right">
                {props.cart && (
                  <a href="shop-cart" className="cs_header_cart">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_307_180)">
                        <path
                          d="M19.25 5.5H16.5C16.5 4.04131 15.9205 2.64236 14.8891 1.61091C13.8576 0.579463 12.4587 0 11 0C9.54131 0 8.14236 0.579463 7.11091 1.61091C6.07946 2.64236 5.5 4.04131 5.5 5.5H2.75C2.02065 5.5 1.32118 5.78973 0.805456 6.30546C0.289731 6.82118 0 7.52065 0 8.25L0 17.4167C0.00145554 18.6318 0.484808 19.7967 1.34403 20.656C2.20326 21.5152 3.3682 21.9985 4.58333 22H17.4167C18.6318 21.9985 19.7967 21.5152 20.656 20.656C21.5152 19.7967 21.9985 18.6318 22 17.4167V8.25C22 7.52065 21.7103 6.82118 21.1945 6.30546C20.6788 5.78973 19.9793 5.5 19.25 5.5ZM11 1.83333C11.9725 1.83333 12.9051 2.21964 13.5927 2.90728C14.2804 3.59491 14.6667 4.52754 14.6667 5.5H7.33333C7.33333 4.52754 7.71964 3.59491 8.40728 2.90728C9.09491 2.21964 10.0275 1.83333 11 1.83333ZM20.1667 17.4167C20.1667 18.146 19.8769 18.8455 19.3612 19.3612C18.8455 19.8769 18.146 20.1667 17.4167 20.1667H4.58333C3.85399 20.1667 3.15451 19.8769 2.63879 19.3612C2.12306 18.8455 1.83333 18.146 1.83333 17.4167V8.25C1.83333 8.00688 1.92991 7.77373 2.10182 7.60182C2.27373 7.42991 2.50688 7.33333 2.75 7.33333H5.5V9.16667C5.5 9.40978 5.59658 9.64294 5.76849 9.81485C5.94039 9.98676 6.17355 10.0833 6.41667 10.0833C6.65978 10.0833 6.89294 9.98676 7.06485 9.81485C7.23676 9.64294 7.33333 9.40978 7.33333 9.16667V7.33333H14.6667V9.16667C14.6667 9.40978 14.7632 9.64294 14.9352 9.81485C15.1071 9.98676 15.3402 10.0833 15.5833 10.0833C15.8264 10.0833 16.0596 9.98676 16.2315 9.81485C16.4034 9.64294 16.5 9.40978 16.5 9.16667V7.33333H19.25C19.4931 7.33333 19.7263 7.42991 19.8982 7.60182C20.0701 7.77373 20.1667 8.00688 20.1667 8.25V17.4167Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_307_180">
                          <rect width={22} height={22} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="cs_header_cart_label">0</span>
                  </a>
                )}
                <Link
                  to={props.actionBtnUrl}
                  className={`cs_btn cs_style_1  ${
                    props.colorVariant ? "cs_btn_white" : ""
                  }`}
                >
                  {props.actionBtnText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Component End */}
    </Box>
  );
};

// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    logo,
    colorVariant,
    cart,
    actionBtn,
    menuItems,
    styles,
  } = useNode((node) => ({
    logo: node.data.props.logoUrl,
    colorVariant: node.data.props.colorVariant,
    cart: node.data.props.cart,
    actionBtn: node.data.props.actionBtnText,
    menuItems: node.data.props.menuItems,
    styles: node.data.props.styles,
  }));

  //COMPONENT STATES
  //--accordion
  const [expanded, setExpanded] = React.useState(false);
  //--color picker
  const [color, setColor] = useColor("hex", "#121212");

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Header Logo</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>
    </>
  );
};

Header.craft = {
  displayName: "Header",
  props: {
    logoUrl: "/soimagine/logo.svg",
    colorVariant: "cs_primary_color",
    cart: true,
    actionBtnText: "Get Started",
    actionBtnUrl: "/",
    isEditorMode: false,
    menuItems: [
      {
        title: "Home",
        url: "/",
        has_sub_menu: false,
      },
      {
        title: "About",
        url: "/about",
        has_sub_menu: false,
      },
      {
        title: "Service",
        url: "/service",
        has_sub_menu: true,
        sub_menu: [
          {
            title: "Service",
            url: "/service",
          },
          {
            title: "Service Details",
            url: "/service-details",
          },
        ],
      },
      {
        title: "Blog",
        url: "/blog-list",
        has_sub_menu: true,
        sub_menu: [
          {
            title: "Blog Standard",
            url: "/blog",
          },
          {
            title: "Blog List",
            url: "/blog-list",
          },
          {
            title: "Blog Details",
            url: "/blog/blog-details",
          },
        ],
      },
      {
        title: "Shop",
        url: "/shop",
        has_sub_menu: true,
        sub_menu: [
          {
            title: "Our Shop",
            url: "/shop",
          },
          {
            title: "Shop Details",
            url: "/shop/product-details",
          },
          {
            title: "Cart",
            url: "/shop/cart",
          },
          {
            title: "Checkout",
            url: "/shop/checkout",
          },
          {
            title: "Success Order",
            url: "/shop/success",
          },
          {
            title: "Wishlist",
            url: "/shop/wishlist",
          },
        ],
      },
      {
        title: "Contact",
        url: "/contact-us",
        has_sub_menu: false,
      },
    ],
  },
  related: {
    settings: ComponentSettings,
  },
};
