import React, { useState } from "react";
import {
  Input,
  Textarea,
  Select,
  Option,
  Button,
  FormControl,
  Divider,
  FormLabel,
  Box,
  Checkbox,
} from "@mui/joy";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar, enqueueSnackbar } from "notistack";
import PortfolioApi from "./PortfolioApi";
import _ from "lodash";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 } from "uuid";

export const PortfolioForm = ({ content, contentId, contentType }) => {
  //CONSTANTS
  const PortfolioSchema = {
    id: 1,
    title: "Portfolio",
    content: "Portfolio Content",
    author: "Author",
    status: "publish",
    last_modified: "2022-12-31",
    category: ["web", "mobile", "desktop"],

    client: {
      name: "Client Name",
      logo: "https://www.example.com/logo.jpg",
    },
    tech_stack: [
      // "reactjs" || "nextjs" || "laravel" || "electronjs" || "nodejs"
      "wordpress",
      "reactjs",
      "nextjs",
      "laravel",
      "electronjs",
      "nodejs",
    ],
    project_url: "https://www.example.com",
    project_completion: "2022-12-31",
    project_gallery: [
      {
        title: "Project Image",
        image: "https://www.example.com/image.jpg",
      },
    ],
  };

  //STATES
  const [portfolioItem, setPortfolioItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: content ? content?.title : "",
      status: content ? content?.status : "",
      client: {
        name: content ? content?.client?.name : "",
        logo: content ? content?.client?.logo : "",
      },
      tech_stack: content ? content?.tech_stack : [],
      project_url: content ? content?.project_url : "",
      project_completion: content ? content?.project_completion : "",
      project_gallery: content ? content?.project_gallery : [],
      content: content ? content?.content : "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
      client: Yup.object({
        name: Yup.string().required("Required"),
        logo: Yup.string().required("Required"),
      }),
      tech_stack: Yup.array().required("Required"),
      project_url: Yup.string().required("Required"),
      project_completion: Yup.string().required("Required"),
      project_gallery: Yup.array().required("Required"),
      content: Yup.string().required("Required"),
    }),

    //enableReinitialize: true,

    onSubmit: (values) => {
      console.log(values);
    },
  });

  //FUNCTIONS
  //--Get the content item from the database
  const getContentItem = async (id) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const json = await PortfolioApi.getOne(id);
      setPortfolioItem(json);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  //--Save the ContentItem to the database
  const saveContentItem = async (values) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const json = await PortfolioApi.update(contentId, values);
      setSuccess(json);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  //EFFECTS
  React.useEffect(() => {
    if (contentId) {
      getContentItem(contentId);
    }
  }, []);

  //COMPONENTS
  //--Basic Details
  const BasicDetails = () => {
    return (
      <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          maxWidth: "100%",
          mx: "auto",
          // to make the demo resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Typography
          level="title-sm"
          startDecorator={<Icon icon="carbon:document" />}
        >
          Basic Details
        </Typography>
        <Divider inset="none" />
        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
            gap: 1.5,
          }}
        >
          <FormControl sx={{ gridColumn: "1/-1" }}>
            <FormLabel>Title</FormLabel>
            <Input
              id="title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              endDecorator={<Icon icon="carbon:text-font" />}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              id="status"
              name="status"
              value={formik.values.status}
              onChange={(e, v) => formik.setFieldValue("title", v)}
              size="sm"
              endDecorator={<Icon icon="carbon:chevron-down" />}
            >
              <Option value="publish">Publish</Option>
              <Option value="draft">Draft</Option>
              <Option value="trash">Trash</Option>
            </Select>
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  //Client Details
  const ClientDetails = ({ clientDetails, OnChange }) => {
    return (
      <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          maxWidth: "100%",
          mx: "auto",
          // to make the demo resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Typography
          level="title-sm"
          startDecorator={<Icon icon="carbon:user-avatar" />}
        >
          Client Details
        </Typography>
        <Divider inset="none" />

        <FilePickerOpener
          currentFile={formik.values.client.logo}
          onFileChange={(file) => {
            formik.setFieldValue("client.logo", file);
          }}
          previewStyle={{
            width: "100%",
            height: "75px",
          }}
        />

        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
            gap: 1.5,
          }}
        >
          <FormControl sx={{ gridColumn: "1/-1" }}>
            <FormLabel>Client Name</FormLabel>
            <Input
              id="client-name"
              name="client-name"
              value={formik.values.client.name}
              onChange={formik.handleChange}
              endDecorator={<Icon icon="carbon:text-font" />}
            />
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  //Project Details
  const ProjectDetails = ({ projectDetails, OnChange }) => {
    //FUNCTIONS
    return (
      <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          maxWidth: "100%",
          mx: "auto",
          // to make the demo resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Typography
          level="title-sm"
          startDecorator={<Icon icon="carbon:logo-github" />}
        >
          Project Details
        </Typography>
        <Divider inset="none" />

        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
            gap: 1.5,
          }}
        >
          <FormControl sx={{ gridColumn: "1/-1" }}>
            <FormLabel>Project URL</FormLabel>
            <Input
              id="project_url"
              name="project_url"
              value={formik.values.project_url}
              onChange={formik.handleChange}
              endDecorator={<Icon icon="carbon:link" />}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Completion</FormLabel>
            <Input
              id="project_completion"
              name="project_completion"
              value={formik.values.project_completion}
              onChange={formik.handleChange}
              endDecorator={<Icon icon="carbon:calendar" />}
            />
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  //Project Gallery
  //-- Repeatable FilePickerOpener for the project gallery
  //-- Rendered in rows of 3
  const ProjectGallery = () => {
    //FUNCTIONS
    //--If the value of the input changes, update the state
    // and call the OnChange function
    const onImageChange = ({ id, file }) => {
      formik.setFieldValue(
        "project_gallery",
        formik.values.project_gallery.map((image) => {
          if (image.id === id) {
            return {
              ...image,
              image: file,
            };
          }
          return image;
        })
      );
    };
    const onImageDelete = (id) => {
      formik.setFieldValue(
        "project_gallery",
        formik.values.project_gallery.filter((image) => image.id !== id)
      );
    };
    const onImageAdd = (id) => {
      formik.setFieldValue("project_gallery", [
        ...formik.values.project_gallery,
        {
          id: id,
          image: "",
        },
      ]);
    };

    return (
      <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          maxWidth: "100%",
          mx: "auto",
          // to make the demo resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            level="title-sm"
            startDecorator={<Icon icon="carbon:image-copy" />}
          >
            Project Gallery
          </Typography>

          <Button
            color="primary"
            onClick={() => onImageAdd(v4())}
            startDecorator={
              <Icon
                icon="carbon:document"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Add Image
          </Button>
        </Stack>
        <Divider inset="none" />

        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(80px, 1fr))",
            gap: 1.5,
          }}
        >
          {
            //Project Gallery
            _.isEmpty(formik.values.project_gallery) ? (
              <>
                <Typography level="title-md" sx={{ gridColumn: "1/-1" }}>
                  No images found
                </Typography>
                <Icon icon="carbon:image" sx={{ gridColumn: "1/-1" }} />
              </>
            ) : (
              formik.values.project_gallery.map((image, index) => <></>)
            )
          }
        </CardContent>
      </Card>
    );
  };

  //Project Content
  const ProjectContent = ({ projectContent, OnChange }) => {
    return (
      <Card
        variant="outlined"
        sx={{
          maxHeight: "max-content",
          maxWidth: "100%",
          mx: "auto",
          // to make the demo resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Typography
          level="title-sm"
          startDecorator={<Icon icon="carbon:document" />}
        >
          Project Content
        </Typography>
        <Divider inset="none" />

        <RichTextEditor
          value={formik.values.content}
          onEdit={(c) => {
            formik.setFieldValue("content", c);
          }}
        />
      </Card>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        flex: 1,
      }}
    >
      {loading ? (
        <>
          <Typography level="title-md" sx={{ gridColumn: "1/-1" }}>
            Loading...
          </Typography>
          <Icon
            icon="svg-spinners:blocks-scale"
            width="35"
            height="35"
            sx={{ gridColumn: "1/-1" }}
          />
        </>
      ) : error ? (
        <>
          <Icon
            icon="carbon:error"
            width="100"
            height="100"
            sx={{ gridColumn: "1/-1" }}
          />
          <Typography level="title-md" sx={{ gridColumn: "1/-1" }}>
            Error
          </Typography>
          <Typography level="body" sx={{ gridColumn: "1/-1" }}>
            {error?.message}
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <BasicDetails />
              </Grid>
              <Grid item xs={12} md={3}>
                <ClientDetails />
              </Grid>

              <Grid item xs={12} md={4}>
                <ProjectDetails />
              </Grid>

              <Grid item xs={12}>
                <ProjectContent />
              </Grid>

              <Grid item xs={12}>
                <ProjectGallery />
              </Grid>
            </Grid>
          </form>

          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              display: "flex",
              justifyContent: "flex-end",
              boxShadow: "0px -1px 5px rgba(0,0,0,0.1)",
              borderTop: "1px solid #ccc",
              padding: "10px",
            }}
          >
            <Button
              color="primary"
              onClick={formik.handleSubmit}
              startDecorator={
                <Icon
                  icon="carbon:download"
                  style={{ fontSize: "16px", color: "#fff" }}
                />
              }
              size="lg"
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const RichTextEditor = ({ content, onEdit }) => {
  //CONSTANTS
  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
    },
    label: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    editor: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "10px",
      minHeight: "100%",
    },
  };

  const q_formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "clean",
  ];

  const tb_options = [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code-block"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ];

  //STATES
  const [value, setValue] = React.useState(content);

  //REFS
  const editorRef = React.useRef(null);

  //EFFECTS
  //insert the HTML content into the editor on load
  React.useEffect(() => {
    if (content && content !== value) {
      setValue(content);
    }
  }, [content, value]);

  //RENDER
  return (
    <div>
      <QuillEditor
        ref={editorRef}
        className={styles.editor}
        theme="snow"
        value={value}
        formats={q_formats}
        modules={{
          toolbar: tb_options,
        }}
        style={{
          height: "100%",
        }}
        onChange={(content, delta, source, editor) => {
          setValue(content);
          onEdit(content);
        }}
      />
    </div>
  );
};

export default PortfolioForm;
