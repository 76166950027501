import * as React from "react";
import { Outlet } from "react-router-dom";

//craft-js
import { Editor } from "@craftjs/core";
//editable components for the menu

export default function MenuLayout() {
  const currentTheme = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = React.useState(currentTheme);

  React.useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <Editor
     resolver={{
      
    }}>
      <Outlet />
    </Editor>
    
  );
}
