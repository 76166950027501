import axios from "axios";

const SiteOptionsApi = {

  //--url
  baseUrl: 'https://api.soimagine.co.za/wp-json',
  namespace: '/sa9ecms/v1/',
  endpoint: '/options',

  //--get general options
  getGeneralOptions: async function () {
    try {
      const response = await axios.get(
        `${this.baseUrl}${this.namespace}${this.endpoint}/general`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  //--update general options
  updateGeneralOptions: async function (data) {
    try {
      const response = await axios.post(
        `${this.baseUrl}${this.namespace}${this.endpoint}/general`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
};

export default SiteOptionsApi;

