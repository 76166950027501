import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
//Forms
import PortfolioForm from "./PortfolioForm";
import { useLocation, useNavigate } from "react-router-dom";

/*COMPONENT*/
//--pagesList | default
export default function ContentEditor(){

  //CONSTANTS
  const location = useLocation();
  const navigate = useNavigate();

  
  //FUNCTIONS
  //--get the content type and id from the url
  //--edit/{contentType}/{contentId}
  function getContentType(){
    const type = location.pathname.split("/")[4];
    console.log("URL CONTENT TYPE", type);
    return type;
  }

  function getContentId(){
    const id = location.pathname.split("/")[5];
    console.log("URL CONTENT ID", id);
    return id;
  }

  //STATES
  const [content, setContent] = React.useState(location.state?.content);
  const [contentType, setContentType] = React.useState(getContentType());
  const [contentId, setContentId] = React.useState(getContentId());
 
  //RETURN
  return (
    <Box sx={{ minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    }}>
      {
        contentType === "portfolio" 
        ?
        <PortfolioForm content={content} contentType={contentType} contentId={contentId} />
        :
        <Box>
          <Typography variant="h6">Content Editor</Typography>
          <Typography variant="body2">Edit content here</Typography>
        </Box>
      }
    </Box>
  );
}

//--Content Form Builder

