export const OurServices=[
  {
    title:"AI Driven Applications",
    content:"We develop advanced data tools and systems that enable organizations to make data-driven tools and systems that enable organizations to make data-driven decisions, gain valuable insights, and predict future trends."
  },
  {
    title:"Business Process RE-engineering",
    content:"Our team works closely with clients to analyze and optimize their business processes, identifying opportunities for increased efficiency and productivity."
  },
  {
    title:"Mobile App Development",
    content:"We create intuitive and engaging mobile applications for IOS, Android, and Huawei platforms tailored to your specific requirements and target audience."
  },
  {
    title:"Software Development",
    content:"Our team of skilled developers specializes in designing and building custom software solutions that improve business efficiency, streamline processes, and drive growth."
  },
  {
    title:"WhatsApp / Telegram Chatbots",
    content:"Our chatbot development services help businesses enhance customer engagement, automate communication, and improve support through popular messaging platforms."
  },
  {
    title:"Data Analysis & Forecasting Systems",
    content:"We develop advanced data tools and systems that enable organizations to make data-driven tools and systems that enable organizations to make data-driven decisions, gain valuable insights, and predict future trends."
  },
  {
    title:"Public Relations",
    content:"In today's fast-paced world, managing your reputation is crucial. Soimagine's public relations experts are skilled at shaping positive narratives around your brand. We have a proven track record of successful PR campaigns, crisis management, and media relations. Our team will work tirelessly to enhance your brand's image, build trust, and ensure your voice is heard in the media landscape. We understand the power of storytelling and will craft compelling narratives that resonate with your target audience."
  },
  {
    title:"Marketing",
    content:"Our marketing team at Soimagine is dedicated to helping your business thrive. We understand that marketing is more than just advertising; it's about building a brand, connecting with your audience, and driving results. With a deep understanding of consumer behavior and market trends, we develop comprehensive marketing strategies tailored to your unique needs. From market research to campaign execution, we are with you every step of the way to ensure your brand stands out and achieves its objectives"
  },
  {
    title:"Web Development",
    content:"We specialize in creating visually appealing, user-friendly websites tailored to your brand's identity and objectives. Paired with our reliable hosting services, we ensure your website is not only stunning but also performs seamlessly, providing a superior experience for your visitors. Trust us to bring your digital vision to life, with round-the-clock support and top-notch security."
  }
]

export const ServiceData = [
  {
    number: '01',
    title: 'AI Driven Applications',
    subTitle:
      'We develop advanced data tools and systems that enable organizations to make data-driven tools and systems that enable organizations to make data-driven decisions, gain valuable insights, and predict future trends.',
    thumbnailSrc: '/images/studio-agency/service_img_1.jpeg',
    href: '/services/service-details/1',
    content:{
      key_points:[
        {
          title:"Data-driven Decisions",
          content:"Since data is the backbone of any business, we help organizations harness the power of data to make informed decisions that drive growth and success."
        },
        {
          title:"Predict Future Trends",
          content:"Our AI-driven applications are designed to analyze data patterns, identify trends, and predict future outcomes, giving businesses a competitive edge in the market."
        },
        {
          title:"Gain Valuable Insights",
          content:"We provide organizations with valuable insights into their operations, customers, and market trends, enabling them to make strategic decisions that lead to success."
        },
        {
          title:"Enhanced Efficiency",
          content:"Our AI-driven applications streamline processes, automate tasks, and improve efficiency, allowing organizations to focus on what matters most."
        }
      ],
      benefits:{
        title: "Do we really need AI-driven applications?",
        content: "A.I. driven applications are essential for businesses looking to stay ahead of the competition and drive growth. By leveraging the power of artificial intelligence, organizations can gain valuable insights, make data-driven decisions, and predict future trends. This not only enhances efficiency and productivity but also improves customer satisfaction and drives revenue. With our AI-driven applications, your business can unlock its full potential and achieve success in today's digital world.",
        points:[
          "Designing content with AI power",
          "Automating customer service",
          "Personalizing user experience",
          "Predicting customer behavior",
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/19246371-d16e-476e-bc52-1995bc4b2ebd_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/d3440efe-486f-411d-8ef9-2ac0c1b9ef83_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '02',
    title: 'Business Process RE-engineering',
    subTitle:
      'Our team works closely with clients to analyze and optimize their business processes, identifying opportunities for increased efficiency and productivity.',
    thumbnailSrc: '/images/studio-agency/service_img_2.jpeg',
    href: '/services/service-details/2',
    content:{
      key_points:[
        {
          title:"Process Analysis",
          content:"We conduct a thorough analysis of your business processes to identify inefficiencies, bottlenecks, and areas for improvement."
        },
        {
          title:"Optimization Strategies",
          content:"Our team develops customized strategies to optimize your business processes, streamline operations, and improve productivity."
        },
        {
          title:"Implementation Support",
          content:"We provide ongoing support to help you implement the new processes, train your team, and ensure a smooth transition."
        },
        {
          title:"Performance Monitoring",
          content:"We monitor the performance of your new processes, gather feedback, and make adjustments as needed to ensure continued success."
        }
      ],
      benefits:{
        title: "Why Business Process Re-engineering?",
        content: "Business process re-engineering is essential for organizations looking to improve efficiency, reduce costs, and drive growth. By analyzing and optimizing your business processes, you can identify opportunities for improvement, streamline operations, and enhance productivity. This not only leads to cost savings but also improves customer satisfaction and drives revenue. With our business process re-engineering services, your organization can achieve its full potential and stay ahead of the competition.",
        points:[
          "Identifying inefficiencies",
          "Streamlining operations",
          "Improving productivity",
          "Enhancing customer satisfaction"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/1379beee-1f44-4493-94b6-278b665487a8_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/000e1bd8-e8be-4886-9144-45638b4c3e9c_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '03',
    title: 'Mobile App Development',
    subTitle:
      'We create intuitive and engaging mobile applications for IOS, Android, and Huawei platforms tailored to your specific requirements and target audience.',
    thumbnailSrc: '/images/studio-agency/service_img_3.jpeg',
    href: '/services/service-details/3',
    content:{
      key_points:[
        {
          title:"Customized Solutions",
          content:"We develop custom mobile applications tailored to your unique requirements, brand identity, and target audience."
        },
        {
          title:"Cross-Platform Compatibility",
          content:"Our mobile applications are designed to work seamlessly across IOS, Android, and Huawei platforms, ensuring a consistent user experience."
        },
        {
          title:"User-Centric Design",
          content:"We prioritize user experience and design intuitive, engaging interfaces that keep users coming back for more."
        },
        {
          title:"Scalable Solutions",
          content:"Our mobile applications are built to scale with your business, accommodating growth and evolving needs over time."
        }
      ],
      benefits:{
        title: "Why Mobile App Development?",
        content: "Mobile applications are essential for businesses looking to engage customers, drive sales, and enhance brand visibility. With the increasing use of smartphones and tablets, mobile apps provide a convenient way to connect with your audience, offer personalized experiences, and drive customer loyalty. Whether you're looking to launch a new app or enhance an existing one, our mobile app development services can help you achieve your goals and stand out in the crowded app marketplace.",
        points:[
          "Engaging customers",
          "Driving sales",
          "Enhancing brand visibility",
          "Offering personalized experiences"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/13652507-5de9-4670-bc81-74568ce82d18_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/b187febb-2999-4c6a-9280-837dde42d263_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '04',
    title: 'Software Development',
    subTitle:
      'Our team of skilled developers specializes in designing and building custom software solutions that improve business efficiency, streamline processes, and drive growth.',
    thumbnailSrc: '/images/studio-agency/service_img_4.jpeg',
    href: '/services/service-details/4',
    content:{
      key_points:[
        {
          title:"Custom Software Solutions",
          content:"We develop custom software solutions tailored to your unique business needs, objectives, and target audience."
        },
        {
          title:"Streamlined Processes",
          content:"Our software solutions are designed to streamline your business processes, improve efficiency, and drive growth."
        },
        {
          title:"Scalable Solutions",
          content:"We build scalable software solutions that can grow with your business, accommodating changing needs and future expansion."
        },
        {
          title:"Ongoing Support",
          content:"We provide ongoing support to ensure your software solution continues to meet your needs and deliver value over time."
        }
      ],
      benefits:{
        title: "Why Software Development?",
        content: "Custom software solutions are essential for businesses looking to improve efficiency, streamline operations, and drive growth. By developing software that is tailored to your unique needs, you can automate tasks, reduce manual errors, and enhance productivity. This not only saves time and money but also improves customer satisfaction and drives revenue. With our software development services, your business can achieve its full potential and stay ahead of the competition.",
        points:[
          "Automating tasks",
          "Reducing manual errors",
          "Enhancing productivity",
          "Improving customer satisfaction"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/663c02ac-a771-4f23-a784-affacf768c6b_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/596e6c0d-33ae-4ed2-98cb-5c4a93f30bda_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '05',
    title: 'Data Analysis & Forecasting Systems',
    subTitle:
      'We develop advanced data tools and systems that enable organizations to make data-driven tools and systems that enable organizations to make data-driven decisions, gain valuable insights, and predict future trends.',
    thumbnailSrc: '/images/studio-agency/service_img_5.jpeg',
    href: '/services/service-details/5',
    content:{
      key_points:[
        {
          title:"Data-driven Decisions",
          content:"Since data is the backbone of any business, we help organizations harness the power of data to make informed decisions that drive growth and success."
        },
        {
          title:"Predict Future Trends",
          content:"Our data analysis and forecasting systems are designed to analyze data patterns, identify trends, and predict future outcomes, giving businesses a competitive edge in the market."
        },
        {
          title:"Gain Valuable Insights",
          content:"We provide organizations with valuable insights into their operations, customers, and market trends, enabling them to make strategic decisions that lead to success."
        },
        {
          title:"Enhanced Efficiency",
          content:"Our data analysis and forecasting systems streamline processes, automate tasks, and improve efficiency, allowing organizations to focus on what matters most."
        }
      ],
      benefits:{
        title: "Why Data Analysis & Forecasting Systems?",
        content: "Data analysis and forecasting systems are essential for organizations looking to make data-driven decisions, gain valuable insights, and predict future trends. By analyzing data patterns and trends, businesses can identify opportunities for growth, optimize operations, and stay ahead of the competition. This not only improves efficiency and productivity but also enhances decision-making and drives revenue. With our data analysis and forecasting systems, your organization can unlock the power of data and achieve success in today's digital world.",
        points:[
          "Making data-driven decisions",
          "Gaining valuable insights",
          "Predicting future trends",
          "Optimizing operations"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/6bf71233-cf53-4ed6-82bf-f641eba83ccd_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/663c02ac-a771-4f23-a784-affacf768c6b_copy.webp"
      ],
      serviceRequestForm:{}
    }

  },
  {
    number: '05',
    title: 'Public Relations',
    subTitle:
    "In today's fast-paced world, managing your reputation is crucial. Soimagine's public relations experts are skilled at shaping positive narratives around your brand. We have a proven track record of successful PR campaigns, crisis management, and media relations",
    thumbnailSrc: '/images/studio-agency/service_img_5.jpeg',
    href: '/services/service-details/6',
    content:{
      key_points:[
        {
          title:"Positive Narratives",
          content:"We help shape positive narratives around your brand, products, and services, enhancing your reputation and building trust with your target audience."
        },
        {
          title:"Crisis Management",
          content:"Our team is experienced in managing crises, handling negative publicity, and protecting your brand's reputation during challenging times."
        },
        {
          title:"Media Relations",
          content:"We have strong relationships with the media and can help you secure positive press coverage, interviews, and media placements to enhance your brand's visibility."
        },
        {
          title:"Brand Building",
          content:"We work with you to build a strong brand identity, develop compelling messaging, and create engaging content that resonates with your target audience."
        }
      ],
      benefits:{
        title: "Why Public Relations?",
        content: "Public relations is essential for businesses looking to build brand awareness, manage their reputation, and engage with their target audience. By shaping positive narratives, managing crises, and securing media coverage, PR can help businesses build trust, credibility, and loyalty with their customers. This not only enhances brand visibility but also drives customer engagement and loyalty. With our public relations services, your business can stand out in a crowded marketplace and achieve its communication goals.",
        points:[
          "Building brand awareness",
          "Managing reputation",
          "Engaging with target audience",
          "Securing media coverage"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/4935694b-6c7c-4096-a1e0-d6a22e9e646f_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/1379beee-1f44-4493-94b6-278b665487a8_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '05',
    title: 'Marketing',
    subTitle:
      "Our marketing team at Soimagine is dedicated to helping your business thrive. We understand that marketing is more than just advertising; it's about building a brand, connecting with your audience, and driving results.",
    thumbnailSrc: '/images/studio-agency/service_img_5.jpeg',
    href: '/services/service-details/7',
    content:{
      key_points:[
        {
          title:"Brand Building",
          content:"We help you build a strong brand identity, develop compelling messaging, and create engaging content that resonates with your target audience."
        },
        {
          title:"Audience Engagement",
          content:"Our marketing strategies are designed to engage your target audience, build relationships, and drive customer loyalty and retention."
        },
        {
          title:"Results-Driven Approach",
          content:"We focus on delivering measurable results, driving traffic, generating leads, and increasing conversions to help you achieve your business goals."
        },
        {
          title:"Comprehensive Services",
          content:"From market research to campaign execution, we offer a full range of marketing services to help you reach your target audience and drive growth."
        }
      ],
      benefits:{
        title: "Why Marketing?",
        content: "Marketing is essential for businesses looking to build brand awareness, drive sales, and engage with their target audience. By developing comprehensive marketing strategies, businesses can connect with customers, build relationships, and drive results. This not only enhances brand visibility but also increases customer engagement, loyalty, and retention. With our marketing services, your business can stand out in a crowded marketplace and achieve its marketing goals.",
        points:[
          "Building brand awareness",
          "Driving sales",
          "Engaging with target audience",
          "Generating leads"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/1e05421e-03c6-44cf-a576-0b07fa5f549e_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/2dbd11ed-3221-40c4-8c1f-7a655be7a87a_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
  {
    number: '05',
    title: 'Web Development',
    subTitle:
      "We specialize in creating visually appealing, user-friendly websites tailored to your brand's identity and objectives. Paired with our reliable hosting services, we ensure your website is not only stunning but also performs seamlessly, providing a superior experience for your visitors.",
    thumbnailSrc: '/images/studio-agency/service_img_5.jpeg',
    href: '/services/service-details/8',
    content:{
      key_points:[
        {
          title:"Customized Websites",
          content:"We develop custom websites tailored to your brand's identity, objectives, and target audience, ensuring a unique and engaging online presence."
        },
        {
          title:"User-Friendly Design",
          content:"We prioritize user experience and design intuitive, user-friendly interfaces that keep visitors engaged and coming back for more."
        },
        {
          title:"Responsive Design",
          content:"Our websites are designed to be responsive and mobile-friendly, ensuring a seamless experience across all devices and screen sizes."
        },
        {
          title:"Reliable Hosting",
          content:"Paired with our reliable hosting services, we ensure your website is secure, fast, and always available to provide a superior experience for your visitors."
        }
      ],
      benefits:{
        title: "Why Web Development?",
        content: "Web development is essential for businesses looking to establish an online presence, engage customers, and drive growth. By creating visually appealing, user-friendly websites, businesses can attract visitors, generate leads, and drive conversions. This not only enhances brand visibility but also improves customer engagement, loyalty, and retention. With our web development services, your business can stand out in a crowded online marketplace and achieve its digital goals.",
        points:[
          "Establishing online presence",
          "Engaging customers",
          "Driving growth",
          "Improving customer experience"
        ]
      },
      media:[
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/bcc4f382-925b-48a8-a613-01862aa0e232_copy.webp",
        "https://api.soimagine.co.za/wp-content/uploads/2024/05/596e6c0d-33ae-4ed2-98cb-5c4a93f30bda_copy.webp"
      ],
      serviceRequestForm:{}
    }
  },
];