import * as React from "react";
import Box from "@mui/joy/Box";

import { Outlet } from "react-router-dom";

export default function BlocksLayout() {
  const currentTheme = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = React.useState(currentTheme);

  React.useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <Outlet />
  );
}
