/* eslint-disable react/jsx-pascal-case */
import React from 'react';
//Editor
import { useNode } from "@craftjs/core";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import { Box, Input, Stack, Typography, Button as JButton, Card, CardContent, CardActions } from '@mui/joy';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import RichTextEditor from 'src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor';
import { FilePickerOpener } from 'src/dashboard/components/FilePicker';


export default function Award({ data }) {
  return (
    <div className="cs_awaard_1_list">
      {data?.map((item, index) => (
        <div className="cs_awaard cs_style_1" key={index}>
          <h3 className="cs_awaard_brand mb-0 cs_fs_29 cs_semibold cs_accent_color">
            {item.brand}
          </h3>
          <div className="cs_awaard_info">
            <h2 className="cs_awaard_title cs_fs_29 cs_white_color cs_semibold">
              {item.title}
            </h2>
            <p className="cs_awaard_subtitle mb-0">{item.subTitle}</p>
          </div>
          <h3 className="cs_awaard_time cs_white_color cs_semibold mb-0 cs_fs_29">
            {item.date}
          </h3>
          <div className="cs_award_img">
            <img src={item.awardImgUrl} alt="Award" />
          </div>
        </div>
      ))}
    </div>
  );
}

export const EditableAward = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <Award data={props.data} />

      {/* Component End */}

    </Box>
  );
    
};

// This is where you can add UI elements for editing component properties
const _ItemForm = ({ item, index, onChange, onRemove }) => {
  return (
    <Card key={index} sx={{ margin: 1 }}>
      <CardContent>
        <Stack spacing={2}>
          <Input
            label="Brand"
            value={item.brand}
            onChange={(e) => onChange('brand', e.target.value)}
          />

          <Input
            label="Title"
            value={item.title}
            onChange={(e) => onChange('title', e.target.value)}
          />

          <Input
            label="Sub Title"
            value={item.subTitle}
            onChange={(e) => onChange('subTitle', e.target.value)}
          />

          <Input
            type="date"
            label="Date"
            value={item.date}
            onChange={(e) => onChange('date', e.target.value)}
          />

          <FilePickerOpener
          onSelected={(file) => {
            onChange('awardImgUrl', file.url);
          }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <JButton size="sm" color="error" onClick={onRemove}>
          Remove
        </JButton>
      </CardActions>
    </Card>
  );
};

const _ItemRepeater = ({ items, onChange }) => {
  const addItem = () => {
    onChange([...items, { title: '', url: '' }]);
  };

  const removeItem = (index) => {
    onChange(items.filter((item, i) => i !== index));
  };

  const updateItem = (index, key, value) => {
    onChange(
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }

        return item;
      }),
    );
  };

  return (
    <Stack>
      {items.map((item, index) => (
        <_ItemForm
          key={index}
          item={item}
          index={index}
          onChange={(key, value) => updateItem(index, key, value)}
          onRemove={() => removeItem(index)}
        />
      ))}

      <JButton
        size="sm"
        color="primary"
        onClick={addItem}
      >
        Add Item
      </JButton>
    </Stack>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    data
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <_ItemRepeater
            items={data}
            onChange={(value) => setProp((props) => (props.data = value))}
          /> 
        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableAward.craft = {
  props: {
    data: [
      {
        brand: 'Brand 1',
        title: 'Title 1',
        subTitle: 'Sub Title 1',
        date: '2021-10-01',
        awardImgUrl: 'https://via.placeholder.com/150',
      },
      {
        brand: 'Brand 2',
        title: 'Title 2',
        subTitle: 'Sub Title 2',
        date: '2021-10-02',
        awardImgUrl: 'https://via.placeholder.com/150',
      },
    ],
  },
  related: {
    settings: ComponentSettings,
  },
};