import React, { useRef, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  PerspectiveCamera,
  SpotLight,
  Box as Box3D,
  Plane,
  Stage,
  Environment,
} from "@react-three/drei";
import * as THREE from "three";
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton, Stack, ToggleButtonGroup, ButtonGroup, Select, Slider, Option, Button } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";

function Building({ position, args }) {
  return (
    <mesh position={position} castShadow receiveShadow>
      <Box3D args={args} />
      <meshStandardMaterial color={new THREE.Color(Math.random() * 0xf90000)} />
    </mesh>
  );
}

function City() {
  const buildings = useMemo(() => {
    const buildings = [];
    for (let i = 0; i < 100; i++) {
      const position = [
        Math.random() * 60 - 30,
        Math.random() * 2.5,
        Math.random() * 60 - 30,
      ];
      const args = [
        Math.random() * 2 + 0.5,
        Math.random() * 5 + 2,
        Math.random() * 2 + 0.5,
      ];
      buildings.push({ position, args });
    }
    return buildings;
  }, []);

  return (
    <>
      {buildings.map((building, index) => (
        <Building
          key={index}
          position={building.position}
          args={building.args}
        />
      ))}
    </>
  );
}

//create Bumpy Ground plane with Perlin noise
function Ground({
  //This is the default values
  width = 100,
  height = 100,
  widthSegments = 100,
  heightSegments = 100,
  depth = 3,
  threshold = 0.5,
  color = "#a0a0a0",
}) {
  const ref = useRef();
  const { clock } = useThree();

  useFrame(() => {
    const time = clock.getElapsedTime();
    ref.current.material.displacementScale = Math.sin(time) * 0.1;
  });

  return (
    <mesh
      ref={ref}
      receiveShadow
      position={[0, -3, 0]}
      rotation={[-Math.PI / 2, 0, 0]}
    >
      <planeGeometry
        attach="geometry"
        args={[width, height, widthSegments, heightSegments]}
      />
      <meshStandardMaterial
        attach="material"
        color={color}
        displacementScale={0.1}
        displacementBias={-0.1}
        displacementMap={new THREE.TextureLoader().load(
          "https://threejsfundamentals.org/threejs/resources/images/heightmap-1.jpg"
        )}
      />
    </mesh>
  );
}

function Scene({

  //GROUND 
  g_width = 100,
  g_height = 100,
  g_widthSegments = 100,
  g_heightSegments = 100,
  g_depth = 3,
  g_threshold = 0.5,
  g_color = "#a0a0a0",

  //SCENE
  cameraPosition = [0, 3, 15],
  cameraFov = 50,

  enablePan = true,
  enableZoom = true,
  enableRotate = true,

  ambientLightIntensity = 0.5,
  spotLightPosition = [15, 20, 5],
  spotLightAngle = 0.3,
  spotLightPenumbra = 0.1,
  spotLightIntensity = 2,
  castShadow = true,
}) {
  const { camera } = useThree();
  useFrame(({ pointer }) => {
    camera.position.lerp(new THREE.Vector3(pointer.x * 5, 3, 15), 0.05);
    camera.lookAt(new THREE.Vector3(0, 2, 0));
  });

  return (
    <>
      <PerspectiveCamera
        makeDefault
        position={cameraPosition}
        fov={cameraFov}
      />
      <OrbitControls
        enablePan={enablePan}
        enableZoom={enableZoom}
        enableRotate={enableRotate}
      />
      <ambientLight intensity={ambientLightIntensity} />
      <SpotLight
        position={spotLightPosition}
        angle={spotLightAngle}
        penumbra={spotLightPenumbra}
        intensity={spotLightIntensity}
        castShadow={castShadow}
      />
      <City />
      <Ground 
        width={g_width}
        height={g_height}
        widthSegments={g_widthSegments}
        heightSegments={g_heightSegments}
        depth={g_depth}
        threshold={g_threshold}
        color={g_color}
      />
    </>
  );
}

export default function BGScene1() {
  return (
    <div style={{ height: "500px", width: "100vw" }}>
      <Canvas shadows camera={{ position: [0, 3, 15], fov: 50 }}>
        <Scene />

        <Environment preset="city" blur={0.3} />
        <Stage environment="city" intensity={0.5} contactShadowOpacity={0.5} />
      </Canvas>
    </div>
  );
}

// Define the editable version of your component
// Replace InteractiveScene1 with your component's name
export const EditableInteractiveScene1 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));
  
  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}

      <div style={{
        height: props.containerHeight,
        width: props.containerWidth,
      }}>
        <Canvas
          shadows={props.shadows}
          camera={{
            position: [props.cameraPosition[0], props.cameraPosition[1], props.cameraPosition[2]],
            fov: props.cameraFov,
          }}
        >
          <Scene
          //ground
            g_width={100}
            g_height={100}
            g_widthSegments={100}
            g_heightSegments={100}
            g_depth={3}
            g_threshold={0.5}
            g_color={"#a0a0a0"}

          //scene
            cameraPosition={props.cameraPosition}
            cameraFov={props.cameraFov}
            enablePan={props.enablePan}
            enableZoom={props.enableZoom}
            enableRotate={props.enableRotate}
            ambientLightIntensity={props.ambientLightIntensity}
            spotLightPosition={props.spotLightPosition}
            spotLightAngle={props.spotLightAngle}
            spotLightPenumbra={props.spotLightPenumbra}
            spotLightIntensity={props.spotLightIntensity}
            castShadow={props.castShadow}
          />

          <Environment
            preset={props.environmentPreset}
            blur={props.environmentBlur}
          />
          <Stage
            environment={props.stage.preset}
            intensity={props.stageIntensity}
            contactShadowOpacity={props.contactShadowOpacity}
          />
        </Canvas>
      </div>

      {/* Component End */}
    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  const {
    actions: { setProp },
    //Ground
    g_width,
    g_height,
    g_widthSegments,
    g_heightSegments,
    g_depth,
    g_threshold,
    //Scene
    containerHeight,
    containerWidth,
    shadows,
    cameraPosition,
    cameraFov,
    enablePan,
    enableZoom,
    enableRotate,
    ambientLightIntensity,
    spotLightPosition,
    spotLightAngle,
    spotLightPenumbra,
    spotLightIntensity,
    castShadow,
    environmentPreset,
    environmentBlur,
    environmentStage,
    stageIntensity,
    contactShadowOpacity,
    stage
  } = useNode((node) => ({
    //Ground
    g_width: node.data.props.g_width,
    g_height: node.data.props.g_height,
    g_widthSegments: node.data.props.g_widthSegments,
    g_heightSegments: node.data.props.g_heightSegments,
    g_depth: node.data.props.g_depth,
    g_threshold: node.data.props.g_threshold,
    //Scene
    containerHeight: node.data.props.containerHeight,
    containerWidth: node.data.props.containerWidth,
    shadows: node.data.props.shadows,
    cameraPosition: node.data.props.cameraPosition,
    cameraFov: node.data.props.cameraFov,
    enablePan: node.data.props.enablePan,
    enableZoom: node.data.props.enableZoom,
    enableRotate: node.data.props.enableRotate,
    ambientLightIntensity: node.data.props.ambientLightIntensity,
    spotLightPosition: node.data.props.spotLightPosition,
    spotLightAngle: node.data.props.spotLightAngle,
    spotLightPenumbra: node.data.props.spotLightPenumbra,
    spotLightIntensity: node.data.props.spotLightIntensity,
    castShadow: node.data.props.castShadow,
    environmentPreset: node.data.props.environmentPreset,
    environmentBlur: node.data.props.environmentBlur,
    environmentStage: node.data.props.environmentStage,
    stageIntensity: node.data.props.stageIntensity,
    contactShadowOpacity: node.data.props.contactShadowOpacity,
    stage: node.data.props.stage,
  }));

  //COMPONENT SETTINGS
  const [expanded, setExpanded] = React.useState(false);
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }
  const [groundColor, setGroundColor] = useColor("hex", "#a0a0a0");

  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === "panel0"}
        onChange={handlePanelChange("panel0")}
      >
        <AccordionSummary
          
          aria-controls="panel0bh-content"
          id="panel0bh-header"
        >
          <Typography>Container</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
            <Input
              label="Height"
              value={containerHeight}
              onChange={(e) => setProp((props) => (props.containerHeight = e.target.value))}
            />
            <Input
              label="Width"
              value={containerWidth}
              onChange={(e) => setProp((props) => (props.containerWidth = e.target.value))}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handlePanelChange("panel1")}
      >
        <AccordionSummary
          
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Camera</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
            <Input
              label="Position X"
              value={cameraPosition[0]}
              onChange={(e) => setProp((props) => (props.cameraPosition[0] = e.target.value))}
            />
            <Input
              label="Position Y"
              value={cameraPosition[1]}
              onChange={(e) => setProp((props) => (props.cameraPosition[1] = e.target.value))}
            />
            <Input
              label="Position Z"
              value={cameraPosition[2]}
              onChange={(e) => setProp((props) => (props.cameraPosition[2] = e.target.value))}
            />
            
          </Stack>

          <Input
              label="Field of View"
              value={cameraFov}
              onChange={(e) => setProp((props) => (props.cameraFov = e.target.value))}
            />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handlePanelChange("panel2")}
      >
        <AccordionSummary
          
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>Controls</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ButtonGroup
          buttonFlex={1}
          >
            <Button
              variant={enablePan ? "contained" : "outlined"}
              onClick={() => setProp((props) => (props.enablePan = !enablePan))}
            >
              Pan
            </Button>
            <Button
              variant={enableZoom ? "contained" : "outlined"}
              onClick={() => setProp((props) => (props.enableZoom = !enableZoom))}
            >
              Zoom
            </Button>
            <Button
              variant={enableRotate ? "contained" : "outlined"}
              onClick={() => setProp((props) => (props.enableRotate = !enableRotate))}
            >
              Rotate
            </Button>
          </ButtonGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handlePanelChange("panel3")}
      >
        <AccordionSummary
          
          aria-controls="panel0bh-content"
          id="panel0bh-header"
        >
          <Typography>Lights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
            <Input
              label="Ambient Light Intensity"
              value={ambientLightIntensity}
              onChange={(e) => setProp((props) => (props.ambientLightIntensity = e.target.value))}
            />
            <Input
              label="Spot Light Position"
              value={spotLightPosition}
              onChange={(e) => setProp((props) => (props.spotLightPosition = e.target.value))}
            />
            <Input
              label="Spot Light Angle"
              value={spotLightAngle}
              onChange={(e) => setProp((props) => (props.spotLightAngle = e.target.value))}
            />
            <Input
              label="Spot Light Penumbra"
              value={spotLightPenumbra}
              onChange={(e) => setProp((props) => (props.spotLightPenumbra = e.target.value))}
            />
            <Input
              label="Spot Light Intensity"
              value={spotLightIntensity}
              onChange={(e) => setProp((props) => (props.spotLightIntensity = e.target.value))}
            />
            <Button
              variant={castShadow ? "contained" : "outlined"}
              onClick={() => setProp((props) => (props.castShadow = !castShadow))}
            >
              Cast Shadow
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handlePanelChange("panel4")}
      >
        <AccordionSummary
          
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>Environment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
            <Select
              label="Preset"
              value={environmentPreset}
              onChange={(e, val) => setProp((props) => (props.environmentPreset = val))}
            >
              <Option value="city">City</Option>
              <Option value="warehouse">Warehouse</Option>
              <Option value="sunset">Sunset</Option>
              <Option value="dawn">Dawn</Option>
              <Option value="night">Night</Option>
            </Select>
            
            <Slider 
              label="Blur"
              value={environmentBlur}
              onChange={(e) => setProp((props) => (props.environmentBlur = e.target.value))}
              min={0}
              max={1}
              step={0.1}
            />

            <Input
              label="Intensity"
              value={stageIntensity}
              onChange={(e) => setProp((props) => (props.stageIntensity = e.target.value))}
            />
            <Input
              label="Contact Shadow Opacity"
              value={contactShadowOpacity}
              onChange={(e) => setProp((props) => (props.contactShadowOpacity = e.target.value))}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handlePanelChange("panel5")}
      >
        <AccordionSummary
          
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>Ground</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
          <ColorPicker
            width={250}
            height={250}
            color={groundColor}
            onChange={(color) =>
              setProp((props) => (props.svgElement[0].stroke = color.hex))
            }
          />
            <Input
              label="Width"
              value={g_width}
              onChange={(e) => setProp((props) => (props.g_width = e.target.value))}
            />
            <Input
              label="Height"
              value={g_height}
              onChange={(e) => setProp((props) => (props.g_height = e.target.value))}
            />
            <Input
              label="Width Segments"
              value={g_widthSegments}
              onChange={(e) => setProp((props) => (props.g_widthSegments = e.target.value))}
            />
            <Input
              label="Height Segments"
              value={g_heightSegments}
              onChange={(e) => setProp((props) => (props.g_heightSegments = e.target.value))}
            />
            <Input
              label="Depth"
              value={g_depth}
              onChange={(e) => setProp((props) => (props.g_depth = e.target.value))}
            />
            <Input
              label="Threshold"
              value={g_threshold}
              onChange={(e) => setProp((props) => (props.g_threshold = e.target.value))}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handlePanelChange("panel6")}
      >
        <AccordionSummary
          
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>Stage</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} justifyContent="space-between">
            <Select
              label="Preset"
              value={stage.preset}
              onChange={(e, val) => setProp((props) => (props.stage.preset = e.val))}
            >
              <Option value="rembrandt">Rembrandt</Option>
              <Option value="portrait">Portrait</Option>
              <Option value="upfront">Upfront</Option>
              <Option value="soft">Soft</Option>
            </Select>
            
            <Select
              label="Shadows"
              value={stage.shadows}
              onChange={(e, val) => setProp((props) => (props.stage.shadows = val))}
            >
              <Option value="contact-us">Contact</Option>
              <Option value="cumulative">Cumulative</Option>
            </Select>
          </Stack>
        </AccordionDetails>
      </Accordion>

    </>
  );
};

// Define CraftJS specific properties for your component
EditableInteractiveScene1.craft = {
  displayName: "InteractiveScene1",
  props: {
    containerHeight: "500px",
    containerWidth: "100vw",
    shadows: true,
    cameraPosition: [0, 3, 15],
    cameraFov: 50,
    enablePan: true,
    enableZoom: true,
    enableRotate: true,
    ambientLightIntensity: 0.5,
    spotLightPosition: [15, 20, 5],
    spotLightAngle: 0.3,
    spotLightPenumbra: 0.1,
    spotLightIntensity: 2,
    castShadow: true,
    environmentPreset: "city",
    environmentBlur: 0.3,
    environmentStage: "city",
    stageIntensity: 0.5,
    contactShadowOpacity: 0.5,
  },
  related: {
    settings: ComponentSettings,
  },
};
