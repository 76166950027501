import axios from "axios";

const ContentApi = {

  //--url
  baseUrl: 'https://api.soimagine.co.za/wp-json',
  namespace: '/sa9ecms/v1/',
  endpoint: '/content',

  //--create
  create: (data) => {
    let bodyParams = {
      content_title: data?.title,
      content_content: data?.content,
      content_status: data?.status,
      content_author: data?.author,
      featured_image: data?.featured_image,
    };
    
    try {
      let req = axios.post(
        `${ContentApi.baseUrl}${ContentApi.namespace}${ContentApi.endpoint}`,
        bodyParams,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--get all
  getAll: () => {
    try {
      let req = axios.get(
        `${ContentApi.baseUrl}${ContentApi.namespace}${ContentApi.endpoint}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--get one
  getOne: (id) => {
    try {
      let req = axios.get(
        `${ContentApi.baseUrl}${ContentApi.namespace}${ContentApi.endpoint}/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--update
  update: (data) => {
    let bodyParams = {
      content_title: data?.title,
      content_content: data?.content,
      content_status: data?.status,
      content_author: data?.author,
      featured_image: data?.featured_image,
    };
    
    try {
      let req = axios.put(
        `${ContentApi.baseUrl}${ContentApi.namespace}${ContentApi.endpoint}/${data?.id}`,
        bodyParams,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--delete
  delete: (id) => {
    try {
      let req = axios.delete(
        `${ContentApi.baseUrl}${ContentApi.namespace}${ContentApi.endpoint}/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },
};

export default ContentApi;