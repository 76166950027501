import React from 'react';
//form fields
import { Input, Textarea, Select, Option, Button } from '@mui/joy';
import { FilePickerOpener } from 'src/dashboard/components/FilePicker';
import RichTextEditor from '../pages/designer/editorComponents/editorFields/RichTextEditor';
//layout
import { Box, Grid, Stack, Typography, Card, CardContent, CardActions, Divider } from '@mui/joy';
import { useFormik } from 'formik';
import * as Yup from 'yup'


export const ContentTypes = [
  //Projects, Portfolio, Services, Blogs, Testimonials, Team, Clients, Contact
  {
    id: 1,
    name: 'Services',
    type: 'service',
    icon: 'material-symbols:auto-read-play-sharp',
    description: 'Projects are the main content type for the In-Company products',
    active: false,
  },
  {
    id: 2,
    name: 'Portfolio',
    type: 'portfolio',
    icon: 'material-symbols:auto-read-play-sharp',
    description: 'Portfolio are the main content type for the In-Company Portfolio',
  },
  {
    id: 3,
    name: 'Products',
    type: 'product',
    icon: 'material-symbols:shop-two-sharp',
    description: 'Products are the main content type for the In-Company Products',
  },
  {
    id: 5,
    name: 'Gallery',
    type: 'gallery',
    icon: 'material-symbols:photo-prints-sharp',
    descriptions: "These Items Make up the Gallery Page Content"
  },
  {
    id: 7,
    name: 'Testimonials',
    type: 'testimonial',
    icon: 'material-symbols:thumb-up-sharp',
    description: 'Testimonials are the main content type for the In-Company Testimonials',
  },
  {
    id: 8,
    name: 'Team',
    type: 'team',
    icon: 'material-symbols:group-sharp',
    description: 'Team are the main content type for the In-Company Team',
  },
  {
    id: 9,
    name: 'Clients',
    type: 'client',
    icon: 'material-symbols:business-sharp',
    description: 'Clients are the main content type for the In-Company Clients',
  },
  {
    id: 10,
    name: 'Contact',
    type: 'contact-us',
    icon: 'material-symbols:contact-us-mail-sharp',
    description: 'Contact are the main content type for the In-Company Contact',
  },
  {
    id: 12,
    name: 'FAQs',
    type: 'faq',
    icon: 'material-symbols:help-sharp',
    description: 'FAQs are the main content type for the In-Company FAQs',
  },
  {
    id: 15,
    name: 'Events',
    type: 'event',
    icon: 'material-symbols:event-sharp',
  }
]
