import React from "react";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import PostGridStyle2 from "../PostGrid/PostGridStyle2";
import AboutStyle4 from "../About/AboutStyle4";
import AboutStyle5 from "../About/AboutStyle5";
import IconBoxStyle2, { FeaturesCard } from "../IconBox/IconBoxStyle2";
import PortfolioSlider from "../Slider/PortfolioSlider";
import PricingTableList from "../PricingTable/PricingTableList";
import { pageTitle } from "../../helpers/PageTitle";
import GalleryScene from "../reactThree/GalleryScene";
//api
import { getPartners, getClients } from "../../api/constantsAPI";
import { getArticles } from "../../api/articlesAPI";
import { getPortfolio } from "../../api/portfolioAPI";
import BrandsMarquee from "../Brands/BrandsMarquee";
import Award from "../Award";
import TypewriterEffect from "../Framer/TypewriterEffect";
import HeroStyle5 from "../Hero/HeroStyle5";
import { motion } from "framer-motion";
import PageTransition from "../Framer/PageTransitions";

const postData = [
  {
    date: "05 Mar 2023",
    title: "How to keep fear from ruining your art business with confident",
    thumbnailSrc: "/images/studio-agency/post_2.jpeg",
    href: "/blog/blog-details",
  },
  {
    date: "07 Mar 2023",
    title: "Artistic mind will be great for creation anything",
    thumbnailSrc: "/images/studio-agency/post_1.jpeg",
    href: "/blog/blog-details",
  },
  {
    date: "03 Mar 2023",
    title: "How to keep fear from ruining your art business with confident",
    thumbnailSrc: "/images/studio-agency/post_3.jpeg",
    href: "/blog/blog-details",
  },
  {
    date: "02 Mar 2023",
    title: "How to keep fear from ruining your art business with confident",
    thumbnailSrc: "/images/studio-agency/post_4.jpeg",
    href: "/blog/blog-details",
  },
];

const brandList = [
  {
    logoSrc: "/soimagine/partners/partner1.png",
    logoAlt: "AWS Partner Network",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner2.png",
    logoAlt: "Dell Technologies",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner3.png",
    logoAlt: "Open Suse",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner4.png",
    logoAlt: "Huawei",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner5.png",
    logoAlt: "Dahua Technology",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner6.png",
    logoAlt: "UBIBOT",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
];

const brandListDark = [
  {
    logoSrc: "/soimagine/partners/partner1.png",
    logoAlt: "AWS Partner Network",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner2.png",
    logoAlt: "Dell Technologies",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner3.png",
    logoAlt: "Open Suse",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner4.png",
    logoAlt: "Huawei",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner5.png",
    logoAlt: "Dahua Technology",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner6.png",
    logoAlt: "UBIBOT",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
];
const testimonialData = [
  {
    text: "SoImagines Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ansari Patron",
    avatarDesignation: "CEO at Delta",
  },
  {
    text: "SoImagines Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Jhon Doe",
    avatarDesignation: "Manager at Delta",
  },
  {
    text: "SoImagines Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ramatam Coo",
    avatarDesignation: "MD at Delta",
  },
];
const portfolioData = [
  {
    thumbnailSrc: "/soimagine/BoardFlow_banner_sm.jpg",
    miniTitle: "WorkWize 360 is an innovative productivity tool ",
    title: "Workwize 360",
    subTitle:
      "WorkWize 360 is an innovative productivity tool designed to streamline workforce management and enhance collaboration within your organization. With its comprehensive set of features, WorkWize 360 empowers businesses to efficiently manage their human resources and optimize productivity.",
    href: "/portfolio/portfolio-details",
  },
  {
    thumbnailSrc: "/soimagine/BoardFlow_banner_sm.jpg",
    miniTitle: "Board Meeting Management Platform",
    title: "BoardFlow",
    subTitle:
      "BoardFlow is a board meeting management platform that allows for the seamless management of board meetings, agendas, and minutes. It is a secure and efficient platform that allows for the easy management of board meetings.",
    href: "/portfolio/portfolio-details",
  },
  {
    thumbnailSrc: "/soimagine/digim_banner_sm.jpg",
    miniTitle: "Digital Signage Solution",
    title: "Digim",
    subTitle:
      "Digim is a digital signage solution that allows for the easy management of digital signage content. It is a cloud-based solution that allows for the easy management of digital signage content from anywhere in the world.",
    href: "/portfolio/portfolio-details",
  },
  {
    thumbnailSrc: "/soimagine/Mobile-App-Screen-Mockups.jpg",
    miniTitle: "Relocation and Courier Service",
    title: "Your Move",
    subTitle:
      "Your Move is a relocation and courier service that allows for the easy management of relocations and courier services. It is a secure and efficient platform that allows for the easy management of relocations and courier services.",
    href: "/portfolio/portfolio-details",
  },
];

export default function DigitalAgencyPage({ darkMode }) {
  //CONSTANTS
  const title = "SoImagine Systems | Digital Agency";

  //STATES
  const [isDarkMode, setIsDarkMode] = React.useState(darkMode);
  const [partners, setPartners] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [portfolio, setPortfolio] = React.useState([]);
  const [articles, setArticles] = React.useState([]);

  //HANDLERS
  const handleContentLoad = async () => {
    //get partners
    getPartners().then((data) => {
      setPartners(data);
    });

    //get clients
    getClients().then((data) => {
      setClients(data);
    });

    //get portfolio
    getPortfolio({
      page: 1,
      category: "",
      tag: "",
    }).then((data) => {
      setPortfolio(data);
    });

    //get articles
    getArticles({
      page: 1,
      category: "",
      tag: "",
    }).then((data) => {
      setArticles(data);
    });
  };

  //EFFECTS
  React.useEffect(() => {
    pageTitle(title);

    async function fetchData() {
      await handleContentLoad();
    }

    fetchData();
  }, []);

  //RENDER
  return (
    <PageTransition>
      <div
        style={{
          height: "100vh",
        }}
      >
        <GalleryScene />
      </div>

      <AboutStyle4
        thumbnailSrc="/soimagine/brand/soimagine_logo_on_glass.jpg"
        miniTitle="Welcome to SoImagine"
        title="From Concept to Creation: We Make IT Happen"
        subTitle="At SoImagine Systems, we breathe life into your digital dreams. With our cutting-edge approach to App Development, Web Development, and A.I Based Application Development, we're not just building platforms; we're crafting the future. Our mantra, 'Making your ideas Go Live,' reflects our commitment to transforming your visions into reality. From conceptualization to launch, we're with you every step of the way."
        btnText="See Our Services"
        btnUrl="/service"
      />

      <Spacing lg="85" md="45" />

      <AboutStyle5
        thumbnailSrc="/soimagine/soimagineLogoInOffice.jpg"
        videoSrc="/soimagine/showcase.mp4"
        miniTitle="What We Do"
        title="Best value service provider agency"
        subTitle="We make specializing in Software Development, Web Development, Mobile App Development, and A.I Based Application Development. We provide the best value service to our clients. We are a team of highly skilled professionals who are dedicated to providing the best value service to our clients. We are committed to delivering high-quality services that meet the needs of our clients."
        progressBarList={[
          { title: "Web Development", percentage: "95" },
          { title: "Mobile Apps", percentage: "90" },
          { title: "Desktop Applications", percentage: "85" },
          { title: "A.I Based Applications", percentage: "98" },
        ]}
      />

      <section className="cs_primary_bg">
        <Spacing lg="85" md="45" />

        <div className="container">
          <SectionHeading
            title="Exceeding expectations our <br />service is our promise"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <div className="row cs_gap_y_45">
            <div className="col-lg-4 col-sm-6">
              <FeaturesCard
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Application Development"
                //create a short one-liner for the service
                subTitle="Designing and developing applications that are Powerful, Scalable, and Secure."
                features={[
                  "Web Development",
                  "Mobile App Development",
                  "Desktop Applications",
                  "A.I Based Applications",
                  "Cloud Solutions",
                  "Custom Software Development",
                ]}
              />
            </div>

            <div className="col-lg-4 col-sm-6">
              <FeaturesCard
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Business Process Re-engineering"
                //create a short one-liner for the service
                subTitle="Reimagining and redesigning business processes to achieve operational excellence."
                features={[
                  "Process Automation",
                  "Workflow Management",
                  "Business Analysis",
                  "Process Optimization",
                  "Business Process Management",
                  "Process Mapping",
                ]}
              />
            </div>

            <div className="col-lg-4 col-sm-6">
             <FeaturesCard
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Marketing & Branding"
                //create a short one-liner for the service
                subTitle="Creating and executing marketing strategies that drive growth and build brand value."
                features={[
                  "Digital Marketing",
                  "Social Media Marketing",
                  "Content Marketing",
                  "SEO & SEM",
                  "Email Marketing",
                  "Branding",
                ]}
              />
            </div>

          </div>
        </div>
      </section>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Featured Products" subTitle="Products" />
          <Spacing lg="85" md="45" />
        </div>
        <PortfolioSlider data={portfolioData} />
        <Spacing lg="150" md="80" />
      </section>

      <section className="cs_gray_bg_2 cs_shape_animation_2">
        <div className="cs_shape_1 position-absolute">
          <svg
            width={57}
            height={41}
            viewBox="0 0 57 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M55.1923 32.7347C52.209 37.0618 42.704 43.3879 40.3199 34.8097C39.0585 30.2359 42.6536 24.5172 43.1266 19.9687C43.8204 13.2821 39.973 5.41874 32.4359 6.05135C31.8886 6.10385 31.3452 6.19047 30.8086 6.31072C29.5308 4.21019 27.6918 2.5107 25.5002 1.40489C23.3085 0.299087 20.8519 -0.168816 18.4087 0.0542238C11.0797 0.743766 5.31489 7.39247 2.40097 13.6616C0.297443 17.9159 -0.0470809 22.831 1.44227 27.3386C2.02253 28.9897 4.52649 28.3381 4.07868 26.6111C2.33789 19.994 6.55742 12.4976 11.1238 7.99978C15.6903 3.50194 23.1517 2.572 26.8919 7.71511C22.3191 9.96086 18.2321 14.1614 15.6019 17.957C12.4483 22.5245 9.15598 31.3746 15.1794 35.2589C21.0135 39.0165 27.7181 36.5241 30.9852 30.8306C34.3217 24.9727 34.2461 16.9828 32.6377 10.6251C32.4379 9.84013 32.1764 9.07227 31.8556 8.32873C34.7948 7.65817 37.6078 8.80319 39.4747 11.7765C42.4517 16.502 40.9317 21.6009 39.5126 26.5289C38.1691 31.1785 36.6617 38.618 42.9185 40.5728C48.4057 42.2935 54.6246 38.6054 56.4853 33.2978C56.7691 32.507 55.6275 32.1085 55.1923 32.7347ZM27.8253 28.0345C26.6396 30.7231 24.5519 32.7537 21.5181 32.9751C16.3336 33.3547 15.3434 29.0909 16.4723 25.0739C18.1122 19.3298 23.2274 13.4845 28.1407 10.0684C28.1664 10.1199 28.1896 10.1727 28.2101 10.2266C30.2749 16.0071 30.1378 22.349 27.8253 28.0345Z"
              fill="#4F4747"
            />
          </svg>
        </div>
        <div className="cs_shape_2 position-absolute">
          <svg
            width={21}
            height={24}
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M1.83883 0.992714L20.9086 13.6609L0.402728 23.8417L1.83883 0.992714Z"
              fill="#4F4747"
            />
          </svg>
        </div>
        <div className="cs_height_143 cs_height_lg_75" />
        <div className="container">
          <SectionHeading
            title="Achievements"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award
            data={[
              {
                title: "Best Digital Agency",
                subTitle: "2021",
                date: "2021",
                awardImgUrl: "/soimagine/achievement1_1_dark.png",
              },
            ]}
          />
        </div>

        <div className="cs_height_150 cs_height_lg_80" />
      </section>

      {/* <TestimonialSlider
        layeredImages={[
          '/images/digital-agency/layer_img_1.png',
          '/images/digital-agency/layer_img_2.png',
          '/images/digital-agency/layer_img_3.png',
          '/images/digital-agency/layer_img_4.png',
          '/images/digital-agency/layer_img_5.png',
        ]}
        data={testimonialData}
      /> */}

      {/* <Cta
        title="Is there a specific project or goal <br/>that you have in mind?"
        btnText="Contact Us"
        btnUrl="/contact-us"
        bgUrl="/images/creative-agency/cta_bg.jpeg"
        variant="cs_type_1"
      /> */}

      <section className="cs_shape_animation_3">
        <div className="cs_shape_1 position-absolute">
          <svg
            width={509}
            height={458}
            viewBox="0 0 509 458"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={254} cy={229} r="228.5" stroke="currentColor" />
            <circle
              cx={26}
              cy={237}
              r="25.5"
              className="anio_3_c_1"
              fill="white"
              stroke="currentColor"
            />
            <circle cx={26} cy={237} r={15} fill="currentColor" />
            <circle
              cx={483}
              cy={229}
              r="25.5"
              className="anio_3_c_1"
              fill="white"
              stroke="currentColor"
            />
            <circle cx={483} cy={229} r={15} fill="currentColor" />
          </svg>
        </div>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Articles" subTitle="Stay Informed" />
          <Spacing lg="85" md="45" />
          <PostGridStyle2 data={postData} />
        </div>
      </section>
      <Spacing lg="135" md="70" />

      <BrandsMarquee data={darkMode ? brandListDark : brandList} />

      <Spacing lg="84" md="50" />
    </PageTransition>
  );
}
