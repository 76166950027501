import { Icon } from '@iconify/react';
import React from 'react';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function Ratings({ ratingNumber, smallSize }) {
  return (
    <div
      className={`cs_rating cs_accent_color ${smallSize ? 'cs_size_sm' : ''}`}
    >
      <div className="cs_rating_bg">
        <i>
          <Icon icon="fa6-regular:star" />
        </i>
        <i>
          <Icon icon="fa6-regular:star" />
        </i>
        <i>
          <Icon icon="fa6-regular:star" />
        </i>
        <i>
          <Icon icon="fa6-regular:star" />
        </i>
        <i>
          <Icon icon="fa6-regular:star" />
        </i>
      </div>
      <div
        className="cs_rating_percentage"
        style={{ width: `${ratingNumber * 20}%` }}
      >
        <i>
          <Icon icon="fa6-solid:star" />
        </i>
        <i>
          <Icon icon="fa6-solid:star" />
        </i>
        <i>
          <Icon icon="fa6-solid:star" />
        </i>
        <i>
          <Icon icon="fa6-solid:star" />
        </i>
        <i>
          <Icon icon="fa6-solid:star" />
        </i>
      </div>
    </div>
  );
}
