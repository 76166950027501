import React from 'react';
//Editor
import { useNode } from "@craftjs/core";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import { Box, Input, Stack, Typography, Button as JButton, Card, CardContent, CardActions } from '@mui/joy';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import RichTextEditor from 'src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor';
import { FilePickerOpener } from 'src/dashboard/components/FilePicker';

export default function CardStyle2({ miniTitle, title, subTitle, variant }) {
  return (
    <div className={`${variant} cs_radius_15`}>
      <span className="cs_primary_color cs_radius_15">{miniTitle}</span>
      <h2 className="cs_fs_29 cs_semibold">{title}</h2>
      <p className="mb-0">{subTitle}</p>
    </div>
  );
}

export const EditableCard2 = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <CardStyle2 {...props} />

      {/* Component End */}

    </Box>
  );
    
};

// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    miniTitle,
    title,
    subTitle,
  } = useNode((node) => ({
    miniTitle: node.data.props.miniTitle,
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >

          <Box sx={{ margin: "10px 0" }}>
            <Typography level="label">Mini Title</Typography>
            <Input
              value={miniTitle}
              onChange={(e) => setProp((props) => (props.miniTitle = e.target.value))}
              placeholder="Mini Title"
            />
          </Box>

          <Box sx={{ margin: "10px 0" }}>
            <Typography level="label">Title</Typography>
            <Input
              value={title}
              onChange={(e) => setProp((props) => (props.title = e.target.value))}
              placeholder="Title"
            />
          </Box>

          <Box sx={{ margin: "10px 0" }}>
            <Typography level="label">Sub Title</Typography>
            <Input
              value={subTitle}
              onChange={(e) => setProp((props) => (props.subTitle = e.target.value))}
              placeholder="Sub Title"
            />
          </Box>
          
        </AccordionDetails>
      </Accordion>

    </>
  );
};

EditableCard2.craft = {
  props: {
    miniTitle: "Mini Title",
    title: "Title",
    subTitle: "Sub Title",
    variant: "cs_bg_white",
  },
  related: {
    settings: ComponentSettings,
  },
};
