import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Chip,
  Tabs,
  TabList,
} from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import { useFormik } from "formik";
import * as yup from "yup";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useLocation } from "react-router-dom";
import SiteOptionsApi from "./CustomizeApi";
import { AnimatePresence, motion } from "framer-motion";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";

/*COMPONENT*/
//--pagesList | default
export default function CustomizeEditor() {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      status: "draft",
      visibility: "public",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
    }),
    onSubmit: (values) => {
      publishCustomOptions(values);
    },
  });

  //STATES
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [customizeOptions, setCustomizeOptions] = React.useState({});

  //FUNCTIONS
  //--publish the page
  //FUNCTIONS
  async function publishCustomOptions(values) {
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://api.soimagine.co.za/wp-json/sa9ecms/v1/blog/add",
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setCustomizeOptions(json);

      setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);
    } catch (error) {
      setIsError(true);
      setIsSubmitting(false);
      console.error(error);
      return;
    }
  }

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/*TABS*/}
      <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: "transparent" }}>
        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: "sm",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
        >
          <Tab disableIndicator>General</Tab>
          <Tab disableIndicator>Design</Tab>
          <Tab disableIndicator>Header</Tab>
          <Tab disableIndicator>Footer</Tab>
          <Tab disableIndicator>SEO</Tab>
          <Tab disableIndicator>Social</Tab>
          <Tab disableIndicator>Advanced</Tab>
        </TabList>

        <TabPanel value={0}>
          <GeneralOptions />
        </TabPanel>

        <TabPanel value={1}>
          <Typography variant="h6" gutterBottom>
            Design Options
          </Typography>
        </TabPanel>
        <TabPanel value={2}>
          <Typography variant="h6" gutterBottom>
            Header Options
          </Typography>
        </TabPanel>
        <TabPanel value={3}>
          <Typography variant="h6" gutterBottom>
            Footer Options
          </Typography>
        </TabPanel>
        <TabPanel value={4}>
          <Typography variant="h6" gutterBottom>
            SEO Options
          </Typography>
        </TabPanel>
        <TabPanel value={5}>
          <Typography variant="h6" gutterBottom>
            Social Options
          </Typography>
        </TabPanel>
        <TabPanel value={6}>
          <Typography variant="h6" gutterBottom>
            Advanced Options
          </Typography>
        </TabPanel>
      </Tabs>
    </Box>
  );
}

//--General
function GeneralOptions() {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      //IDENTITY
      siteTitle: "",
      siteDescription: "",
      siteIcon: "",
      siteLogo: "",
    },
    validationSchema: yup.object({
      siteTitle: yup.string().required("Site Title is required"),
      siteDescription: yup.string().required("Site Description is required"),
    }),
    onSubmit: (values) => {
      publishOptions(values);
    },
  });

  //STATES
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  //FUNCTIONS
  //--Get the options
  async function getOptions(values) {
    setIsSubmitting(true);

    SiteOptionsApi.getGeneralOptions()
      .then((response) => {
        //set the options to the form
        formik.setFieldValue("siteTitle", response.siteTitle);
        formik.setFieldValue("siteDescription", response.siteDescription);
        formik.setFieldValue("siteIcon", response.siteIcon);
        formik.setFieldValue("siteLogo", response.siteLogo);

        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        console.error(error);
        return;
      });
  }

  //--publish the options
  async function publishOptions(values) {
    setIsSubmitting(true);

    SiteOptionsApi.updateGeneralOptions(values)
      .then((response) => {
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        console.error(error);
        return;
      });
  }

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            General Options
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel htmlFor="siteTitle">Site Title</FormLabel>
                <Input
                  id="siteTitle"
                  name="siteTitle"
                  type="text"
                  value={formik.values.siteTitle}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="siteDescription">
                  Site Description
                </FormLabel>
                <Textarea
                  id="siteDescription"
                  name="siteDescription"
                  value={formik.values.siteDescription}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FilePickerOpener
                  currentFile={formik.values.siteIcon}
                  onSelected={(file) => {
                    formik.setFieldValue("siteIcon", file.url);
                  }}
                />

                <FilePickerOpener
                  currentFile={formik.values.siteLogo}
                  onSelected={(file) => {
                    formik.setFieldValue("siteLogo", file.url);
                  }}
                />
              </Box>
            </Stack>
          </form>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
