import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import { useFormik } from "formik";
import * as yup from "yup";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useLocation } from "react-router-dom";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";

/*COMPONENT*/
//--pagesList | default
export default function BlogEditor() {
  //CONSTANTS
  const location = useLocation();
  const navigate = useNavigate();

  //STATES
  const [loading, setLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [blogId, setBlogId] = React.useState(location.state?.id);
  const [blogPost, setBlogPost] = React.useState(null);

  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: blogPost?.title || "",
      description: blogPost?.description || "",
      status: blogPost?.status || "draft",
      content: blogPost?.content || "",
      visibility: blogPost?.visibility || "public",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
      content: yup.string().required("Content is required"),
    }),
    onSubmit: (values) => {
      publishBlogPost(values);
    },
    enableReinitialize: true,
  });

  //FUNCTIONS
  async function getBlogPost(id) {
    setLoading(true);
    console.log("Getting Blog Post");

    try {
      const response = await fetch(
        `https://api.soimagine.co.za/wp-json/sa9ecms/v1/blog-post/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setBlogPost(json);
      console.log("BlogPost\n" + JSON.stringify(json, null, 2));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsError(true);
      setLoading(false);
      return;
    }
  }

  //--publish the page
  async function publishBlogPost(values) {
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://api.soimagine.co.za/wp-json/sa9ecms/v1/blog/add",
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setBlogPost(json);

      setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);
    } catch (error) {
      setIsError(true);
      setIsSubmitting(false);
      console.error(error);
      return;
    }
  }

  //EFFECTS
  //get the id from the location state
  React.useEffect(() => {
    if (location.state?.id) {
      setBlogId(location.state.id);
    }
  }, []);

  //get the blog post if the blogId is set
  React.useEffect(() => {
    if (blogId) {
      getBlogPost(blogId);
    }
  }, [blogId]);

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* Breadcrumbs / Actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            Blog
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Edit
          </Typography>
        </Breadcrumbs>
      </Box>

      {
        /* Page Content */
        loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Icon
              icon="svg-spinners:eclipse"
              style={{ fontSize: "48px" }}
              color={"#6B7280"}
            />
            <Typography level="title-lg">Loading...</Typography>
          </Box>
        ) : isError ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Icon
              icon="material-symbols:warning"
              style={{ fontSize: "48px" }}
              color={"#6B7280"}
            />
            <Typography level="title-lg">An Error Occured</Typography>
            <Typography level="title-sm">{error?.message}</Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {/* Column 1 */}
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: 2,
              }}
            >
              {/*Scrollable Container*/}
              <Box sx={{ 
                overflowY: "auto",
                height: "calc(100vh - 80px)", 
              }}>
                {/*Title*/}
                <FormControl sx={{ gridColumn: "1/-1" }}>
                  <Input
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    placeholder="Title"
                    sx={{
                      mb: 2,
                      width: "100%",
                    }}
                  />
                </FormControl>

                {/*WYSIWYG Editor*/}
                <RichTextEditor
                  content={formik.values.content}
                  onTextChange={(value) => {
                    formik.setFieldValue("content", value);
                  }}
                />
              </Box>
            </Grid>

            {/* Column 2 */}
            <Grid item xs={12} md={3}>
              <PublishSettingsForm />
              
              <FeaturedImageForm
                current={blogPost?.featured_image}
                onImageChange={(file) => {
                  console.log("Featured Image: " + JSON.stringify(file, null, 2));
                }}
              />
            </Grid>
          </Grid>
        )
      }
    </Box>
  );
}

//--Rich Text Editor
const RichTextEditor = ({ content, onTextChange }) => {
  //CONSTANTS
  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
    },
    label: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    editor: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "10px",
      minHeight: "100%",
    },
  };

  const q_formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "clean",
  ];

  const tb_options = [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code-block"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ];

  //STATES
  const [value, setValue] = React.useState(content);

  //REFS
  const editorRef = React.useRef(null);

  //EFFECTS
  //insert the HTML content into the editor on load
  React.useEffect(() => {
    if (content) {
      setValue(content);
    }
  }, [content]);

  //RENDER
  return (
    <div>
      <QuillEditor
        ref={editorRef}
        className={styles.editor}
        theme="snow"
        value={value}
        formats={q_formats}
        modules={{
          toolbar: tb_options,
        }}
        style={{
          height: "100%",
        }}
      />
    </div>
  );
};

//--Page Publish Settings
//--for publishing, Page Status, Visibility, etc.
function PublishSettingsForm({ onPublish }) {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      status: "draft",
      visibility: "public",
    },
    validationSchema: yup.object({
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);

  //FUNCTIONS

  //RETURN
  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "max-content",
        maxWidth: "100%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
      }}
    >
      <Typography
        level="title-sm"
        startDecorator={<Icon icon="mdi:file-document-edit" />}
      >
        Publish Settings
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Status</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              //--component props
              endDecorator={
                formik.values.status === "published" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="published">Published</Option>
              <Option value="draft">Draft</Option>
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Visibility</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="visibility"
              value={formik.values.visibility}
              onChange={formik.handleChange}
              error={
                formik.touched.visibility && Boolean(formik.errors.visibility)
              }
              helperText={formik.touched.visibility && formik.errors.visibility}
              //--component props
              endDecorator={
                formik.values.visibility === "public" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="public">Public</Option>
              <Option value="private">Private</Option>
            </Select>
          </FormControl>
        </Stack>

        <Button
          color="primary"
          size="sm"
          fullWidth
          onClick={formik.handleSubmit}
        >
          Publish
        </Button>
      </CardContent>
    </Card>
  );
}

//--Featured Image
//--for Selecting and Uploading a Featured Image
function FeaturedImageForm({
  current,
  onImageChange,
}) {

  //CONSTANTS

  //STATES

  //FUNCTIONS

  //EFFECTS

  //RETURN
  return (
    <Card variant="outlined"
    sx={{
      my: 2,
      maxHeight: "max-content",
      maxWidth: "100%",
    }}
    >
      <Typography
        level="title-sm"
        startDecorator={<Icon icon="mdi:image" />}
      >
        Featured Image
      </Typography>
      <Divider inset="none" />
      <CardContent>
        <FilePickerOpener
        currentFile={current? current : null}
        onSelected={(file) => onImageChange(file)}
        />
      </CardContent>
      <CardActions>
        <Button color="primary" size="sm" fullWidth>
          Upload Image
        </Button>
      </CardActions>
    </Card>
  );
}
