/* This Defines the Authentification service that will be used to authenticate the user.*/
import axios from "axios";


class PagesService {
  
  baseUrl = "http://localhost:3010/api/pages";
  namespace = "/sa9ecms/v1";
  endpoint = "/page";

  //--current user
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  //--create
  create = (data) => {
    let bodyParams = {
      page_title: data?.page_title,
      page_content: data?.page_content,
    };

    try {
      let req = axios.post(
        `${this.baseUrl}${this.namespace}${this.endpoint}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--get all
  getAll = () => {
    try {
      let req = axios.get(
        `${this.baseUrl}/list`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("PAGES REQUEST\n", req);
      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--get single
  getSingle = (id) => {
    try {
      let req = axios.get(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--update
  update = (data) => {
    let bodyParams = {
      page_title: data?.title,
      page_content: data?.content,
    };

    try {
      let req = axios.put(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${data?.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--delete
  delete = (id) => {
    try {
      let req = axios.delete(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };
}

const PagesApi = new PagesService();

//DEMO
export const DemoPagesList = [
  {
    id: 1,
    page_title: "About Us",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 2,
    page_title: "Services",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 3,
    page_title: "Contact Us",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 4,
    page_title: "Privacy Policy",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 5,
    page_title: "Terms & Conditions",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 6,
    page_title: "FAQs",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 7,
    page_title: "Careers",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 8,
    page_title: "Blog",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 9,
    page_title: "Pricing",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
  {
    id: 10,
    page_title: "404 Error",
    page_author: "Admin",
    page_status: "Published",
    page_last_modified: "2021-09-01",
  },
];

export default PagesApi;
