import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Sheet,
  Stack,
  Switch,
  Textarea,
  ToggleButtonGroup,
} from "@mui/joy";
import { useFormik } from "formik";
import * as yup from "yup";
import MenusApi from "./MenuApi";
import PagesApi from "../pages/PagesApi";
import { useLocation } from "react-router-dom";

/*COMPONENT*/
//--pagesList | default
export default function MenuEditor() {
  //CONSTANTS
  const location = useLocation();

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);
  const [menuContent, setMenuContent] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(null);

  //FUNCTIONS
  //--using the ID, get the menu content from the API
  function getMenuContent() {
    //Get the id from the URL
    const mId = location.pathname.split("/").pop();

    MenusApi.getSingle(mId)
      .then((res) => {
        console.log(res.data);
        setMenuContent(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //USE EFFECTS
  //--get menu content
  React.useEffect(() => {
    getMenuContent();
  }, []);

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* Breadcrumbs / Actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            Pages
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create
          </Typography>
        </Breadcrumbs>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            color="primary"
            startDecorator={
              <Icon
                icon="carbon:document-add"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Publish
          </Button>

          <Button
            color="info"
            startDecorator={
              <Icon
                icon="mdi:cancel"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Cancel
          </Button>
        </Box>
      </Box>

      {/* Page Content */}
      <Grid container spacing={2}>
        {/* Sortable List of Menu Items
        With Add Item Button */}
        <Grid item xs={12} md={2}>
          {isBusy && !menuContent ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : menuContent &&
            menuContent.menu_links &&
            menuContent.menu_links.length > 0 ? (
            //map through the menu_links
            menuContent.menu_links.map((menu, index) => (
              <EachMenuItem
                key={index}
                menu={menu}
                handleMenuEdit={() => console.log("edit menu")}
                handleMenuDelete={() => console.log("delete menu")}
                OnSelectMenuItem={(menu) => setSelectedMenuItem(menu)}
                Selected={selectedMenuItem}
              />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography>No Menu Items Found</Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={7}>
          <MenuItemOptions selected={selectedMenuItem} />
        </Grid>

        {/* Column 2 */}
        <Grid item xs={12} md={3}>
          <PublishSettingsForm />
        </Grid>
      </Grid>
    </Box>
  );
}

function EachMenuItem({
  menu,
  index,
  handleMenuEdit,
  handleMenuDelete,
  OnSelectMenuItem,
  Selected,
}) {
  /*Rectangle with Grab Icon | Menu_name
  | Edit Icon | Delete Icon*/
  return (
    <Card
      variant={Selected?.id === menu.id ? "soft" : "outlined"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        mb: 1,
      }}
      onClick={() => {
        OnSelectMenuItem(menu);
      }}
    >
      <Typography
        level="title-sm"
        startDecorator={<Icon icon="carbon:grabber" />}
      >
        {menu?.title}
      </Typography>

      <Divider />

      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon
            icon="carbon:grabber"
            style={{ fontSize: "16px", color: "#6B7280" }}
          />
          <Typography fontWeight={500} fontSize={14} ml={1}>
            {menu?.menu_name}
          </Typography>
        </Box>

        <ButtonGroup orientation="vertical" buttonFlex={1}>
          <Button
            color="primary"
            size="sm"
            startDecorator={
              <Icon
                icon="carbon:edit"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            onClick={handleMenuEdit}
          >
            Edit
          </Button>

          <Button
            color="error"
            size="sm"
            startDecorator={
              <Icon
                icon="carbon:delete"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            onClick={handleMenuDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </CardContent>
    </Card>
  );
}

function MenuItemOptions({ selected, setSelectedMenuItem }) {
  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "max-content",
        maxWidth: "100%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "horizontal",
      }}
    >
      <Typography level="title-lg" startDecorator={<Icon icon="mdi:menu" />}>
        Menu Item Options
      </Typography>
      <Divider inset="none" />
      <CardContent
      >
        <FormControl>
          <FormLabel>Menu Name</FormLabel>
          <Input
            type="text"
            placeholder="Menu Name"
            fullWidth
            variant="outlined"
            value={selected?.menu_name}
            onChange={(e) => {
              setSelectedMenuItem(
                (selected) => (selected.menu_name = e.target.value)
              );
            }}
          />
        </FormControl>

        <LinkComponentSettings
          settings={{
            url: selected?.url,
            page: selected?.page,
            openInNewTab: selected?.openInNewTab,
            noFollow: selected?.noFollow,
          }}
          onUpdate={(settings) => {
            setSelectedMenuItem((selected) => (selected = settings));
          }}
        />
      </CardContent>
    </Card>
  );
}

//LINK / NAVIGATION SETTINGS
const LinkComponentSettings = ({ settings, onUpdate }) => {
  //CONSTANTS
  const linkTypes = [
    {
      id: "url",
      name: "URL",
      icon: "carbon:link",
      value: "url",
    },
    {
      id: "page",
      name: "Page",
      icon: "carbon:page-scroll",
      value: "page",
    },
  ];

  //COMPONENTS
  const UrlLinkSettings = ({ url, openInNewTab, noFollow }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Typography level="title-sm">URL</Typography>
        <Input
          size="sm"
          placeholder="URL"
          value={url}
          onChange={(e) => {
            setLinkSettings((settings) => (settings.url = e.target.value));
          }}
        />

        <Divider />

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography level="title-sm">Open in New Tab</Typography>
          <Switch
            checked={openInNewTab}
            onChange={(e) => {
              setLinkSettings(
                (settings) => (settings.openInNewTab = e.target.checked)
              );
            }}
          />
        </Stack>

        <Divider />

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography level="title-sm">No Follow</Typography>
          <Switch
            checked={noFollow}
            onChange={(e) => {
              setLinkSettings(
                (settings) => (settings.noFollow = e.target.checked)
              );
            }}
          />
        </Stack>
      </Box>
    );
  };

  const PageLinkSettings = ({ selectedPage, openInNewTab, noFollow }) => {
    //STATES
    const [pages, setPages] = React.useState([]);

    //USE EFFECTS
    React.useEffect(() => {
      PagesApi.getAll()
        .then((res) => {
          setPages(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

    return pages.length === 0 ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Typography level="title-sm">Page</Typography>
        <Select
          size="sm"
          value={selectedPage}
          onChange={(e, value) => {
            setLinkSettings((settings) => (settings.page = value));
          }}
        >
          {pages.map((page, index) => (
            <Option key={index} value={page.id}>
              {page.page_title}
            </Option>
          ))}
        </Select>

        <Divider />

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography level="title-sm">Open in New Tab</Typography>
          <Switch
            checked={openInNewTab}
            onChange={(e) => {
              setLinkSettings(
                (settings) => (settings.openInNewTab = e.target.checked)
              );
            }}
          />
        </Stack>

        <Divider />

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography level="title-sm">No Follow</Typography>
          <Switch
            checked={noFollow}
            onChange={(e) => {
              setLinkSettings(
                (settings) => (settings.noFollow = e.target.checked)
              );
            }}
          />
        </Stack>
      </Box>
    );
  };

  //STATES
  const [title, setTitle] = React.useState("");
  const [linkType, setLinkType] = React.useState("url");
  const [linkSettings, setLinkSettings] = React.useState({
    url: "",
    page: "",
    openInNewTab: false,
    noFollow: false,
  });

  //USE EFFECTS
  React.useEffect(() => {
    if (settings) {
      setTitle(settings.url);
      setLinkSettings(settings);
    }
  }, [settings]);

  //RETURN
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 1,
        flexDirection: "column",
      }}
    >
      <Typography level="title-sm">Link</Typography>
      <ToggleButtonGroup
        buttonFlex={1}
        value={linkType}
        exclusive
        onChange={(e, value) => {
          setLinkType(value);
        }}
      >
        {linkTypes.map((type, index) => (
          <Button key={index} value={type.value}>
            <Icon icon={type.icon} />
          </Button>
        ))}
      </ToggleButtonGroup>

      {linkType === "url" ? (
        <UrlLinkSettings
          url={linkSettings.url}
          openInNewTab={linkSettings.openInNewTab}
          noFollow={linkSettings.noFollow}
        />
      ) : (
        <PageLinkSettings
          selectedPage={linkSettings.page}
          openInNewTab={linkSettings.openInNewTab}
          noFollow={linkSettings.noFollow}
        />
      )}
    </Box>
  );
};

//--Page Basic Info

//--Page Publish Settings
//--for publishing, Page Status, Visibility, etc.
function PublishSettingsForm() {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      status: "draft",
      visibility: "public",
    },
    validationSchema: yup.object({
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);

  //FUNCTIONS

  //RETURN
  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "max-content",
        maxWidth: "100%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "horizontal",
      }}
    >
      <Typography
        level="title-lg"
        startDecorator={<Icon icon="mdi:file-document-edit" />}
      >
        Publish Settings
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Status</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              //--component props
              endDecorator={
                formik.values.status === "published" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="published">Published</Option>
              <Option value="draft">Draft</Option>
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Visibility</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="visibility"
              value={formik.values.visibility}
              onChange={formik.handleChange}
              error={
                formik.touched.visibility && Boolean(formik.errors.visibility)
              }
              helperText={formik.touched.visibility && formik.errors.visibility}
              //--component props
              endDecorator={
                formik.values.visibility === "public" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="public">Public</Option>
              <Option value="private">Private</Option>
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
}
