import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton, Card, CardContent, CardActions, Stack  } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function BrandCarousel({ data }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={24}
        speed={600}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          575: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 6,
          },
        }}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="text-center">
              <img src={item.logoSrc} alt={item.alt} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}


// Define the editable version of your component
// Replace Carousel1 with your component's name
export const EditableCarousel1 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}


      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
// This is where you can add UI elements for editing component properties
const _ItemForm = ({ item, index, onChange, onRemove }) => {
  return (
    <Card key={index} sx={{ margin: 1 }}>
      <CardContent>
        <Stack spacing={2}>
          <Input
            label="Brand"
            value={item.brand}
            onChange={(e) => onChange('brand', e.target.value)}
          />

          <Input
            label="Title"
            value={item.title}
            onChange={(e) => onChange('title', e.target.value)}
          />

          <Input
            label="Sub Title"
            value={item.subTitle}
            onChange={(e) => onChange('subTitle', e.target.value)}
          />

          <Input
            type="date"
            label="Date"
            value={item.date}
            onChange={(e) => onChange('date', e.target.value)}
          />

          <FilePickerOpener
          onSelected={(file) => {
            onChange('awardImgUrl', file.url);
          }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <JButton size="sm" color="error" onClick={onRemove}>
          Remove
        </JButton>
      </CardActions>
    </Card>
  );
};

const _ItemRepeater = ({ items, onChange }) => {
  const addItem = () => {
    onChange([...items, { title: '', url: '' }]);
  };

  const removeItem = (index) => {
    onChange(items.filter((item, i) => i !== index));
  };

  const updateItem = (index, key, value) => {
    onChange(
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }

        return item;
      }),
    );
  };

  return (
    <Stack>
      {items.map((item, index) => (
        <_ItemForm
          key={index}
          item={item}
          index={index}
          onChange={(key, value) => updateItem(index, key, value)}
          onRemove={() => removeItem(index)}
        />
      ))}

      <JButton
        size="sm"
        color="primary"
        onClick={addItem}
      >
        Add Item
      </JButton>
    </Stack>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    data
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <_ItemRepeater
            items={data}
            onChange={(value) => setProp((props) => (props.data = value))}
          /> 
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditableCarousel1.craft = {
  displayName: 'Carousel1',
  props: {
    // Example: adding a text property to the component
    data: [
      {
        brand: 'Brand 1',
        title: 'Title 1',
        subTitle: 'Sub Title 1',
        date: '2021-01-01',
        awardImgUrl: 'https://via.placeholder.com/150',
      },
      {
        brand: 'Brand 2',
        title: 'Title 2',
        subTitle: 'Sub Title 2',
        date: '2021-01-02',
        awardImgUrl: 'https://via.placeholder.com/150',
      },
      {
        brand: 'Brand 3',
        title: 'Title 3',
        subTitle: 'Sub Title 3',
        date: '2021-01-03',
        awardImgUrl: 'https://via.placeholder.com/150',
      },
    ],
  },
  related: {
    settings: ComponentSettings,
  },
};
