import React from "react";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
//Editor
import { useNode } from "@craftjs/core";
import {
  Box,
  Divider,
  Input,
  Typography,
  Button as JButton,
  CardActions,
  Card,
  CardContent,
  Stack,
} from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useNavigate } from "react-router-dom";
import PortfolioApi from "src/dashboard/modules/content/PortfolioApi";
import { Icon } from "@iconify/react";

export default function Portfolio({ data }) {
  //CONSTANTS
  const breakpoints = {
    default: 2,
    991: 1,
  };

  //STATE
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [posts, setPosts] = React.useState([]);

  //FUNCTIONS
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await PortfolioApi.getAll();
      console.log("PORTFOLIO", JSON.stringify(response.data, null, 2));
      setPosts(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  //EFFECTS
  React.useEffect(() => {
    fetchPosts();
  }, []);

  //COMPONENTS
  const Loading = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2
        className="cs-recent_post_title "
        style={{
          margin: "0",
          padding: "0",
        }}
      >
        Loading...
      </h2>
      <Icon icon="svg-spinners:blocks-shuffle-3" width="50" height="50" />
    </div>
  );

  const Error = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <Icon icon="carbon:error" width="50" height="50" />
      <h2 className="cs-recent_post_title ">Error</h2>
      <p style={{ margin: "0", padding: "0" }}>{error.message}</p>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2 className="cs-recent_post_title" style={{}}>
        No Posts
      </h2>
      <Icon icon="bi:exclamation-triangle-fill" width="50" height="50" />
    </div>
  );

  //RENDER
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : posts?.length <= 1 ? (
        <Empty />
      ) : (
        <>
          <Masonry
            breakpointCols={{
              default: 3,
              991: 2,
              767: 1,
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {posts.map((item, index) => (
              <div key={index} className="masonry-item">
                <div className="cs_portfolio cs_style_1">
                  <Link
                    to={`/portfolio/${item.id}`}
                    className="cs_portfolio_thumb cs_radius_15"
                  >
                    <img src={item.featured_image} alt="Portfolio" />
                  </Link>
                  <div className="cs_portfolio_info">
                    <h2 className="cs_portfolio_title cs_fs_38">
                      <Link to={`/portfolio/${item.id}`}>{item.title}</Link>
                    </h2>
                    <Link to={`/portfolio/${item.id}`} className="cs_btn">
                      {item.btnText}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Masonry>
        </>
      )}
    </>
  );
}

// Define the editable version of your component
// Replace Portfolio1 with your component's name
export const EditablePortfolio1 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}

      {/* Component End */}
    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
// This is where you can add UI elements for editing component properties
const _ItemForm = ({ item, index, onChange, onRemove }) => {
  return (
    <Card key={index} sx={{ margin: 1 }}>
      <CardContent>
        <Stack spacing={2}>
          <Input
            label="Brand"
            value={item.brand}
            onChange={(e) => onChange("brand", e.target.value)}
          />

          <Input
            label="Title"
            value={item.title}
            onChange={(e) => onChange("title", e.target.value)}
          />

          <Input
            label="Sub Title"
            value={item.subTitle}
            onChange={(e) => onChange("subTitle", e.target.value)}
          />

          <Input
            type="date"
            label="Date"
            value={item.date}
            onChange={(e) => onChange("date", e.target.value)}
          />

          <FilePickerOpener
            onSelected={(file) => {
              onChange("awardImgUrl", file.url);
            }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <JButton size="sm" color="error" onClick={onRemove}>
          Remove
        </JButton>
      </CardActions>
    </Card>
  );
};

const _ItemRepeater = ({ items, onChange }) => {
  const addItem = () => {
    onChange([...items, { title: "", url: "" }]);
  };

  const removeItem = (index) => {
    onChange(items.filter((item, i) => i !== index));
  };

  const updateItem = (index, key, value) => {
    onChange(
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }

        return item;
      })
    );
  };

  return (
    <Stack>
      {items.map((item, index) => (
        <_ItemForm
          key={index}
          item={item}
          index={index}
          onChange={(key, value) => updateItem(index, key, value)}
          onRemove={() => removeItem(index)}
        />
      ))}

      <JButton size="sm" color="primary" onClick={addItem}>
        Add Item
      </JButton>
    </Stack>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    data,
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <_ItemRepeater
            items={data}
            onChange={(value) => setProp((props) => (props.data = value))}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditablePortfolio1.craft = {
  displayName: "Portfolio1",
  props: {
    data: [],
  },
  related: {
    settings: ComponentSettings,
  },
};
