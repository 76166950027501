import React from "react";
import { useState } from "react";
import PricingTable, { EditablePricingTable1 } from ".";
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

export default function PricingTableList() {
  const [tab, setTab] = useState("monthly");
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links cs_style1 cs_mp0 cs_pricing_control">
        <li
          className={tab === "monthly" ? "active" : ""}
          onClick={() => setTab("monthly")}
        >
          <span>Monthly</span>
          <span className="cs_switch"></span>
        </li>
        <li
          className={tab === "yearly" ? "active" : ""}
          onClick={() => setTab("yearly")}
        >
          <span>Yearly</span>
        </li>
      </ul>
      <div className="row cs_gap_y_30">
        <div className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Silver"
              subTitle="Control your choices for effective business growth and more customers."
              price="399"
              currency="$"
              timeline="monthly"
              features={[
                "Limited content",
                "Ad campaign",
                "Branding consultancy",
                "Video ad banner",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Silver"
              subTitle="Control your choices for effective business growth and more customers."
              price="499"
              currency="$"
              timeline="yearly"
              features={[
                "Limited content",
                "Ad campaign",
                "Branding consultancy",
                "Video ad banner",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Gold"
              subTitle="Control your choices for effective business growth and more customers."
              price="449"
              currency="$"
              timeline="monthly"
              features={[
                "Limited content",
                "Social ad campaign",
                "Premium consultancy",
                "Secret strategy",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
              popular
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Gold"
              subTitle="Control your choices for effective business growth and more customers."
              price="649"
              currency="$"
              timeline="yearly"
              features={[
                "Limited content",
                "Social ad campaign",
                "Premium consultancy",
                "Secret strategy",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
              popular
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Platinum"
              subTitle="Control your choices for effective business growth and more customers."
              price="599"
              currency="$"
              timeline="monthly"
              features={[
                "Unlimited content",
                "Ad campaign",
                "Branding consultancy",
                "Video ad banner",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Platinum"
              subTitle="Control your choices for effective business growth and more customers."
              price="899"
              currency="$"
              timeline="yearly"
              features={[
                "Unlimited content",
                "Ad campaign",
                "Branding consultancy",
                "Video ad banner",
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
        </div>
      </div>
    </div>
  );
}

// Define the editable version of your component
// Replace PricingTableList with your component's name
export const EditablePricingTableList = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  //COMPONENT STATES
  const [tab, setTab] = useState("monthly");

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}

      <div className="cs_tabs cs_style1">
        <ul className="cs_tab_links cs_style1 cs_mp0 cs_pricing_control">
          <li
            className={tab === "monthly" ? "active" : ""}
            onClick={() => setTab("monthly")}
          >
            <span>Monthly</span>
            <span className="cs_switch"></span>
          </li>
          <li
            className={tab === "yearly" ? "active" : ""}
            onClick={() => setTab("yearly")}
          >
            <span>Yearly</span>
          </li>
        </ul>
        <div className="row cs_gap_y_30">
          <div className="col-lg-4">
            {tab === "monthly" && (
              <EditablePricingTable1
                title="Silver"
                subTitle="Control your choices for effective business growth and more customers."
                price="399"
                currency="$"
                timeline="monthly"
                features={[
                  "Limited content",
                  "Ad campaign",
                  "Branding consultancy",
                  "Video ad banner",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
              />
            )}
            {tab === "yearly" && (
              <EditablePricingTable1
                title="Silver"
                subTitle="Control your choices for effective business growth and more customers."
                price="499"
                currency="$"
                timeline="yearly"
                features={[
                  "Limited content",
                  "Ad campaign",
                  "Branding consultancy",
                  "Video ad banner",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
              />
            )}
          </div>
          <div className="col-lg-4">
            {tab === "monthly" && (
              <EditablePricingTable1
                title="Gold"
                subTitle="Control your choices for effective business growth and more customers."
                price="449"
                currency="$"
                timeline="monthly"
                features={[
                  "Limited content",
                  "Social ad campaign",
                  "Premium consultancy",
                  "Secret strategy",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
                popular
              />
            )}
            {tab === "yearly" && (
              <EditablePricingTable1
                title="Gold"
                subTitle="Control your choices for effective business growth and more customers."
                price="649"
                currency="$"
                timeline="yearly"
                features={[
                  "Limited content",
                  "Social ad campaign",
                  "Premium consultancy",
                  "Secret strategy",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
                popular
              />
            )}
          </div>
          <div className="col-lg-4">
            {tab === "monthly" && (
              <EditablePricingTable1
                title="Platinum"
                subTitle="Control your choices for effective business growth and more customers."
                price="599"
                currency="$"
                timeline="monthly"
                features={[
                  "Unlimited content",
                  "Ad campaign",
                  "Branding consultancy",
                  "Video ad banner",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
              />
            )}
            {tab === "yearly" && (
              <EditablePricingTable1
                title="Platinum"
                subTitle="Control your choices for effective business growth and more customers."
                price="899"
                currency="$"
                timeline="yearly"
                features={[
                  "Unlimited content",
                  "Ad campaign",
                  "Branding consultancy",
                  "Video ad banner",
                ]}
                btnText="Choose Packedge"
                btnLink="/"
              />
            )}
          </div>
        </div>
      </div>
      {/* Component End */}
    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
  } = useNode((node) => ({}));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditablePricingTableList.craft = {
  displayName: "PricingTableList",
  props: {
    // Example setting: text property
    text: "Pricing Table List",
  },
  related: {
    settings: ComponentSettings,
  },
};
