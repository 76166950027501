import { useEffect, useState } from "react";
import { Route, Routes, useLocation, outlet, Outlet } from "react-router-dom";

import AppGlobalsProvider from "./contexts/AppGlobalsProvider";
import WebsiteRoutes from "./routes/WebsiteRoutes";
//MUI & Joy UI
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Sheet } from "@mui/joy";
import soimagineTheme from "./theme/theme";
import { DndContext } from "@dnd-kit/core";
import { SnackbarProvider } from "notistack";

//GLOBAL CONSTANTS
//--mui
const materialTheme = materialExtendTheme();

function App() {
  //CONSTANTS
  const { pathname } = useLocation();

  //CONTEXT

  //STATES

  //FUNCTIONS
  //save selected theme scheme
  const saveSelectedThemeScheme = (scheme) => {
    localStorage.setItem("darkMode", scheme);
  };
  //get selected theme scheme
  const getSelectedThemeScheme = () => {
    return localStorage.getItem("darkMode");
  };

  //USE EFFECTS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //RENDER
  return (
    <MaterialCssVarsProvider
      theme={{ [MATERIAL_THEME_ID]: materialTheme }}
      defaultMode="dark"
      defaultColorScheme="dark"
    >
      <JoyCssVarsProvider
        defaultMode="dark"
        defaultColorScheme="dark"
        theme={soimagineTheme}
      >
        <CssBaseline enableColorScheme />
        <SnackbarProvider
          dense
          autoHideDuration={3000}
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <DndContext>
            <AppGlobalsProvider>
              <WebsiteRoutes />
            </AppGlobalsProvider>
          </DndContext>
        </SnackbarProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
