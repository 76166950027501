/*This File Defines All the API Requests for the Articles Endpoint. This Includes GET, POST, PUT, DELETE Requests.
The Endpoints have Been Defined As Wordpress custom REST API Endpoints.
the rest routes are defined in the wordpress plugin soimagine-rest-api
*/

/*
namespace: soimagine/articles
- get-all : get all articles
- get-single : get single article
- create : create new article
- update : update existing article
- delete : delete existing article
*/

import api from './index';

/**
* @typedef {Object} Article
* @property {number} id - The Article ID
* @property {string} title - The Article Title
* @property {string} content - The Article Content
* @property {string} excerpt - The Article Excerpt
* @property {string} date - The Article Date
* @property {string} author - The Article Author
* @property {string} featured_image - The Article Featured Image
* @property {Array} categories - The Article Categories
* @property {Array} tags - The Article Tags
*/

/*GET - retrieves all articles
* @param {number} page - The Page Number
* @typedef {Object} Article || id title content excerpt date author featured_image categories tags
* @returns {Promise<Article[]>} - A Promise that Resolves to an Array of Articles
*/
export const getArticles = async (params) => {
  api.post('soimagine/articles/get-all', {
    page: params ? params.page : 1,
    category: params ? params.category : "",
    tag: params ? params.tag : ""
  })
  .then((response) => {
    console.log(response);
    return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
};

/*GET - retrieves a single article
* @param {number} id - The Article ID
* @typedef {Object} Article || id title content excerpt date author featured_image categories tags
* @returns {Promise<Article>} - A Promise that Resolves to an Article
*/
export const getSingleArticle = async (params) =>{
  api.post('soimagine/article/get-single', {
    id: params ? params.id : "",
    slug: params ? params.slug : "",
    title: params ? params.title : ""
  })
  .then((response) => {
    console.log(response);
    return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
};

/*POST - creates a new article
* @param {Article} article - The Article Object
* @typedef {Object} Article || id title content excerpt date author featured_image categories tags
* @returns {Promise<Article>} - A Promise that Resolves to the New Article
*/
export const createArticle = (article) => {
  api.post('soimagine/articles/create', article)
  .then((response) => {
    console.log(response);
    return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
};

/*POST - deletes an article
* @param {number} id - The Article ID
* @param {Article} article - The Article Object
* @typedef {Object} Article || id title content excerpt date author featured_image categories tags
* @returns {Promise<Article>} - A Promise that Resolves to the Updated Article
*/
export const updateArticle = (article) => {
  api.post(`soimagine/articles/update`, article)
  .then((response) => {
    console.log(response);
    return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
};

/*POST - deletes an article
* @param {number} id - The Article ID
* @typedef {Object} Article || id title content excerpt date author featured_image categories tags
* @returns {Promise<Article>} - A Promise that Resolves to the Deleted Article
*/
export const deleteArticle = (article) => {
  api.post(`soimagine/articles/delete`, article)
  .then((response) => {
    console.log(response);
    return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
};