import React from "react";
import parse from "html-react-parser";
import Spacing from "../Spacing";
import { Link } from "react-router-dom";
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { TypeAnimation } from "react-type-animation";

export default function SectionHeadingStyle3({
  title,
  subTitle,
  variant,
  date,
  avatar,
  avatarLink,
  shape,
  category,
  href,
}) {
  return (
    <div
      className={`cs_section_heading cs_style_1 cs_type_3 ${
        variant ? variant : ""
      }`}
    >
      <div className="container">
        {subTitle && (
          <p className="cs_section_subtitle cs_accent_color cs_fs_21 mb-0">
            {href ? <Link to={href}>{subTitle}</Link> : subTitle}
          </p>
        )}
        {category && <span className="cs_category">{category}</span>}
        <Spacing lg="20" md="10" />
        <h2 className="cs_section_title cs_fs_68 mb-0">{parse(title)}</h2>
        {date && (
          <div className="cs-post_meta cs-style1 cs-ternary_color">
            <span className="cs-posted_by">{date}</span>
            <Link to={avatarLink} className="cs-post_avatar">
              {avatar}
            </Link>
          </div>
        )}
      </div>
      {shape === "shape_1" && <div className="cs_shape_1" />}
      {shape === "shape_2" && <div className="cs_shape_2" />}
      {shape === "shape_3" && <div className="cs_shape_3" />}
      {shape === "shape_4" && (
        <div className="cs_shape_4">
          <img src="/images/icons/team_shape.svg" alt="Shape" />
        </div>
      )}
      {shape === "shape_5" && (
        <div className="cs_shape_5">
          <img src="/images/icons/blog.svg" alt="Icon" />
        </div>
      )}
      {shape === "shape_6" && (
        <div className="cs_shape_4">
          <img src="/images/icons/mail.svg" alt="Mail" />
        </div>
      )}
    </div>
  );
}

export function SectionHeadingStyle3_Typed({
  title,
  subTitle,
  variant,
  date,
  avatar,
  avatarLink,
  shape,
  category,
  href,
}) {
  return (
    <div
      className={`cs_section_heading cs_style_1 cs_type_3 ${
        variant ? variant : ""
      }`}
    >
      <div className="container">
        {subTitle && (
          <p className="cs_section_subtitle cs_accent_color cs_fs_21 mb-0">
            {href ? <Link to={href}>{subTitle}</Link> : subTitle}
          </p>
        )}
        {category && <span className="cs_category">{category}</span>}
        <Spacing lg="20" md="10" />

        <TypedPageTitle
          title={title}
          speed={50}
          repeat={true}
          titleClass="cs_section_title cs_fs_68 mb-0"
        />

        {date && (
          <div className="cs-post_meta cs-style1 cs-ternary_color">
            <span className="cs-posted_by">{date}</span>
            <Link to={avatarLink} className="cs-post_avatar">
              {avatar}
            </Link>
          </div>
        )}
      </div>
      {shape === "shape_1" && <div className="cs_shape_1" />}
      {shape === "shape_2" && <div className="cs_shape_2" />}
      {shape === "shape_3" && <div className="cs_shape_3" />}
      {shape === "shape_4" && (
        <div className="cs_shape_4">
          <img src="/images/icons/team_shape.svg" alt="Shape" />
        </div>
      )}
      {shape === "shape_5" && (
        <div className="cs_shape_5">
          <img src="/images/icons/blog.svg" alt="Icon" />
        </div>
      )}
      {shape === "shape_6" && (
        <div className="cs_shape_4">
          <img src="/images/icons/mail.svg" alt="Mail" />
        </div>
      )}
    </div>
  );
}

const TypedPageTitle = ({ title, titleClass, speed, repeat }) => {
  //CONSTANTS

  //STATE
  const [titleParts, setTitleParts] = React.useState(titleSplitter(title));

  //FUNCTIONS
  //--Split the title into a 3 word array for animation
  function titleSplitter(title) {
    //Split the title into words
    let words = title.split(" ");

    /*To create a delay after 3 Words, We 
    will split the title into parts of 3 words each
    But we need to write the full title in the last part 
    to avoid cutting off the title - so the first part will have 3 words
    the second part will have 6 words and the last part will have all the remaining words
    */
    let parts = [];
    //Create the first part
    parts.push(words.slice(0, 3).join(" "));
    //Create the second part
    parts.push(words.slice(3, 6).join(" "));
    //Create the last part
    parts.push(words.slice(6).join(" "));
    return parts;
  }

  //--construct the title animation sequence
  //With a delay of 750ms between each part
  //And a delay of 3000ms before repeating
  function constructSequence() {
    let sequence = [];

  }

  return (
    <TypeAnimation
      cursor={true}
      sequence={[
        title,
        3000
      ]}
      wrapper="h1"
      speed={speed}
      repeat={repeat}
      className={titleClass}
    />
  );
};

// Define the editable version of your component
// Replace Heading3 with your component's name
export const EditableHeading3 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}
      <SectionHeadingStyle3 {...props} />
      {/* Component End */}
    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    variant: node.data.props.variant,
    date: node.data.props.date,
    avatar: node.data.props.avatar,
    avatarLink: node.data.props.avatarLink,
    shape: node.data.props.shape,
    category: node.data.props.category,
    href: node.data.props.href,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        ></AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditableHeading3.craft = {
  displayName: "Heading3",
  props: {
    title: "Title",
    subTitle: "Subtitle",
    variant: "Variant",
    date: "Date",
    avatar: "Avatar",
    avatarLink: "Avatar Link",
    shape: "Shape",
    category: "Category",
    href: "Link",
  },
  related: {
    settings: ComponentSettings,
  },
};
