import { useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { WP_API_TOKEN } from "src/api/config";
import { InputLabel, ListItemIcon, MenuItem } from "@mui/material";
import { Card, CardActions, CardContent, Divider, FormControl, IconButton, Input, Option, Select, Stack, Switch } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import PagesApi, { DemoPagesList } from "./PagesApi";

/*CONSTANTS*/
const columns = [
  {
    accessorKey: 'page_title',
    header: 'Title',
  },
  {
    accessorKey: 'page_author  ',
    header: 'Author',
  },
  {
    accessorKey: 'page_status',
    header: 'Status',
  },
  {
    accessorKey: 'page_last_modified',
    header: 'Last Modified',
  },
];
const fetchSize = 25;

/*COMPONENT*/
//--pagesList | default
export default function PagesList() {

  //CONSTANTS
  const navigate = useNavigate()

  //STATES
  //--app states
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [demoMode, setDemoMode] = useState(false);

  //FUNCTIONS
  //--navigation functions
  function handlePageClick(pageId) {
    navigate(`/dashboard/pages/edit/${pageId}`);
  }
  //__go to add new page
  function handleAddNewPageClick() {
    setIsAddingNew(true);
  }

  //--After adding new page
  function handleNewPageAdded() {
    setIsAddingNew(false);
  }

  //--Cancel adding new page
  function handleNewPageAddCancel() {
    setIsAddingNew(false);
  }


  //RENDER
  return (
    <Box sx={{ minHeight: "100vh" }}>

      {/* Breadcrumbs / Actions */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Pages
          </Typography>
        </Breadcrumbs>

        <Stack direction="row" gap={1}>
          <Button
            color="primary"
            onClick={handleAddNewPageClick}
            startDecorator={
              <Icon
                icon="mdi:download"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Add New Page
          </Button>

          <Switch
            color="primary"
            checked={demoMode}
            onChange={(e) => setDemoMode(e.target.checked)}
            inputProps={{ "aria-label": "Demo Mode" }}
          />

        </Stack>
      </Box>

      {/* Page Title */}
      {/* <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      > 
      </Box> */}

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
      {
        isAddingNew
        ?
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <AddPageForm
            onPageAdded={handleNewPageAdded}
            onPageAddCancel={handleNewPageAddCancel}
          />
        </motion.div>
        :
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
        {
          demoMode
          ?
          <PagesGrid pages={DemoPagesList} OnSelect={handlePageClick} />
          :
          <PagesTable />
        }
        </motion.div>
      }
      </Box>
      
    </Box>
  );
}

//--pages Table | PagesTable

const PagesTable = () => {

  //CONSTANTS
  const navigate = useNavigate();

  //STATES
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //FUNCTIONS
  //--navigation functions
  //--go to edit page with pageId
  function handlePageClick(pageId) {
    navigate(`/dashboard/pages/edit/${pageId}`);
  }

  //EFFECTS
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        
        PagesApi.getAll()
        .then((response) => {
          setData(response.data);
          setRowCount(response.data.length);
        })
        .catch((error) => {
          setIsError(true);
          console.error(error);
        });

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'page_title',
        header: 'Title',
      },
      //column definitions...
      {
        accessorKey: 'page_author',
        header: 'Author',
      },
      {
        accessorKey: 'page_status',
        header: 'Status',
      },
      {
        accessorKey: 'page_last_modified',
        header: 'Last Modified',
      },
      //end
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: false},
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        onClick={() => {
          closeMenu();
          handlePageClick(row.id);
        }}
      >
        <ListItemIcon>
          <Icon icon="carbon:edit" />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem>
        <ListItemIcon>
          <Icon icon="carbon:delete" />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    //--Apply Styling
    muiTablePaperProps:{
      variant: 'outlined',
      elevation: 0,
      sx:{
        border: '1px solid #3c424d',
        borderRadius: '4px',
        boxShadow: 'none',
      }
    }
  });

  return <MaterialReactTable table={table} />;
};

const PagesGrid = ({pages, OnSelect}) => {

  //CONSTANTS
  const navigate = useNavigate();

  //FUNCTIONS
  //--navigation functions
  //--go to edit content with contentId
  function handleContentClick(contentId) {
    navigate(`/dashboard/pages/edit/${contentId}`);
  }

  //RENDER
  /*--render grid of cards for contents. 3 Items in a row, each card is clickable to go to contents list page
    --each card has a name, desscription, icon, id. Also has a button to go to edit content page
  */
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: 2,
      }}
    >
      {pages.map((content) => (
        <Card
          key={content.id}
          variant="outlined"
          sx={{ 
            p: 2, 
            borderRadius: "4px", 
            boxShadow: "none",
            cursor: "pointer",
            transition: "all 0.3s",
            '&:hover': {
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
              backgroundColor: "var(--joy-palette-neutral-700)"
            }
          }}
          onClick={() => OnSelect(content)}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level={"title-md"}>{content.name}</Typography>
            <IconButton
              size="sm"
              onClick={() => handleContentClick(content.id)}
            >
              <Icon icon="carbon:edit" />
            </IconButton>
          </Box>
          <Typography level={"body"} sx={{ color: "text.secondary" }}>
            {content.page_title}
          </Typography>
        </Card>
      ))}
    </Box>
  );
};

//--add page Form | AddPageForm
const AddPageForm = ({ onPageAdded, onPageAddCancel }) => {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      page_title: "",
      page_status: "",
    },
    validationSchema: Yup.object({
      page_title: Yup.string(),
      page_status: Yup.string(),
    }),
    onSubmit: async (values) => {
      await AddNewPage(values);
    },
  });

  //STATES
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPageItem, setNewPageItem] = useState({});

  //FUNCTIONS
  async function AddNewPage(values) {
    setIsSubmitting(true);

    PagesApi.create(values)
      .then((response) => {

        setNewPageItem(response.data);
        console.log("CREATE PAGE", response.data);
        
        setIsError(false);
        setIsSubmitting(false);

        setTimeout(()=>{
          onPageAdded();
        }, 2000);
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        console.error(error);
      });
  }

  function CancelAddNewPage() {
    onPageAddCancel();
  }


  //RENDER
  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        borderRadius: "4px",
        boxShadow: "none",
        border: "1px solid #3c424d",
      }}
    >
      {/*header*/}
      <Typography level={"title-md"} sx={{ mb: 2 }}>
        Add New Page
      </Typography>
      <Divider />

      {/*form*/}
      <CardContent>
        <FormControl>
          <InputLabel htmlFor="page_title">Title</InputLabel>
          <Input
            id="page_title"
            name="page_title"
            type="text"
            value={formik.values.page_title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="page_status">Status</InputLabel>
          <Select
            variant="outlined"
            id="page_status"
            name="page_status"
            defaultValue="draft"
            value={formik.values.page_status}
            onChange={(e, val)=>formik.setFieldValue("page_status", val)}
            onBlur={formik.handleBlur}
          >
            <Option value="draft">Draft</Option>
            <Option value="published">Published</Option>
            <Option value="trash">Trash</Option>
          </Select>
        </FormControl>

      </CardContent>

      {/*actions*/}
      <CardActions>
        <Button color="danger" onClick={CancelAddNewPage} size="sm">
          Cancel
        </Button>

        <Button
          color={isError ? "danger" : newPageItem?.id ? "success" : "primary"}
          disabled={isSubmitting}
          onClick={formik.handleSubmit}
          size="sm"
          startDecorator={
            isSubmitting && !isError 
            ?
            <Icon icon="svg-spinners:eclipse-half" style={{ fontSize: "16px", color: "#fff" }} />
            :
            <Icon icon="mdi:download" style={{ fontSize: "16px", color: "#fff" }} />
          }
        >
          {
            isSubmitting && !isError
            ? 
            "Adding..." 
            : 
            !isSubmitting && isError
            ?
            "Retry"
            :
            !isSubmitting && !isError && newPageItem?.id
            ?
            "Added"
            :
            "Add"
          }
        </Button>
      </CardActions>
    </Card>
  );
};
