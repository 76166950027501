import React from "react";
import PageTransition from "src/components/Framer/PageTransitions";
import SectionHeadingStyle3 from "src/components/SectionHeading/SectionHeadingStyle3";
import Spacing from "src/components/Spacing";
import { pageTitle } from "src/helpers/PageTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Box, Stack, Typography } from "@mui/joy";
import AboutStyle7 from "src/components/About/AboutStyle7";
import AboutStyle8 from "src/components/About/AboutStyle8";
import CtaStyle2 from "src/components/Cta/CtaStyle2";

export default function ProjectDetailsPage() {
  pageTitle("Project Details");

  //CONSTANTS
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  //STATES
  const [loading, setLoading] = React.useState(true);
  const [productData, setProjectData] = React.useState({});
  const [error, setError] = React.useState(null);

  //FUNCTIONS
  //Get the data from the API
  const getData = async () => {
    try {
      //Simulate a delay
      setTimeout(() => {
        setProjectData({});
        setLoading(false);
      }, 1000);
    } catch (error) {
      setError(error);
    }
  };

  //EFFECTS
  React.useEffect(() => {
    getData();
  }, []);

  //RENDER
  return (
    <PageTransition>
      <Spacing lg="70" md="70" />

      {loading ? (
        <Box>
          <Icon icon="svg-spinners:blocks-shuffle-3" width="50" height="50" />
          <Typography variant="h6">Loading...</Typography>
        </Box>
      ) : (
        <>
          <SectionHeadingStyle3
            title={productData.heading.title}
            subTitle={productData.heading.subTitle}
            variant={productData.heading.variant}
          />
          <Spacing lg="75" md="60" />

          <div className="container">
            <img
              className="cs_casestudy_details_thumb cs_radius_15"
              src={productData.thumbnailSrc}
              alt="Thumb"
            />
            <div className="cs_height_100 cs_height_lg_60" />
            <ul className="cs_case_study_info cs_mp0">
              <li>
                <p className="mb-0">Product</p>
                <h3 className="cs_fs_21 mb-0 cs_semibold">{productData.details.product}</h3>
              </li>
              <li>
                <p className="mb-0">Industry</p>
                <h3 className="cs_fs_21 mb-0 cs_semibold">{productData.details.industry}</h3>
              </li>
              <li>
                <p className="mb-0">Tech Stack</p>
                <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"space-evenly"}>
                  {productData.details.techStack.map((tech) => (
                    <Icon icon={tech.icon} width="30" height="30" />
                  ))}
                </Stack>
              </li>
              <li>
                <p className="mb-0">Launched</p>
                <h3 className="cs_fs_21 mb-0 cs_semibold">{productData.details.launched}</h3>
              </li>
            </ul>
            <div className="cs_height_100 cs_height_lg_60" />
            <div className="row align-items-center cs_gap_y_35">
              <div className="col-lg-5">
                <img
                  src={productData.media.logo}
                  alt="Thumb"
                  className="cs_radius_15 w-100"
                />
              </div>
              <div className="col-lg-6 offset-lg-1">
                <h2 className="cs_fs_50">Project Brief :</h2>
                <div className="cs_height_15 cs_height_lg_15" />

                <p>
                    {productData.content.brief}
                </p>

                <div className="cs_height_20 cs_height_lg_10" />
                <p className="mb-0">
                  Website:{" "}
                  <a href={productData.links.website} target="_blank" rel="noreferrer">
                    {productData.links.website}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <Spacing lg="150" md="80" />
          <AboutStyle7
            title={productData.content.featureTitle}
            subTitle={productData.content.featureContent}
            featureList={productData.content.featureList}
            thumbnailSrc={productData.media.feature}
          />
          <Spacing lg="150" md="80" />
          <AboutStyle8
            thumbnailSrc={productData.media.advert}
            title={productData.content.advertTitle}
            subTitle={productData.content.advertContent}
            grothData={productData.content.advertData}
          />
          <Spacing lg="140" md="70" />
          <CtaStyle2
            title="Schedule a demo with us"
            btnText="Send Message"
            btnUrl="/contact-us"
          />
          <div className="cs_height_150 cs_height_lg_80" />
        </>
      )}
    </PageTransition>
  );
}
