import axios from "axios";

const FileApi = {
  //--url
  baseUrl: "https://api.soimagine.co.za/wp-json",
  namespace: "/sa9ecms/v1/",
  endpoint: "file",

  //--create
  create: (data) => {
    let bodyParams = {
      file_title: data?.title,
      file_content: data?.content,
      file_status: data?.status,
      file_author: data?.author,
      featured_image: data?.featured_image,
    };

    try {
      let req = axios.post(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--get all
  getAll: () => {
    try {
      let req = axios.get(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--get one
  getOne: (id) => {
    try {
      let req = axios.get(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--update
  update: (data) => {
    let bodyParams = {
      file_title: data?.title,
      file_content: data?.content,
      file_status: data?.status,
      file_author: data?.author,
      featured_image: data?.featured_image,
    };

    try {
      let req = axios.put(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}/${data?.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--delete
  delete: (id) => {
    try {
      let req = axios.delete(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //CUSTOM
  //--Get Media
  queryMedia: ({ page, per_page, mime_type }) => {
    try {
      let req = axios.get(
        `${FileApi.baseUrl}${FileApi.namespace}${FileApi.endpoint}/media?page=${page}&per_page=${per_page}&mime_type=${mime_type}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      /*response example
      {
        "data": [
          {
            id
            title
            caption
            alt
            description
            url
            mime_type
            date
          }
        ]
      }
      */

      return req;
    } catch (error) {
      console.log(error);
    }
  },
};

export default FileApi;
