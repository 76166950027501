import React from 'react';
import { Parallax } from 'react-parallax';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function HeroStyle2({
  miniTitle,
  title,
  subTitle,
  thumbnailSrc,
  mikeIcon,
}) {
  return (
    <section className="cs_hero cs_style_2 cs_gray_bg position-relative">
      <div className="container">
        <div className="cs_hero_text position-relative">
          <p className="cs_hero_top_title cs_medium cs_fs_18 cs_accent_color">
            <span className="cs_hero_mike">
              <img src={mikeIcon} alt="Mike" />
            </span>
            <span className="cs_hero_arrow cs_accent_color">
              <i className="fa-solid fa-chevron-right" />
            </span>
            <span className>{miniTitle}</span>
          </p>
          <h1 className="cs_hero_title cs_fs_68">{title}</h1>
        </div>
        <div className="row cs_gap_y_40 align-items-center">
          <div className="col-lg-7">
            <div className="cs_hero_img cs_parallax">
              <Parallax
                bgImage={thumbnailSrc}
                bgImageAlt="Thumb"
                strength={-100}
                className="h-100"
              ></Parallax>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cs_hero_subtitle">
              <p className="mb-0">{subTitle}</p>
              <a
                href="#service"
                className="cs_down_btn cs_center cs_primary_color"
              >
                <svg
                  width={8}
                  height={33}
                  viewBox="0 0 8 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.64645 32.3536C3.84171 32.5488 4.15829 32.5488 4.35355 32.3536L7.53554 29.1716C7.7308 28.9763 7.7308 28.6597 7.53554 28.4645C7.34027 28.2692 7.02369 28.2692 6.82843 28.4645L4 31.2929L1.17157 28.4645C0.976312 28.2692 0.65973 28.2692 0.464467 28.4645C0.269205 28.6597 0.269205 28.9763 0.464467 29.1716L3.64645 32.3536ZM3.5 2.18557e-08L3.5 32L4.5 32L4.5 -2.18557e-08L3.5 2.18557e-08Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const EditableHero2 = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        //padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <HeroStyle2
        miniTitle={props.miniTitle}
        title={props.title}
        subTitle={props.subTitle}
        thumbnailSrc={props.thumbnailSrc}
        mikeIcon={props.mikeIcon}
      />

      {/* Component End */}

    </Box>
  );
    
};

// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    miniTitle,
    title,
    subTitle,
    thumbnailSrc,
    mikeIcon,
  } = useNode((node) => ({
    miniTitle: node.data.props.miniTitle,
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    thumbnailSrc: node.data.props.thumbnailSrc,
    mikeIcon: node.data.props.mikeIcon,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >

        <Input
            label="Mini Title"
            value={miniTitle}
            onChange={(e) => {
              setProp((props) => (props.miniTitle = e.target.value));
            }}
          />
          <Input
            label="Title"
            value={title}
            onChange={(e) => {
              setProp((props) => (props.title = e.target.value));
            }}
          />
          <Input
            label="Subtitle"
            value={subTitle}
            onChange={(e) => {
              setProp((props) => (props.subTitle = e.target.value));
            }}
          />
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <FilePickerOpener
            label="Thumbnail"
            onSelected={(file) => {
              setProp((props) => (props.thumbnailSrc = file.url));
            }}
          />
          <FilePickerOpener
            label="Mike Icon"
            onSelected={(file) => {
              setProp((props) => (props.mikeIcon = file.url));
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableHero2.craft = {
  displayName: "Hero Style 2",
  props: {
    miniTitle: "Mini Title",
    title: "Title",
    subTitle: "Subtitle",
    thumbnailSrc: "https://via.placeholder.com/600",
    mikeIcon: "https://via.placeholder.com/100",
  },
  related: {
    settings: ComponentSettings,
  },
  rules: {
    canDrag: () => true,
  },
};
