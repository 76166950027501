import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import { useFormik } from "formik";
import * as yup from "yup";

/*COMPONENT*/
//--pagesList | default
export default function ContentAdd() {
  //FORMIK: Wordpress - Register New Custom Post Type
  const formik = useFormik({
    labels: {
      name: "",
      singular_name: "",
    },
    description: "",
    public: true,
    hierarchical: false,
    show_in_rest: true,
    supports: {
      title: true,
      author: true,
      comments: false,
      editor: false,
      revisions: false,
      thumbnail: false,
      "custom-fields": true,
    },

    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      singular_name: yup.string().required("Singular Name is required"),
      description: yup.string().required("Description is required"),
    }),

    onSubmit: (values) => {
      console.log("onSubmit", values);
    },
  });

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* Breadcrumbs / Actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            Pages
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create
          </Typography>
        </Breadcrumbs>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            color="primary"
            startDecorator={
              <Icon
                icon="carbon:document-add"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
            onClick={formik.handleSubmit}
            disabled={isBusy || !formik.isValid}
          >
            Create Page
          </Button>

          <Button
            color="info"
            startDecorator={
              <Icon
                icon="mdi:cancel"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Cancel
          </Button>
        </Box>
      </Box>

      {/* Page Title */}
      {/* <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      > 
      </Box> */}

      {/* Page Content */}
      <Grid container spacing={2}>
        {/* Column 1 */}
        <Grid item xs={12} md={8}>
          
        </Grid>

        {/* Column 2 */}
        <Grid item xs={12} md={4}>
          
        </Grid>
      </Grid>
    </Box>
  );
}

//COMPONENTS
//--Content: Custom Post Type Settings 
//--Show_in_rest, Public, Hierarchical


//--Content: Support And Capabilities Form
function SupportAndCapabilities({ supports, onUpdated }) {

  //CONSTANTS
  const allSupports = [
    //Wordpress Default Supports
    //title, editor, thumbnail, comments, revisions
    {
      id: "title",
      label: "Title",
      description: "Allows each post to have a title.",
      status: supports.title,
    },
    {
      id: "editor",
      label: "Editor",
      description: "Enables the post content editor.",
      status: supports.editor,
    },
    {
      id: "thumbnail",
      label: "Thumbnail",
      description: "Allows each post to have a representative image.",
      status: supports.thumbnail,
    },
    {
      id: "comments",
      label: "Comments",
      description: "Allows users to comment on a post.",
      status: supports.comments,
    },
    {
      id: "revisions",
      label: "Revisions",
      description: "Allows each post to be saved and edited.",
      status: supports.revisions,
    },
    {
      id: "custom-fields",
      label: "Custom Fields",
      description: "Allows custom fields to be added to a post.",
      status: supports["custom-fields"],
    },
  ]

  //FUNCTIONS
  const handleSupportChange = (id, status) => {
    const newSupports = supports.map((support) =>
      support.id === id ? { ...support, status } : support
    );
    onUpdated(newSupports);
  };

  //COMPONENTS
  //--Support Item : Outlined Card With Checkbox, Label, and Description
  function SupportItem({ support, onSupportChange }) {
    const { id, label, description, status } = support;

    return (
      <Card variant="outlined">
        <CardContent>
          <FormControl>
            <Checkbox
              checked={status}
              onChange={(e) => onSupportChange(id, e.target.checked)}
            />
            <FormLabel>{label}</FormLabel>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </FormControl>
        </CardContent>
      </Card>
    );
  }

  //RETURN
  return (
    //Outlined Card With Title, Divider, and Grid Layout For Supports
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Supports
        </Typography>
        <Divider />
        <Grid container spacing={2}>
          {allSupports.map((support) => (
            <Grid item xs={12} key={support.id}>
              <SupportItem
                support={support}
                onSupportChange={handleSupportChange}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}


