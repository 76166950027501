/* This Defines the Authentification service that will be used to authenticate the user.*/
import axios from "axios";


class MenusService {
  
  baseUrl = "https://api.soimagine.co.za/wp-json";
  namespace = "/sa9ecms/v1";
  endpoint = "/menu";

  //--current user
  currentUser = JSON.parse(localStorage.getItem("currentUser"));

  //--create
  create = (data) => {
    let bodyParams = {
      menu_title: data?.menu_title,
      menu_content: data?.menu_content,
    };

    try {
      let req = axios.post(
        `${this.baseUrl}${this.namespace}${this.endpoint}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--get all
  getAll = () => {
    try {
      let req = axios.get(
        `${this.baseUrl}${this.namespace}${this.endpoint}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--get single
  getSingle = (id) => {
    try {
      let req = axios.get(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--update
  update = (data) => {
    let bodyParams = {
      menu_title: data?.title,
      menu_content: data?.content,
    };

    try {
      let req = axios.put(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${data?.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--delete
  delete = (id) => {
    try {
      let req = axios.delete(
        `${this.baseUrl}${this.namespace}${this.endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.currentUser?.token}`,
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };

  //--Get the Primary Menu
  getPrimaryMenu = () => {
    try {
      let req = axios.get(
        `${this.baseUrl}${this.namespace}${this.endpoint}/primary`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  };
}


const MenusApi = new MenusService();

export default MenusApi;
