import { useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";
import { WP_API_TOKEN } from "src/api/config";
import { InputLabel, ListItemIcon, MenuItem } from "@mui/material";
import { Card, CardActions, CardContent, Divider, FormControl, Input, Option, Select } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { ContentTypes } from "./ContentTypes";
import ContentApi from "./ContentAPI";


/*COMPONENT*/
//--contentsList | default
export default function ContentItems() {

  //CONSTANTS
  const navigate = useNavigate()

  //STATES
  //--app states
  const [isAddingNew, setIsAddingNew] = useState(false);

  //FUNCTIONS
  //--navigation functions
  function handleContentClick(content) {
    console.log("CONTENT TYPE", content);
    
    navigate(`/dashboard/content/list/${content.type}`,
    {
      state: { content: content }
    });
  }
  //__go to add new content
  function handleAddNewContentClick() {
    setIsAddingNew(true);
  }

  //--After adding new content
  function handleNewContentAdded() {
    setIsAddingNew(false);
  }

  //--Cancel adding new content
  function handleNewContentAddCancel() {
    setIsAddingNew(false);
  }

  //RENDER
  return (
    <Box sx={{ minHeight: "100vh" }}>

      {/* Breadcrumbs / Actions */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:content-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Contents
          </Typography>
        </Breadcrumbs>

        <Button
          color="primary"
          onClick={handleAddNewContentClick}
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Add New Content
        </Button>
      </Box>

      {/* Content Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
      {
        isAddingNew
        ?
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <AddContentForm
            onContentAdded={handleNewContentAdded}
            onContentAddCancel={handleNewContentAddCancel}
          />
        </motion.div>
        :
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ContentsGrid contents={ContentTypes} OnSelect={handleContentClick}/>
        </motion.div>
      }
      </Box>
      
    </Box>
  );
}

//--contents Grid | ContentsGrid
const ContentsGrid = ({contents, OnSelect}) => {

  //CONSTANTS
  const navigate = useNavigate();

  //FUNCTIONS
  //--navigation functions
  //--go to edit content with contentId
  function handleContentClick(contentId) {
    navigate(`/dashboard/contents/content-list/${contentId}`);
  }

  //RENDER
  /*--render grid of cards for contents. 3 Items in a row, each card is clickable to go to contents list page
    --each card has a name, desscription, icon, id. Also has a button to go to edit content page
  */
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: 2,
      }}
    >
      {contents.map((content) => (
        <Card
          key={content.id}
          variant="outlined"
          sx={{ 
            p: 2, 
            borderRadius: "4px", 
            boxShadow: "none",
            cursor: "pointer",
            transition: "all 0.3s",
            '&:hover': {
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
              backgroundColor: "var(--joy-palette-neutral-700)"
            }
          }}
          onClick={() => OnSelect(content)}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level={"title-md"}>{content.name}</Typography>
            <Icon icon={content.icon} style={{ fontSize: "16px", color: "#6B7280" }} />
          </Box>
          <Typography level={"body"} sx={{ color: "text.secondary" }}>
            {content.description}
          </Typography>
        </Card>
      ))}
    </Box>
  );
};

//--add content Form | AddContentForm
const AddContentForm = ({ onContentAdded, onContentAddCancel }) => {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      content_title: "",
      content_status: "",
    },
    validationSchema: Yup.object({
      content_title: Yup.string(),
      content_status: Yup.string(),
    }),
    onSubmit: async (values) => {
      await AddNewContent(values);
    },
  });

  //STATES
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newContentItem, setNewContentItem] = useState({});

  //FUNCTIONS
  async function AddNewContent(values) {
    setIsSubmitting(true);

    ContentApi.create(values)
      .then((response) => {

        setNewContentItem(response.data);
        console.log("CREATE CONTENT", response.data);
        
        setIsError(false);
        setIsSubmitting(false);

        setTimeout(()=>{
          onContentAdded();
        }, 2000);
      })
      .catch((error) => {
        setIsError(true);
        setIsSubmitting(false);
        console.error(error);
      });
  }

  function CancelAddNewContent() {
    onContentAddCancel();
  }


  //RENDER
  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        borderRadius: "4px",
        boxShadow: "none",
        border: "1px solid #3c424d",
      }}
    >
      {/*header*/}
      <Typography level={"title-md"} sx={{ mb: 2 }}>
        Add New Content
      </Typography>
      <Divider />

      {/*form*/}
      <CardContent>
        <FormControl>
          <InputLabel htmlFor="content_title">Title</InputLabel>
          <Input
            id="content_title"
            name="content_title"
            type="text"
            value={formik.values.content_title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="content_status">Status</InputLabel>
          <Select
            variant="outlined"
            id="content_status"
            name="content_status"
            defaultValue="draft"
            value={formik.values.content_status}
            onChange={(e, val)=>formik.setFieldValue("content_status", val)}
            onBlur={formik.handleBlur}
          >
            <Option value="draft">Draft</Option>
            <Option value="published">Published</Option>
            <Option value="trash">Trash</Option>
          </Select>
        </FormControl>

      </CardContent>

      {/*actions*/}
      <CardActions>
        <Button color="danger" onClick={CancelAddNewContent} size="sm">
          Cancel
        </Button>

        <Button
          color={isError ? "danger" : newContentItem?.id ? "success" : "primary"}
          disabled={isSubmitting}
          onClick={formik.handleSubmit}
          size="sm"
          startDecorator={
            isSubmitting && !isError 
            ?
            <Icon icon="svg-spinners:eclipse-half" style={{ fontSize: "16px", color: "#fff" }} />
            :
            <Icon icon="mdi:download" style={{ fontSize: "16px", color: "#fff" }} />
          }
        >
          {
            isSubmitting && !isError
            ? 
            "Adding..." 
            : 
            !isSubmitting && isError
            ?
            "Retry"
            :
            !isSubmitting && !isError && newContentItem?.id
            ?
            "Added"
            :
            "Add"
          }
        </Button>
      </CardActions>
    </Card>
  );
};
