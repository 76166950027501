/*This Define a set of Page Wrapper components That apply a transition effect to the page content when the route changes*/

import React, { useEffect, useState, useRef } from 'react';
import { 
  motion, 
  useAnimation, 
  useMotionValue, 
  useTransform 
} from 'framer-motion';
import { useLocation } from 'react-router-dom';

/*Page Transition
-- It wraps the layout components with the page transition effect
-- It uses the framer-motion library to animate the page content when the route changes
-- It uses the useLocation hook to get the current location object
-- It uses the useAnimation hook to control the animation
-- It uses the useMotionValue and useTransform hooks to create motion values and transforms
*/

/*PageTransition component*/
const PageTransition = ({ children }) => {
  const controls = useAnimation();
  const location = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const x = useMotionValue(0);
  const opacity = useTransform(x, [-100, 0, 100], [0, 1, 0]);

  //Animate Scroll to top on route change
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    controls.start({ x: 100, opacity: 0 });
  }, [location, controls, isFirstLoad]);
  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        style={{ x, opacity }}
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 100, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default PageTransition;