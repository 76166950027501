import React from 'react';
import { Link } from 'react-router-dom';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function PricingTable({
  title,
  subTitle,
  price,
  currency,
  features,
  btnLink,
  btnText,
  timeline,
  popular,
}) {
  return (
    <div className="cs_pricing_table cs_style1 cs_radius_15">
      <h2 className="cs_pricing_title cs_fs_29 cs_semibold">
        {title} {popular && <span>Popular</span>}
      </h2>
      <div className="cs_pricing_info">
        <div className="cs_price">
          <h3 className="cs_accent_color cs_fs_50">
            {currency}
            {price}
          </h3>
          <span className="cs_accent_color cs_medium">
            {timeline && `/${timeline}`}
          </span>
        </div>
        <div className="cs_price_text cs_gray_color_2">{subTitle}</div>
      </div>
      <ul className="cs_pricing_feature cs_mp0 cs_primary_font cs_fs_21">
        {features?.map((item, index) => (
          <li key={index}>
            <span className="cs_feature_icon cs_accent_color">
              <img src="/images/icons/check.svg" alt="Icon" />
            </span>
            <span>{item}</span>
          </li>
        ))}
      </ul>
      <div className="cs_pricing_btn_wrap">
        <Link to={btnLink} className="cs_btn cs_style_1 cs_white_color">
          {btnText}
        </Link>
      </div>
    </div>
  );
}


// Define the editable version of your component
// Replace PricingTable1 with your component's name
export const EditablePricingTable1 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}
      <PricingTable {...props} />
      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    price: node.data.props.price,
    currency: node.data.props.currency,
    features: node.data.props.features,
    btnLink: node.data.props.btnLink,
    btnText: node.data.props.btnText,
    timeline: node.data.props.timeline,
    popular: node.data.props.popular,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditablePricingTable1.craft = {
  displayName: 'PricingTable1',
  props: {
    title: 'Title',
    subTitle: 'Sub Title',
    price: '99',
    currency: '$',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
    btnLink: '/',
    btnText: 'Buy Now',
    timeline: 'month',
    popular: false,
  },
  related: {
    settings: ComponentSettings,
  },
};
