import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
//mui
import { Button, TextField } from "@mui/material";
import { Box, Card, Checkbox, Divider, FormControl, FormLabel, Input, Stack, Typography } from "@mui/joy";
import AuthApi from "../AuthApi";

export default function LoginForm({ onLoginSuccess }) {
  //CONSTANTS

  //CONTEXT

  //STATES
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //FUNCTIONS
  const handleLogin = async (values) => {
    setLoading(true);
    setError(null);
    try {
      //--login logic here
      AuthApi.login(values)
        .then((userData) => {
          console.log("userData", userData);
          
          //store user data in local storage
          localStorage.setItem("currentUser", JSON.stringify(userData.data));

          //store auth token in local storage
          localStorage.setItem("token", userData.data.token);

          //redirect to dashboard
          onLoginSuccess();
        })
        .catch((error) => {
          console.log(error);
          setError("Invalid credentials");
        });

     
    } catch (error) {
      setError("Invalid credentials");
    }
    setLoading(false);
  };

  const assignCurrentUser = (user) => {
    try {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          id: user.id,
          username: user.username,
          email: user.email,
          role: user.role,
          profile: {
            avatar: user.profile.avatar,
            displayName: user.profile.displayName,
            bio: user.profile.bio,
            status: user.profile.status,
            settings: {
              theme: user.profile.settings.theme,
              direction: user.profile.settings.direction,
              themeMode: user.profile.settings.themeMode,
            },
            social: {
              facebook: user.profile.social.facebook,
              twitter: user.profile.social.twitter,
              linkedin: user.profile.social.linkedin,
              instagram: user.profile.social.instagram,
              github: user.profile.social.github,
            },
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fakeLogin = async (values) => {
    setLoading(true);
    fake_assignCurrentUser(values);

    setTimeout(() => {
      onLoginSuccess();
      setLoading(false);
    }, 2000);
  };

  const fake_assignCurrentUser = (auth) => {
    try {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          id: 1,
          username: "admin",
          email: auth.email,
          role: "admin",
          profile: {
            avatar: "https://i.pravatar.cc/150?img=3",
            displayName: "Admin",
            bio: "Admin User",
            status: "online",
            settings: {
              theme: "light",
              direction: "ltr",
              themeMode: "system",
            },
            social: {
              facebook: "https://www.facebook.com",
              twitter: "https://www.twitter.com",
              linkedin: "https://www.linkedin.com",
              instagram: "https://www.instagram.com",
              github: "https://www.github.com",
            },
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  //USE EFFECTS
  // FORMIK
  const formik = useFormik({
    initialValues: {
      email: "thando@soimagine.co.za",
      password: "Sa9eReaktion",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleLogin(values);
      //fakeLogin(values);
    },
  });

  // RENDER
  return (
    <Card
      variant="outlined"
      sx={{
        width: { xs: "100%", sm: "450px" },
        mt: 6,
      }}
    >
        <Stack gap={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography component="h1" level="h3">
              Sign in
            </Typography>
            <Typography level="body-sm">
              New to SoImagine?{" "}
              <Link to={"/auth/register"} level="title-sm">
                Sign up!
              </Link>
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startDecorator={<Icon icon="devicon:google" width={24 } height={24} />}
          >
            Continue with Google
          </Button>
        </Stack>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector("light")]: {
              color: { xs: "#FFF", md: "text.tertiary" },
            },
          })}
        >
          or
        </Divider>
        <Stack gap={4} sx={{ mt: 2 }}>
          <form>
            <FormControl required>
              <FormLabel>Email</FormLabel>
              <Input type="email" name="email" />
            </FormControl>
            <FormControl required>
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" />
            </FormControl>
            <Stack gap={4} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox size="sm" label="Remember me" name="persistent" />
                <Link level="title-sm" href="#replace-with-a-link">
                  Forgot your password?
                </Link>
              </Box>
              <Button type="submit" fullWidth>
                Sign in
              </Button>
            </Stack>
          </form>
        </Stack>
    </Card>
  );
}
