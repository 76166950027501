import { useState } from "react";
import Button from "../Button";
import parse from "html-react-parser";
//Editor
import { Element, useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import Text from "src/dashboard/modules/pages/designer/editorComponents/Text";
import TypewriterEffect from "../Framer/TypewriterEffect";
import DrawLogo from "../Framer/DrawLogo";
import { TypeAnimation } from "react-type-animation";

export default function HeroStyle5({
  highlightTitle,
  title,
  subTitle,
  btnText,
  btnUrl,
  videoSrc,
  videoBtnText,
}) {
  const [iframeSrc, setIframeSrc] = useState("about:blank");
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    setIframeSrc(`${videoSrc}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc("about:blank");
    setToggle(!toggle);
  };
  return (
    <section
      className="cs_hero cs_style_5 position-relative text-center cs_center cs_ripple_activate cs_bg_filed"
      style={{ backgroundImage: "url(/images/tech-startup/hero-bg.jpeg)" }}
    >
      <div className="container">
        <div className="cs_hero_text position-relative">
          <p className="cs_hero_top_title">
            <img src="/images/icons/flower.svg" alt="Icon" />
            {highlightTitle}
          </p>

          <DrawLogo height={500} width={150} />

          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed once, initially
              "We Design",
              1000,
              "We Develop",
              1000,
              "We Deliver",
              1000,
              "We Make Your Ideas ",
              1300,
              "We Make Your Ideas Go Live",
              3000
            ]}
            speed={40}
            style={{ fontSize: "2em" }}
            repeat={Infinity}
          />

          <p className="cs_hero_subtitle">{parse(subTitle)}</p>
          <div className="cs_hero_btns">
            <Button
              btnText={btnText}
              btnUrl={btnUrl}
              variantColor="cs_btn_accent"
            />
            <span className="cs_play_btn cs_style_1" onClick={handelClick}>
              <img src="/images/icons/play.svg" alt="Icon" />
              {videoBtnText}
            </span>
          </div>
        </div>
      </div>
      <div id="background-wrap">
        <div className="bubble cs_hero_shape_1">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_2">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_3">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_4">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_5">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_6">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_7">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_8">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_9">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
        <div className="bubble cs_hero_shape_10">
          <img
            src="/images/tech-startup/hero_circle_shape_1.png"
            alt="Circle"
          />
        </div>
      </div>
      <div className={toggle ? "cs_video_popup active" : "cs_video_popup"}>
        <div className="cs_video_popup_overlay" />
        <div className="cs_video_popup_content">
          <div className="cs_video_popup_layer" />
          <div className="cs_video_popup_container">
            <div className="cs_video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={iframeSrc}
                  title="video modal"
                />
              </div>
            </div>
            <div className="cs_video_popup_close" onClick={handelClose} />
          </div>
        </div>
      </div>
    </section>
  );
}

export const EditableHero5 = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  //STATES
  const [iframeSrc, setIframeSrc] = useState("about:blank");
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    setIframeSrc(`${props.videoSrc}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc("about:blank");
    setToggle(!toggle);
  };

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        //padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <section
        className="cs_hero cs_style_5 position-relative text-center cs_center cs_ripple_activate cs_bg_filed"
        style={{ backgroundImage: "url(/images/tech-startup/hero-bg.jpeg)" }}
      >
        <div className="container">
          <div className="cs_hero_text position-relative">
            <p className="cs_hero_top_title">
              <img src="/images/icons/flower.svg" alt="Icon" />
              {props.highlightTitle}
            </p>
            {/* <h1 className="cs_hero_title cs_fs_68 cs_white_color">
            {parse(title)}
          </h1> */}
            <Element is={Text} content={props.title} id="title" />

            <p className="cs_hero_subtitle">{parse(props.subTitle)}</p>
            <div className="cs_hero_btns">
              <Button
                btnText={props.btnText}
                btnUrl={props.btnUrl}
                variantColor="cs_btn_accent"
              />
              <span
                className="cs_play_btn cs_style_1"
                onClick={props.handelClick}
              >
                <img src="/images/icons/play.svg" alt="Icon" />
                {props.videoBtnText}
              </span>
            </div>
          </div>
        </div>
        <div id="background-wrap">
          <div className="bubble cs_hero_shape_1">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_2">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_3">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_4">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_5">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_6">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_7">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_8">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_9">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
          <div className="bubble cs_hero_shape_10">
            <img
              src="/images/tech-startup/hero_circle_shape_1.png"
              alt="Circle"
            />
          </div>
        </div>
        <div className={toggle ? "cs_video_popup active" : "cs_video_popup"}>
          <div className="cs_video_popup_overlay" />
          <div className="cs_video_popup_content">
            <div className="cs_video_popup_layer" />
            <div className="cs_video_popup_container">
              <div className="cs_video_popup_align">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src={iframeSrc}
                    title="video modal"
                  />
                </div>
              </div>
              <div className="cs_video_popup_close" onClick={handelClose} />
            </div>
          </div>
        </div>
      </section>

      {/* Component End */}
    </Box>
  );
};

const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    highlightTitle,
    title,
    subTitle,
    btnText,
    btnUrl,
    videoSrc,
    videoBtnText,
  } = useNode((node) => ({
    highlightTitle: node.data.props.highlightTitle,
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    btnText: node.data.props.btnText,
    btnUrl: node.data.props.btnUrl,
    videoSrc: node.data.props.videoSrc,
    videoBtnText: node.data.props.videoBtnText,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = useState(false);

  //COMPONENT HANDLERS
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handlePanelChange("panel1")}
      >
        <AccordionSummary>
          <Typography>Typography</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Input
            label="Highlight Title"
            value={highlightTitle}
            onChange={(e) => {
              setProp((props) => {
                props.highlightTitle = e.target.value;
              });
            }}
          />

          <Input
            label="Title"
            value={title}
            onChange={(e) => {
              setProp((props) => {
                props.title = e.target.value;
              });
            }}
          />

          <Input
            label="Sub Title"
            value={subTitle}
            onChange={(e) => {
              setProp((props) => {
                props.subTitle = e.target.value;
              });
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handlePanelChange("panel2")}
      >
        <AccordionSummary>
          <Typography>Button</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Input
            label="Button Text"
            value={btnText}
            onChange={(e) => {
              setProp((props) => {
                props.btnText = e.target.value;
              });
            }}
          />

          <LinkComponentSettings
            settings={{
              url: btnUrl,
              openInNewTab: false,
              noFollow: false,
            }}
            onSettingsChange={(newSettings) => {
              setProp((props) => {
                props.buttonLink = newSettings.url;
              });
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handlePanelChange("panel3")}
      >
        <AccordionSummary>
          <Typography>Video</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilePickerOpener
            onSelected={(file) => {
              setProp((props) => {
                props.videoSrc = file.url;
              });
            }}
          />

          <Input
            label="Video Button Text"
            value={videoBtnText}
            onChange={(e) => {
              setProp((props) => {
                props.videoBtnText = e.target.value;
              });
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableHero5.craft = {
  displayName: "Hero Style 5",
  props: {
    highlightTitle: "We are",
    title: "<span>Leading</span> the digital revolution",
    subTitle: "<span>Transforming</span> the way you do business",
    btnText: "Get Started",
    btnUrl: "/",
    videoSrc: "https://www.youtube.com/embed/1aPfFb2QY0c",
    videoBtnText: "Watch Video",
  },
  related: {
    settings: ComponentSettings,
  },
};
