import React from "react";
import VideoModal from "../VideoModal";
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton, Stack } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

export default function HeroStyle4({ videoSrc, bgUrl, thumbnailSrc }) {
  return (
    <section
      className="cs_hero cs_style_4 position-relative"
      style={{
        backgroundImage: `url(${thumbnailSrc})`,
      }}
    >
      <div className="container">
        <div className="cs_hero_text position-relative">
          <h1 className="cs_hero_title cs_fs_68">
            Mastering Digital Realm, Exploring the Dynamic World of Our Digital
            Studio
          </h1>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="cs_hero_subtitle">
              <p className="mb-0">
                Our agency offers a comprehensive suite of lots services,
                including Branding, Advertising, Social marketing, Video making,
                and Marketing analysis.
              </p>
              <a
                href="#service"
                className="cs_down_btn cs_center cs_primary_color"
              >
                <svg
                  width={8}
                  height={33}
                  viewBox="0 0 8 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.64645 32.3536C3.84171 32.5488 4.15829 32.5488 4.35355 32.3536L7.53554 29.1716C7.7308 28.9763 7.7308 28.6597 7.53554 28.4645C7.34027 28.2692 7.02369 28.2692 6.82843 28.4645L4 31.2929L1.17157 28.4645C0.976312 28.2692 0.65973 28.2692 0.464467 28.4645C0.269205 28.6597 0.269205 28.9763 0.464467 29.1716L3.64645 32.3536ZM3.5 2.18557e-08L3.5 32L4.5 32L4.5 -2.18557e-08L3.5 2.18557e-08Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <VideoModal videoSrc={videoSrc} bgUrl={bgUrl} />
          </div>
        </div>
      </div>
      <div className="cs_hero_shape">
        <svg
          width={458}
          height={475}
          viewBox="0 0 458 475"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M2.35848 153.351L456.103 1.77286L370.156 472.382L2.35848 153.351Z"
            stroke="#A3A3A3"
            strokeWidth={2}
          />
        </svg>
      </div>
    </section>
  );
}

export const EditableHero4 = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  //PROPS
  const {
    //Media
    videoSrc,
    bgUrl,
    thumbnailSrc,
    //Text
    title,
    subtitle,
    //Button
    buttonText,
    buttonLink,
    //svg
    svgElements,
  } = props;

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        //padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <section
        className="cs_hero cs_style_4 position-relative"
        style={{
          backgroundImage: `url(${thumbnailSrc})`,
        }}
      >
        <div className="container">
          <div className="cs_hero_text position-relative">
            <h1 className="cs_hero_title cs_fs_68">
              {title
                ? title
                : "Mastering Digital Realm, Exploring the Dynamic World of Our Digital Studio"}
            </h1>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="cs_hero_subtitle">
                <p className="mb-0">
                  {subtitle
                    ? subtitle
                    : "Our agency offers a comprehensive suite of lots services, including Branding, Advertising, Social marketing, Video making, and Marketing analysis."}
                </p>
                <a
                  href="#service"
                  className="cs_down_btn cs_center cs_primary_color"
                >
                  <svg
                    width={svgElements[0].width}
                    height={svgElements[0].height}
                    viewBox="0 0 8 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.64645 32.3536C3.84171 32.5488 4.15829 32.5488 4.35355 32.3536L7.53554 29.1716C7.7308 28.9763 7.7308 28.6597 7.53554 28.4645C7.34027 28.2692 7.02369 28.2692 6.82843 28.4645L4 31.2929L1.17157 28.4645C0.976312 28.2692 0.65973 28.2692 0.464467 28.4645C0.269205 28.6597 0.269205 28.9763 0.464467 29.1716L3.64645 32.3536ZM3.5 2.18557e-08L3.5 32L4.5 32L4.5 -2.18557e-08L3.5 2.18557e-08Z"
                      fill={svgElements[0].fill}
                      stroke={svgElements[0].stroke}
                      strokeWidth={svgElements[0].strokeWidth}
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <VideoModal videoSrc={videoSrc} bgUrl={bgUrl} />
            </div>
          </div>
        </div>
        <div className="cs_hero_shape">
          <svg
            width={svgElements[1].width}
            height={svgElements[1].height}
            viewBox={`0 0 ${svgElements[1].width} ${svgElements[1].height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M2.35848 153.351L456.103 1.77286L370.156 472.382L2.35848 153.351Z"
              stroke={svgElements[1].stroke}
              strokeWidth={svgElements[1].strokeWidth}
            />
          </svg>
        </div>
      </section>

      {/* Component End */}
    </Box>
  );
};

// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    videoSrc,
    bgUrl,
    thumbnailSrc,
    title,
    subtitle,
    buttonText,
    buttonLink,
    svgElements,
  } = useNode((node) => ({
    videoSrc: node.data.props.videoSrc,
    bgUrl: node.data.props.bgUrl,
    thumbnailSrc: node.data.props.thumbnailSrc,
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    buttonText: node.data.props.buttonText,
    buttonLink: node.data.props.buttonLink,
    svgElements: node.data.props.svgElements,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);
  //colors
  const [color1, setColor1] = useColor("hex", "#121212");
  const [color2, setColor2] = useColor("hex", "#ff4422");

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <Input
            label="Title"
            value={title}
            onChange={(e) => setProp((props) => (props.title = e.target.value))}
          />
          <Input
            label="Subtitle"
            value={subtitle}
            onChange={(e) =>
              setProp((props) => (props.subtitle = e.target.value))
            }
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Media</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <FilePickerOpener
            label="Background Image"
            onSelected={(file) => {
              setProp((props) => {
                props.thumbnailSrc = file.url;
              });
            }}
          />

          <FilePickerOpener
            label="BG Video"
            onSelected={(file) => {
              setProp((props) => {
                props.videoSrc = file.url;
              });
            }}
          />

          <FilePickerOpener
            label="Fallback Image"
            onSelected={(file) => {
              setProp((props) => {
                props.bgUrl = file.url;
              });
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${2}`}
        onChange={handleChange(`panel-${2}`)}
      >
        <AccordionSummary
          aria-controls={`panel${2}-content`}
          id={`panel${2}-header`}
        >
          <Typography level="title-sm">Button</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <Input
            label="Button Text"
            value={buttonText}
            onChange={(e) =>
              setProp((props) => (props.buttonText = e.target.value))
            }
          />

          <LinkComponentSettings
            settings={{
              url: buttonLink,
              openInNewTab: false,
              noFollow: false,
            }}
            onSettingsChange={(newSettings) => {
              setProp((props) => {
                props.buttonLink = newSettings.url;
              });
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${3}`}
        onChange={handleChange(`panel-${3}`)}
      >
        <AccordionSummary
          aria-controls={`panel${3}-content`}
          id={`panel${3}-header`}
        >
          <Typography level="title-sm">SVG - 1</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <Input
            label="SVG Element"
            value={svgElements[0].fill}
            onChange={(e) =>
              setProp((props) => (props.svgElements[0].fill = e.target.value))
            }
          />

          <Stack direction="row" spacing={2}>
            <Input
              label="Width"
              value={svgElements[0].width}
              onChange={(e) =>
                setProp((props) => (props.svgElements[0].width = e.target.value))
              }
            />
            <Input
              label="Height"
              value={svgElements[0].height}
              onChange={(e) =>
                setProp((props) => (props.svgElements[0].height = e.target.value))
              }
            />
          </Stack>

          <ColorPicker
            width={250}
            height={250}
            color={color1}
            onChange={(color) =>
              setProp((props) => (props.svgElements[0].stroke = color.hex))
            }
          />
          <Input
            label="Stroke Width"
            value={svgElements[0].strokeWidth}
            onChange={(e) =>
              setProp((props) => (props.svgElements[0].strokeWidth = e.target.value))
            }
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${4}`}
        onChange={handleChange(`panel-${4}`)}
      >
        <AccordionSummary
          aria-controls={`panel${4}-content`}
          id={`panel${4}-header`}
        >
          <Typography level="title-sm">SVG - 2</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <Input
            label="SVG Element"
            value={svgElements[1].fill}
            onChange={(e) =>
              setProp((props) => (props.svgElements[1].fill = e.target.value))
            }
          />

          <Stack direction="row" spacing={2}>
            <Input
              label="Width"
              value={svgElements[1].width}
              onChange={(e) =>
                setProp((props) => (props.svgElements[1].width = e.target.value))
              }
            />
            <Input
              label="Height"
              value={svgElements[1].height}
              onChange={(e) =>
                setProp((props) => (props.svgElements[1].height = e.target.value))
              }
            />
          </Stack>

          <ColorPicker
            width={250}
            height={250}
            color={color2}
            onChange={(color) =>
              setProp((props) => (props.svgElements[1].stroke = color.hex))
            }
          />
          <Input
            label="Stroke Width"
            value={svgElements[1].strokeWidth}
            onChange={(e) =>
              setProp((props) => (props.svgElements[1].strokeWidth = e.target.value))
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableHero4.craft = {
  props: {
    videoSrc: "",
    bgUrl: "",
    thumbnailSrc: "",
    title: "Mastering Digital Realm, Exploring the Dynamic World of Our Digital Studio",
    subtitle: "Our agency offers a comprehensive suite of lots services, including Branding, Advertising, Social marketing, Video making, and Marketing analysis.",
    buttonText: "Learn More",
    buttonLink: "#",
    svgElements: [
      {
        fill: "#000000",
        width: 8,
        height: 33,
        stroke: "#000000",
        strokeWidth: 2,
      },
      {
        width: 458,
        height: 475,
        stroke: "#A3A3A3",
        strokeWidth: 2,
      },
    ],
  },
  related: {
    settings: ComponentSettings,
  },
};
