import React from 'react';
import parse from 'html-react-parser';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function HeroStyle3({ title, scrollingText, thumbnailSrc }) {
  return (
    <section
      className="cs_hero cs_style_3 cs_bg_filed cs_center"
      style={{ backgroundImage: `url(${thumbnailSrc})` }}
    >
      <div className="container">
        <div className="cs_hero_text position-relative">
          <h1 className="cs_hero_title cs_fs_68 text-uppercase">
            {parse(title)}
          </h1>
          <div className="cs_moving_text_wrap">
            <div className="cs_moving_text_in">
              <div className="cs_moving_text">{scrollingText}</div>
              <div className="cs_moving_text">{scrollingText}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const EditableHero3 = (props) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        //padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start */}

      <HeroStyle3 {...props} />

      {/* Component End */}

    </Box>
  );
    
};

// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    title,
    scrollingText,
    thumbnailSrc,
  } = useNode((node) => ({
    title: node.data.props.title,
    scrollingText: node.data.props.scrollingText,
    thumbnailSrc: node.data.props.thumbnailSrc,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
         <Input
            value={title}
            onChange={(e) => {
              setProp((props) => {
                props.title = e.target.value;
              });
            }}
            label="Title"
            placeholder="Title"
          />
          
          <Input
            value={scrollingText}
            onChange={(e) => {
              setProp((props) => {
                props.scrollingText = e.target.value;
              });
            }}
            label="Scrolling Text"
            placeholder="Scrolling Text"
          />

          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >

          <FilePickerOpener
          currentFile={thumbnailSrc}
          onSelected={(file) => {
            setProp((props) => {
              props.thumbnailSrc = file.url;
            });
          }}
          />

        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableHero3.craft = {
  props: {
    title: "Hero Title",
    scrollingText: "Scrolling Text",
    thumbnailSrc: "https://via.placeholder.com/1920x1080",
  },
  related: {
    settings: ComponentSettings,
  },
};


