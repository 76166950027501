import { extendTheme } from "@mui/joy/styles";

const soimagineTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#fef2f2",
          100: "#fee2e2",
          200: "#fecaca",
          300: "#fca5a5",
          400: "#f87171",
          500: "#ef4444",
          600: "#dc2626",
          700: "#b91c1c",
          800: "#991b1b",
          900: "#7f1d1d",
        },
        neutral: {
          50: "#f8fafc",
          100: "#f1f5f9",
          200: "#e2e8f0",
          300: "#cbd5e1",
          400: "#94a3b8",
          500: "#64748b",
          600: "#475569",
          700: "#334155",
          800: "#1e293b",
          900: "#0f172a",
        },
        danger: {
          50: "#fae8ea",
          100: "#f2c5c8",
          200: "#d98d8b",
          300: "#c76360",
          400: "#cb423c",
          500: "#ca3021",
          600: "#bc2621",
          700: "#ac1d1c",
          800: "#9f1615",
          900: "#8f0b0b",
        },
        success: {
          50: "#ebffeb",
          100: "#ccffcd",
          200: "#a7ffac",
          300: "#77ff85",
          400: "#3efc64",
          500: "#00f941",
          600: "#00e639",
          700: "#00d02e",
          800: "#00bb22",
          900: "#009515",
        },
        warning: {
          50: "#fef8e1",
          100: "#feecb3",
          200: "#fddf82",
          300: "#fcd44f",
          400: "#fcc929",
          500: "#fbc009",
          600: "#fbb201",
          700: "#fb9f00",
          800: "#fb8e00",
          900: "#fb6f00",
        },
        info: {
          50: "#e1f3ff",
          100: "#b8dfff",
          200: "#84cbff",
          300: "#3db6ff",
          400: "#00a5ff",
          500: "#0094ff",
          600: "#0084ff",
          700: "#0070ff",
          800: "#005cfb",
          900: "#2635dc",
        },
        background: {
          body: "var(--joy-palette-neutral-50)",
          surface: "var(--joy-palette-neutral-100)",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: "#ffebee",
          100: "#ffcdd2",
          200: "#ef9a9a",
          300: "#e57373",
          400: "#ef5350",
          500: "#f44336",
          600: "#e53935",
          700: "#d32f2f",
          800: "#c62828",
          900: "#b71c1c",
        },
        neutral: {
          50: "#f8fafc",
          100: "#f1f5f9",
          200: "#e2e8f0",
          300: "#cbd5e1",
          400: "#94a3b8",
          500: "#64748b",
          600: "#475569",
          700: "#334155",
          800: "#1e293b",
          900: "#0f172a",
        },
        danger: {
          50: "#fae8ea",
          100: "#f2c5c8",
          200: "#d98d8b",
          300: "#c76360",
          400: "#cb423c",
          500: "#ca3021",
          600: "#bc2621",
          700: "#ac1d1c",
          800: "#9f1615",
          900: "#8f0b0b",
        },
        success: {
          50: "#ebffeb",
          100: "#ccffcd",
          200: "#a7ffac",
          300: "#77ff85",
          400: "#3efc64",
          500: "#00f941",
          600: "#00e639",
          700: "#00d02e",
          800: "#00bb22",
          900: "#009515",
        },
        warning: {
          50: "#fef8e1",
          100: "#feecb3",
          200: "#fddf82",
          300: "#fcd44f",
          400: "#fcc929",
          500: "#fbc009",
          600: "#fbb201",
          700: "#fb9f00",
          800: "#fb8e00",
          900: "#fb6f00",
        },
        info: {
          50: "#e1f3ff",
          100: "#b8dfff",
          200: "#84cbff",
          300: "#3db6ff",
          400: "#00a5ff",
          500: "#0094ff",
          600: "#0084ff",
          700: "#0070ff",
          800: "#005cfb",
          900: "#2635dc",
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "Century Gothic Bold",
    },
    h2: {
      fontFamily: "Century Gothic Bold",
    },
    h3: {
      fontFamily: "Century Gothic Bold",
    },
    h4: {
      fontFamily: "Century Gothic Bold",
    },
    "title-lg": {
      fontFamily: "Circular Bold",
    },
    "title-md": {
      fontFamily: "Circular",
    },
    "title-sm": {
      fontFamily: "Circular Light",
    },
    "body-lg": {
      fontFamily: "Circular",
    },
    "body-md": {
      fontFamily: "Circular Light",
    },
    "body-sm": {
      fontFamily: "Circular Light",
    },
    "body-xs": {
      fontFamily: "Circular Light",
    },
  },
});

export default soimagineTheme;
