import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { Icon } from "@iconify/react";
import { pageTitle } from "../../helpers/PageTitle";
import PortfolioApi from "src/dashboard/modules/content/PortfolioApi";
import { Link, useLocation } from "react-router-dom";
import PageTransition from "../Framer/PageTransitions";
import moment from "moment";

export default function PortfolioDetailsPage() {
  pageTitle("Portfolio Details");

  //CONSTANTS
  const location = useLocation();

  //STATE
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [postId, setPostId] = React.useState(
    location.pathname.split("/").pop()
  );
  const [post, setPost] = React.useState(null);
  //get next and previous post
  const [nextPost, setNextPost] = React.useState(null);
  const [prevPost, setPrevPost] = React.useState(null);

  //FUNCTIONS
  /*--fetches post data from the API
  returns:{
    title : string
    content : string
    status: string
    category: array
    author: string
    featured_image: url (string)
    client : object {name:string, logo:url(string)}
    tech_stack: array
    project_url: url(string)
    project_completion: date(string)(DD/MM/YYYY)
    project_gallery: array of objects {image:url(string), title:string}
  }
  */
  const fetchPost = async () => {
    setLoading(true);
    try {
      const response = await PortfolioApi.getOne(
        location.pathname.split("/").pop()
      );
      console.table(response.data);
      setPost(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  //--creates HTML content from string
  const createHTMLContent = (content) => {
    return { __html: content };
  };

  //--gets all posts from the API
  //this is used to get the previous and next post
  //called when everything is loaded and post is set
  const getPrevNextPost = async () => {
    try {
      const response = await PortfolioApi.getAll();
      
      //get the index of the current post
      const currentIndex = response.data.findIndex(
        (singlePost) => singlePost.id === post.id
      );
      
      //get the previous post
      if (currentIndex > 0) {
        setPrevPost(response.data[currentIndex - 1]);
      }
      
      //get the next post
      if (currentIndex < response.data.length - 1) {
        setNextPost(response.data[currentIndex + 1]);
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  //EFFECTS
  //--Scroll to the top of the page when the location changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    fetchPost();
  }, [location.pathname]);

  React.useEffect(() => {
    if (post) {
      getPrevNextPost();
    }
  }, [post]);

  //COMPONENTS
  const Loading = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2
        className="cs-recent_post_title "
        style={{
          margin: "0",
          padding: "0",
        }}
      >
        Loading...
      </h2>
      <Icon icon="svg-spinners:blocks-shuffle-3" width="50" height="50" />
    </div>
  );

  const Error = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <Icon icon="carbon:error" width="50" height="50" />
      <h2 className="cs-recent_post_title ">Error</h2>
      <p style={{ margin: "0", padding: "0" }}>{error.message}</p>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2 className="cs-recent_post_title" style={{}}>
        No Posts
      </h2>
      <Icon icon="bi:exclamation-triangle-fill" width="50" height="50" />
    </div>
  );

  return (
    <PageTransition>
      <Spacing lg="70" md="70" />

      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : post ? (
        <>
          <SectionHeadingStyle3
            title={post.title}
            subTitle={post.category.join(", ")}
          />
          <Spacing lg="75" md="60" />
          <div className="container">
            <div className="cs_portfolio_details">
              <img src={post.featured_image} alt="Thumb" />
              <Spacing lg="100" md="40" />
              <div className="cs_portfolio_details_in">
                <ul className="cs_portfolio_details_info cs_mp0">
                  <li>
                    <h3 className="cs_fs_21 mb-0 cs_semibold">Client:</h3>
                    <div className="cs_client_logo">
                      <img 
                        src={post.client.logo} 
                        alt="Thumb"
                        style={{ width: "auto", height: "75px" }}
                      />
                      <p>{post.client.name}</p>
                    </div>
                  </li>
                  <li>
                    <h3 className="cs_fs_21 mb-0 cs_semibold">Tech Stack</h3>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                      post.tech_stack.map((tech, index) => (
                        <>
                          {
                            tech === 'wordpress'
                            ?
                            <Icon icon="logos:wordpress-icon" width="30" height="30" />
                            :
                            tech === 'reactjs'
                            ?
                            <Icon icon="logos:react" width="30" height="30" />
                            :
                            tech === 'nodejs'
                            ?
                            <Icon icon="logos:nodejs" width="30" height="30" />
                            :
                            tech === 'laravel'
                            ?
                            <Icon icon="logos:laravel" width="30" height="30" />
                            :
                            tech === 'nextjs'
                            ?
                            <Icon icon="logos:nextjs" width="30" height="30" />
                            :
                            tech === 'electronjs'
                            &&
                            <Icon icon="logos:electron" width="30" height="30" />
                          }
                        </>
                      ))
                    }
                    </div>
                  </li>
                  <li>
                    <h3 className="cs_fs_21 mb-0 cs_semibold">Project URL:</h3>
                    <Link to={post.project_url} className="cs_text_btn">
                      {post.project_url}
                    </Link>
                  </li>
                  <li>
                    <h3 className="cs_fs_21 mb-0 cs_semibold">
                      Project Completed:
                    </h3>
                    <p className="mb-0">{moment(post.project_completion).format("DD MMM YYYY")}</p>
                  </li>
                </ul>
                <div className="cs_portfolio_details_right">
                  <h2>Project Summary</h2>
                  {
                    React.createElement("div", {
                      dangerouslySetInnerHTML: createHTMLContent(post.content),
                    })
                  }
                </div>
              </div>
              <Spacing lg="115" md="60" />
              <div className="cs_portfolio_details_gallery">
                <div>
                  <img
                    src={post.project_gallery[0].image}
                    alt={post.project_gallery[0].title}
                  />
                </div>
                <div>
                  <img
                    src={post.project_gallery[1].image}
                    alt={post.project_gallery[1].title}
                  />
                </div>
                <div>
                  <img
                    src={post.project_gallery[2].image}
                    alt={post.project_gallery[2].title}
                  />
                </div>
              </div>
            </div>
            <Spacing lg="90" md="60" />
            <div className="cs_page_navigation cs_center">
              <div>
                <Link to={`/portfolio/${prevPost?.id}`} className="cs_text_btn" disabled={!prevPost}>
                  <Icon icon="cil:arrow-left" className="cs_fs_21" />
                  <span>Previous Project</span>
                </Link>
              </div>
              <div>
                <Link to={`/portfolio/${nextPost?.id}`} className="cs_text_btn" disabled={!nextPost}>
                  <span>Next Project</span>
                  <Icon icon="cil:arrow-right" className="cs_fs_21" />
                </Link>
              </div>
            </div>
          </div>
          <Spacing lg="150" md="80" />
        </>
      ) : (
        <Empty />
      )}
    </PageTransition>
  );
}
