/* This File Defines the ChatBot Component */

import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Popover } from '@mui/material'
import { IconButton } from "@mui/joy";
import ChatInterface from "./chatInterface";
import {
  usePopupState,
  bindTrigger,
  bindHover,
  bindPopover,
  bindToggle,
} from 'material-ui-popup-state/hooks'
import { Icon } from "@iconify/react";

const Sa9eAssistant = ({ classes }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })
  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
      }}
    >

      <IconButton
        aria-label="chat"
        {...bindTrigger(popupState)}
        size="large"
        sx={{
          bgcolor: "primary.main",
          color: "white",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Icon icon="bi-chat-left-text" width="24" height="24" />
      </IconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}

        sx={{
          "& .MuiPopover-paper": {
            bgcolor: "transparent",
          },
        }}
      >
        <ChatInterface />
      </Popover>
    </div>
  );
};


export default Sa9eAssistant;