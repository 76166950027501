/* This File Defines All the API Requests for the Constants found across the Application.
- 1. Partners 
- 2. Clients
*/

import api from './index';

const Partners = [
  {
    id: 1,
    name: 'Dell Technologies Authorized Partner',
    logoSrc: 'https://soimagine.co.za/wp-content/uploads/2023/10/dell.png',
    website: 'https://www.dell.com',
  },
  {
    id: 2,
    name: 'AWS Partner Network',
    logoSrc: 'https://soimagine.co.za/wp-content/uploads/2024/02/aws_partner_gry.png',
    website: 'https://aws.amazon.com/partners/',
  },
  {
    id: 3,
    name: 'SUSE One Partner Program',
    logoSrc: 'https://soimagine.co.za/wp-content/uploads/2024/02/suse_partner_gry.png',
    website: 'https://www.suse.com/partners/',
  },
  {
    id: 4,
    name: 'Partner 4',
    logoSrc: 'https://via.placeholder.com/150',
    logoAlt: 'Partner 4 Logo'
  },
  {
    id: 5,
    name: 'Huawei',
    logoSrc: 'https://soimagine.co.za/wp-content/uploads/2024/02/huawui_partner_gry.png',
    website: 'https://www.huawei.com/en/partners',
  },
  {
    id: 6,
    name: 'Dahua Technology',
    logoSrc: 'https://soimagine.co.za/wp-content/uploads/2024/02/dahua_partner_gry.png',
    website: 'https://www.dahuasecurity.com/',
  }
]

const Clients = [
  /*
  LVLX | GAUSHEQ | AFRIZAN ACADEMY | TTJ LOGISTICS |
  DEPT OF SPORTS, ARTS AND CULTURE | THE MARKET - PHOTO WORKSHOP | THE MARKET THEATRE | JAVETT-UP | MSG SOLUTIONS | KENANI SOLUTIONS */
  {
    id: 1,
    name: "LVL X YOUTH",
    logoSrc: "/soimagine/clients/client1.webp",
    website: "https://www.lvlxyouth.com/",
  },
  {
    id: 2, 
    name: "Gausheq",
    logoSrc: "/soimagine/clients/client2.png",
    website: "https://www.gausheq.com/",
  },
  {
    id: 3,
    name: "Afrizan Academy",
    logoSrc: "/soimagine/clients/client3.png",
    website: "https://www.afrizanacademy.co.za/",
  },
  {
    id: 4,
    name: "TTJ Logistics",
    logoSrc: "/soimagine/clients/client4.png",
    website: "https://www.ttjlogistics.co.za/",
  },
  {
    id: 5,
    name: "Dept of Sports, Arts and Culture",
    logoSrc: "/soimagine/clients/client5.png",
    website: "https://www.gov.za/",
  },
  {
    id: 6,
    name: "The Market - Photo Workshop",
    logoSrc: "/soimagine/clients/client6_1.png",
    website: "https://www.marketphotoworkshop.co.za/",
  },
  {
    id: 7,
    name: "The Market Theatre",
    logoSrc: "/soimagine/clients/client6.png",
    website: "https://www.markettheatre.co.za/",
  },
  {
    id: 8,
    name: "Javett-UP",
    logoSrc: "/soimagine/clients/client7.png",
    website: "https://www.javettup.art/",
  },
  {
    id: 9,
    name: "MSG Solutions",
    logoSrc: "/soimagine/clients/client8.png",
    website: "https://www.msgsolutions.co.za/",
  },
  {
    id: 10,
    name: "Kenani Solutions",
    logoSrc: "/soimagine/clients/client9.png",
    website: "https://www.kenani.co.za/",
  },
]

export const getPartners = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(Partners);
    }, 1500);
  });
}

export const getClients = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(Clients);
    }, 1500);
  });
}

export default {
  getPartners,
  getClients
}

