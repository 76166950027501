/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3, {
  SectionHeadingStyle3_Typed,
} from "../SectionHeading/SectionHeadingStyle3";
import { Icon } from "@iconify/react";
import AuthorWidget from "../Widget/AuthorWidget";
import { pageTitle } from "../../helpers/PageTitle";
import BlogPostApi from "src/dashboard/modules/blog/BlogPostApi";
import { useLocation } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import moment from "moment";
import PageTransition from "../Framer/PageTransitions";

export default function BlogDetailsPage() {
  //PAGE META HELMET
  pageTitle("Blog Details");

  //CONSTANTS
  const location = useLocation();

  //STATE
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [postId, setPostId] = React.useState(
    location.pathname.split("/").pop()
  );
  const [post, setPost] = React.useState(null);

  //FUNCTIONS

  const fetchPost = async () => {
    setLoading(true);
    try {
      const response = await BlogPostApi.getOne(
        location.pathname.split("/").pop()
      );
      console.table(response.data);
      setPost(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const createHTMLContent = (content) => {
    return { __html: content };
  }

  //EFFECTS
  React.useEffect(() => {
    fetchPost();
  }, []);

  //COMPONENTS
  const Loading = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2
        className="cs-recent_post_title "
        style={{
          margin: "0",
          padding: "0",
        }}
      >
        Loading...
      </h2>
      <Icon icon="svg-spinners:blocks-shuffle-3" width="50" height="50" />
    </div>
  );

  const Error = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <Icon icon="carbon:error" width="50" height="50" />
      <h2 className="cs-recent_post_title ">Error</h2>
      <p style={{ margin: "0", padding: "0" }}>{error.message}</p>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        flexDirection: "column",
      }}
    >
      <h2 className="cs-recent_post_title" style={{}}>
        No Posts
      </h2>
      <Icon icon="bi:exclamation-triangle-fill" width="50" height="50" />
    </div>
  );

  //RENDER
  return (
    <PageTransition>
      <Spacing lg="140" md="80" />

      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : post ? (
        <SectionHeadingStyle3_Typed
          title={post ? post?.title : "..."}
          variant="text-center"
          shape="shape_5"
          category="Marketing"
          date={moment(post?.date).format("MMM DD, YYYY")}
          avatar={post ? post?.author : "Admin"}
          avatarLink="/"
        />
      ) : (
        <Empty />
      )}

      <Spacing lg="75" md="60" />
      <div className="container">
        <img
          src={post? post?.thumbnailSrc : "/images/blog/post_1.jpeg"}
          alt="Blog"
          className="cs_radius_15"
        />
        <div className="cs_height_60 cs_height_lg_40" />
        <div className="cs_post_details">
          {
            React.createElement("div", {
              dangerouslySetInnerHTML: createHTMLContent(post? post?.content : "<p>Content</p>")
            })
          }
        </div>

        <div className="cs_height_30 cs_height_lg_10" />
        <div className="cs_post_share">
          <div className="cs_categories">
            <a href="/" className="cs_category">
              Marketing
            </a>
            <a href="/" className="cs_category">
              Campaign
            </a>
          </div>
          <div className="text-center">
            <div className="cs_post_share_btns">
              <a href="/">
                <Icon icon="fa6-brands:x-twitter" />
              </a>
              <a href="/">
                <Icon icon="fa6-brands:facebook-f" />
              </a>
              <a href="/">
                <Icon icon="fa6-brands:linkedin-in" />
              </a>
            </div>
            <span className="cs_post_share_title">Share this</span>
          </div>
        </div>
        <div className="cs_height_50 cs_height_lg_40" />
        <AuthorWidget />
        <div className="cs_height_88 cs_height_lg_60" />
        <h2 className="text-center cs_fs_50 mb-0">Leave A Reply</h2>
        <div className="cs_height_60 cs_height_lg_30" />
        <form action="#" className="row">
          <div className="col-lg-6">
            <input
              type="text"
              className="cs_form_field_2"
              placeholder="What’s Your Name?"
            />
            <div className="cs_height_50 cs_height_lg_30" />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="cs_form_field_2"
              placeholder="What’s Your Email?"
            />
            <div className="cs_height_50 cs_height_lg_30" />
          </div>
          <div className="col-lg-12">
            <textarea
              cols={30}
              rows={7}
              className="cs_form_field_2"
              placeholder="Feel Free To Write Your Comment"
            />
            <div className="cs_height_60 cs_height_lg_30" />
          </div>
          <div className="col-lg-12 text-center">
            <button className="cs_btn cs_style_1">
              Post Comment{" "}
              <span>
                <i className="fa-solid fa-arrow-right" />
              </span>
            </button>
          </div>
        </form>
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </PageTransition>
  );
}

const TypedPageTitle = ({ title, titleClass, speed, repeat }) => {
  //CONSTANTS

  //STATE
  const [titleParts, setTitleParts] = React.useState(titleSplitter(title));

  //FUNCTIONS
  //--Split the title into a 3 word array for animation
  function titleSplitter(title) {
    //Split the title into words
    let words = title.split(" ");

    //Split the words into 3 parts
    let parts = [];
    for (let i = 0; i < words.length; i += 3) {
      parts.push(words.slice(i, i + 3).join(" "));
    }

    return parts;
  }

  //--construct the title animation sequence
  //With a delay of 750ms between each part
  //And a delay of 3000ms before repeating
  function constructSequence() {
    let sequence = [];
    titleParts.forEach((part, index) => {
      sequence.push(part);
      sequence.push(750);
      if (index === titleParts.length - 1) {
        sequence.push(3000);
      }
    });
    return sequence;
  }

  return (
    <TypeAnimation
      cursor={true}
      sequence={constructSequence()}
      wrapper="h1"
      speed={speed}
      repeat={repeat}
      className={titleClass}
    />
  );
};
