import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import AboutStyle4 from "../About/AboutStyle4";
import FunFact from "../FunFact";
import AboutStyle5 from "../About/AboutStyle5";
import IconBoxStyle6 from "../IconBox/IconBoxStyle6";
import SectionHeadingStyle5 from "../SectionHeading/SectionHeadingStyle5";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Marquee from "../Marquee";
import Brands from "../Brands";
import { pageTitle } from "../../helpers/PageTitle";
import PageTransition from "../Framer/PageTransitions";
import BrandsMarquee from "../Brands/BrandsMarquee";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";
import Card1 from "../Card";
import CardStyle2 from "../Card/CardStyle2";
const funfactData = [
  { title: "Happy Customers", number: "22k" },
  { title: "Work’s Completed", number: "15k" },
  { title: "Skilled Team Members", number: "121" },
  { title: "Most Valuable Awards", number: "15" },
];
const teamData = [
  {
    memberImg: "/images/studio-agency/team_1.jpeg",
    memberName: "James Berline",
    memberDesignation: "React Developer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_2.jpeg",
    memberName: "Bella Zubena",
    memberDesignation: "Graphic Designer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_3.jpeg",
    memberName: "Kemnei Alekzend",
    memberDesignation: "Digital Marketer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_4.jpeg",
    memberName: "Juliya Jesmine",
    memberDesignation: "UX Researcher",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_1.jpeg",
    memberName: "James Berline",
    memberDesignation: "React Developer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_2.jpeg",
    memberName: "Bella Zubena",
    memberDesignation: "Graphic Designer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_3.jpeg",
    memberName: "Kemnei Alekzend",
    memberDesignation: "Digital Marketer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_4.jpeg",
    memberName: "Juliya Jesmine",
    memberDesignation: "UX Researcher",
    href: "/team/team-details",
  },
];

const brandList = [
  {
    logoSrc: "/soimagine/partners/partner1.png",
    logoAlt: "AWS Partner Network",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner2.png",
    logoAlt: "Dell Technologies",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner3.png",
    logoAlt: "Open Suse",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner4.png",
    logoAlt: "Huawei",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner5.png",
    logoAlt: "Dahua Technology",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner6.png",
    logoAlt: "UBIBOT",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
];

const brandListDark = [
  {
    logoSrc: "/soimagine/partners/partner1.png",
    logoAlt: "AWS Partner Network",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner2.png",
    logoAlt: "Dell Technologies",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner3.png",
    logoAlt: "Open Suse",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner4.png",
    logoAlt: "Huawei",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner5.png",
    logoAlt: "Dahua Technology",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
  {
    logoSrc: "/soimagine/partners/partner6.png",
    logoAlt: "UBIBOT",
    imgStyle: {
      height: "auto",
      width: "150px",
    },
  },
];

export default function AboutPage({ darkMode }) {
  pageTitle("About");
  return (
    <PageTransition>
      <section>
        <Spacing lg="140" md="80" />
        <AboutStyle4
          thumbnailSrc="/soimagine/brand/software.jpg"
          miniTitle="Our Story began in 2018. Since then,
    we have grown into a vibrant,
    passionate, creative powerhouse
    team"
          title="Where It Began"
          subTitle="At SoImagine, we specialize in creating custom desktop, mobile, and web applications that
    streamline processes, enhance efficiency, and foster growth. Our expertise extends to the
    development of sophisticated artificial intelligence dashboarding and data tools that
    empower organizations with meaningful reports and accurate forecasting capabilities. By
    harnessing the power of technology and data-driven insights, we help our clients make
    informed decisions, optimize their operations, and ultimately achieve their business goals.
    As we continue to grow and evolve, our focus remains steadfast: to provide unparalleled IT
    solutions that drive success while fostering a culture of creativity, collaboration, and
    excellence within our team. At SoImagine, We believe that when imagination and
    technology come together, the possibilities are truly endless"
          btnText="See Our Services"
          btnUrl="/services"
        />

        <Spacing lg="125" md="70" />
      </section>

      <section className="cs_p76_full_width">
        <div className="container">
          <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
            Our partnerships and relationships are the key to our success
          </p>
          <Spacing lg="10" md="5" />
          <h4
            className="cs_section_title cs_fs_50 mb-0"
            style={{
              fontSize: "30px",
            }}
          >
            RELATIONSHIPS PARTNERSHIPS & ACCELERATORS
          </h4>
        </div>

        <Spacing lg="10" md="5" />
        <BrandsMarquee data={darkMode ? brandListDark : brandList} />
        <Spacing lg="135" md="80" />
      </section>

      <section className="cs_primary_bg">
        <div className="container">
          <SectionHeadingStyle2
            title="Imaginative solutions that drive <br />creativity & innovation."
            subTitle="Features"
            btnText="Learn More"
            btnUrl="/about"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-12">
              <Card1
                thumbnailSrc="https://api.soimagine.co.za/wp-content/uploads/2024/05/soimagine_a65d4c05-529d-45d6-acc9-2b6c8820566f.webp"
                title="At Soimagine, we pride ourselves on offering a dual advantage to our clients: exceptional services and innovative in-house developed products"
                miniTitle="What we Do"
              />
            </div>
            <div className="col-lg-6">
              <CardStyle2
                miniTitle="Our Team"
                title="Empowering businesses through tech innovation."
                subTitle="`Our team is made up of dedicated, passionate, and talented professionals who are committed to delivering the highest quality IT solutions to our clients"
                variant="cs_mission"
              />
            </div>
            <div className="col-lg-6">
              <CardStyle2
                miniTitle="Our Vision"
                title="Transforming businesses, shaping a smarter world."
                subTitle="Our vision is to be the leading provider of IT solutions that drive success for our clients."
                variant="cs_vision"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="OUR VALUES & CULTURE" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Innovation"
                  subTitle="We encourage curiosity, embrace change, and continuously strive to find new ways to solve problems and deliver exceptional solutions for our clients."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Collaboration"
                  subTitle="We believe that teamwork is the key to success. We cultivate an environment where open communication, diverse perspective, and mutual respect drive the collective growth of our team and clients"
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Customer-Centricity"
                  subTitle="We place our clients at the heart of everything we do, prioritizing their needs and going the extra mile to ensure their satisfaction and success."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

    </PageTransition>
  );
}

/*
Snap Section
*/
