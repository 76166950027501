import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

export default function AuthLayout() {

  const currentTheme = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = React.useState(currentTheme);

  React.useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);


  return (
    <div className="flex flex-col h-screen">
      <Header
        logoUrl="/soimagine/logo-white.png"
        colorVariant="cs_color_1"
        actionBtnText="Getting Started"
        actionBtnUrl="/contact-us"
      />
      <div className="flex flex-1">
        <Outlet />
      </div>
    </div>
  );
}
