import React from 'react';
import parse from 'html-react-parser';
import Button from '../Button';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function SectionHeadingStyle2({
  title,
  subTitle,
  btnText,
  btnUrl,
}) {
  return (
    <>
      <div className="cs_section_heading cs_style_1 cs_type_2">
        <div className="cs_section_heading_left">
          <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
            {subTitle}
          </p>
          <div className="cs_height_10 cs_height_lg_5" />
          <h2 className="cs_section_title cs_fs_50 mb-0 cs_white_color">
            {parse(title)}
          </h2>
        </div>
        <div className="cs_section_heading_right">
          <Button
            btnText={btnText}
            btnUrl={btnText}
            variantColor="cs_btn_white"
          />
        </div>
      </div>
    </>
  );
}


// Define the editable version of your component
// Replace Heading2 with your component's name
export const EditableHeading2 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}


      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
  } = useNode((node) => ({
    
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditableHeading2.craft = {
  displayName: 'Heading2',
  props: {
    
  },
  related: {
    settings: ComponentSettings,
  },
};
