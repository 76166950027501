import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';

import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle } from '@mui/material';
import { DialogActions, DialogContent } from '@mui/joy';
import _ from 'lodash';


function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

const NavigationItems = [
  {
    title: 'Overview',
    icon: 'eva:home-outline',
    link: '/dashboard/',
  },
  {
    title: 'Blocks',
    icon: 'eva:grid-outline',
    link: '/dashboard/blocks',
  },
  {
    title: 'Pages',
    icon: 'eva:file-text-outline',
    link: '/dashboard/pages',
  },
  {
    title: 'Blog',
    icon: 'eva:book-open-outline',
    link: '/dashboard/blog',
  },
  {
    title: 'Content',
    icon: 'eva:folder-outline',
    link: '/dashboard/content',
  },
  {
    title: 'Appearance',
    icon: 'eva:grid-outline',
    link: '/dashboard/appearance',
    nested: [
      {
        title: 'Menus',
        link: '/dashboard/menu',
      },
      {
        title: 'Footer',
        link: '/dashboard/footer',
      },
      {
        title: 'Customizer',
        link: '/dashboard/customizer',
      },
    ],
  },
  {
    title: 'Files',
    icon: 'eva:file-text-outline',
    link: '/dashboard/files',
  },
  {
    title: 'Team',
    icon: 'eva:people-outline',
    link: '/dashboard/team',
  },
];

export default function Sidebar() {

  //CONSTANTS
  const navigate = useNavigate();

  //CONTEXT

  //STATES
  //--app states
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState({});
  //--dialog states
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);

  //FUNCTIONS
  //--get the Current User from local storage
  function getCurrentUser() {
    //get the current user from local storage as a string
    const user_str = localStorage.getItem('currentUser');

    //parse the user string to an object
    const user = JSON.parse(user_str);

    //set the current user to the parsed object
    setCurrentUser(user);
  }
  //--Log out the current user
  function logOutCurrentUser() {
    //remove the current user from local storage
    localStorage.removeItem('currentUser');

    //replace the current nav stack with the Home page
    navigate('/',{
      replace: true
    });
  }

  //EFFECTS
  //--get the Current User from local storage
  React.useEffect(() => {
    getCurrentUser();
  }, []);

  //--get and set Settings from the user data
  

  //RENDER
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        //zIndex: 10000,
        height: '100vh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <img
          src="/soimagine/logos/appIcon.png"
          alt="SoImagine Systems"
          style={{ width: '32px', height: '32px' }}
        />
        <Typography level="title-md">SoImagine Systems</Typography>
      </Box>
      <Input size="sm"
       startDecorator={
          <Icon
            icon="eva:search-outline"
            width="24"
            height="24"
            style={{ color: 'var(--joy-palette-text-secondary)' }}
          />
        }
        placeholder="Search" />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {NavigationItems.map((item) => {

            if (!item.nested) {
              return (
                <ListItem key={item.title}>
                  <ListItemButton onClick={() => navigate(item.link)}>
                    <Icon
                      icon={item.icon}
                      width="24"
                      height="24"
                    />
                    <ListItemContent>
                      <Typography level="title-sm">{item.title}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              );
            }

            return (
              <ListItem nested>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton onClick={() => setOpen(!open)}>
                      <Icon
                        icon={item.icon}
                        width="24"
                        height="24"
                      />
                      <ListItemContent>
                        <Typography level="title-sm">{item.title}</Typography>
                      </ListItemContent>
                      <Icon
                        icon="eva:arrow-ios-downward-outline"
                        width="24"
                        height="24"
                      />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>

                    {item.nested.map((nestedItem) => (
                      <ListItem sx={{ mt: 0.5 }} key={nestedItem.title} onClick={() => navigate(nestedItem.link)}>
                        <ListItemButton>{nestedItem.title}</ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Toggler>
              </ListItem>
            )

            
          })}
        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton>
              <Icon
                icon="eva:shopping-cart-outline"
                width="24"
                height="24"
              />
              Support
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <Icon
                icon="eva:settings-2-outline"
                width="24"
                height="24"
              />
              Settings
            </ListItemButton>
          </ListItem>
        </List>

        <Card
          invertedColors
          variant="soft"
          color="warning"
          size="sm"
          sx={{ boxShadow: 'none' }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography level="title-sm">Used space</Typography>
            <IconButton size="sm">
              <Icon
                icon="eva:info-outline"
                width="24"
                height="24"
              />
            </IconButton>
          </Stack>
          <Typography level="body-xs">
            Your team has used 80% of your available space. Need more?
          </Typography>
          <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} />
          <Button size="sm" variant="solid">
            Upgrade plan
          </Button>
        </Card>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
          src={
            !_.isEmpty(currentUser)
            ?
            currentUser.profile.avatar
            :
            "https://cdn.iconscout.com/icon/free/png-256/avatar-380-456332.png"
          }
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{
            !_.isEmpty(currentUser)
            ?
            currentUser.profile.displayName
            :
            "..."
          }</Typography>
          <Typography level="body-xs">{
            !_.isEmpty(currentUser)
            ?
            currentUser.email
            :
            "..."
          
          }</Typography>
        </Box>
        <IconButton
          size="sm" 
          variant="plain" 
          color="neutral"
          onClick={() =>{
            setOpenLogoutDialog(true);
          }}
        >
          <Icon
            icon="eva:log-out-outline"
            width="24"
            height="24"
          />
        </IconButton>
      </Box>

      {/*Logout Confirmation Dialog*/}
      <Dialog open={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography level="title-md">Logout</Typography>
          <IconButton onClick={() => setOpenLogoutDialog(false)}>
            <Icon
              icon="eva:close-outline"
              width="24"
              height="24"
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}
        >
          <Typography level="body-md">Are you sure you want to logout?</Typography>
        </DialogContent>
        <Divider />
        
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            p: 2,
          }}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setOpenLogoutDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="danger"
            onClick={() => logOutCurrentUser()}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Sheet>
  );
}
