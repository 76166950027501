import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import PageTransition from "../Framer/PageTransitions";
import { 
  Card,
  CardContent,
  CardActions,
  Typography,
  Input,
  Button,
  Textarea,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  Divider,
} from "@mui/joy";

export default function ContactPage() {
  pageTitle("Contact");
  return (
    <PageTransition>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
                Come & visit <br />
                our place!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">hello@soimagine.co.za</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Phone:</h3>
                  {/* +27 (0) 11 083 5705
                  +27 (0) 76 831 3475 */}
                  <Typography
                    component="a"
                    href="tel:+27 (0) 11 083 5705"
                    level="title-md"
                    sx={{
                      '&:hover': {
                        color: '#f90000'
                      }
                    }}
                  >
                    +27 (0) 11 083 5705
                  </Typography>
                  <Typography
                    component="a"
                    href="tel:+27 (0) 76 831 3475"
                    className="cs_primary_color"
                    level="title-md"
                    sx={{
                      '&:hover': {
                        color: '#f90000'
                      }
                    }}
                    >
                    +27 (0) 76 831 3475
                    </Typography>
                    <Typography
                    component="a"
                    href="tel:+27 (0) 76 831 3475"
                    className="cs_primary_color"
                    level="title-md"
                    sx={{
                      '&:hover': {
                        color: '#f90000'
                      }
                    }}
                    >
                    +27(0) 11 083 5705
                    </Typography>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Address:</h3>
                  <p className="mb-0">
                    22 Magwa Crescent <br />
                    Gateway West <br />
                    Waterfall City <br />
                    Midrand <br />
                    Johannesburg
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
            <Card variant="soft"
                  sx={{
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                  }}
                  >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Contact Us
                    </Typography>
                    <Divider />
                    <Spacing mb="20" />
                    <Input
                      label="Full Name"
                      placeholder="Type your name"
                      fullWidth
                    />
                    <Spacing mb="20" />
                    <Input
                      label="Email Address"
                      placeholder="Type your email address"
                      fullWidth
                    />
                    <Spacing mb="20" />
                    <Input
                      label="Phone Number"
                      placeholder="Type your phone number"
                      fullWidth
                    />
                    <Spacing mb="20" />
                    <Textarea
                      label="Project Brief"
                      placeholder="Type your message here"
                      fullWidth
                    />
                  </CardContent>
                  </Card>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
      <div className="cs_map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.584185113049!2d28.105906974250004!3d-26.014435256270065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95734b5c6e5d2d%3A0xb3d8c5c6b3b0b415!2sSpaces%20-%20Johannesburg%2C%20Waterfall%2C%20Midrand!5e0!3m2!1sen!2sza!4v1714549337385!5m2!1sen!2sza" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Soimagine Offices"></iframe>
      </div>
    </PageTransition>
  );
}
