import { Route, Routes, useLocation } from "react-router-dom";

import Layout from "../components/Layout";
import DigitalAgencyPage from "../components/Pages/DigitalAgencyPage";
import ServicePage from "../components/Pages/ServicePage";
import AboutPage from "../components/Pages/AboutPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogListPage from "../components/Pages/BlogListPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import PortfolioPage from "../components/Pages/PortfolioPage";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
import CaseStudyDetailsPage from "../components/Pages/CaseStudyDetailsPage";
import TeamPage from "../components/Pages/TeamPage";
import TeamDetailsPage from "../components/Pages/TeamDetailsPage";
import ContactPage from "../components/Pages/ContactPage";
import ServiceDetailsPage from "../components/Pages/ServiceDetailsPage";
import Shop from "../components/Pages/Shop";
import ProductDetails from "../components/Pages/Shop/ProductDetails";
import Cart from "../components/Pages/Shop/Cart";
import Checkout from "../components/Pages/Shop/Checkout";
import Success from "../components/Pages/Shop/Success";
import Wishlist from "../components/Pages/Shop/Wishlist";
import ErrorPage from "../components/Pages/ErrorPage";
//Auth
import AuthLayout from "../auth/layout";
import Login from "../auth/pages/Login";
//Dashboard
import DashboardLayout from "../dashboard/layout/DashboardLayout";
import DashboardOverview from "../dashboard/pages/overview";
//Global Context
import { useContext, useEffect, useState } from "react";
import AppGlobalsContext from "../contexts/AppGlobalsContext";
import PreLoader from "../components/Misc/Preloader";
import PagesLayout from "src/dashboard/modules/pages/pagesLayout";
import PagesList from "src/dashboard/modules/pages/pagesList";
import PagesAdd from "src/dashboard/modules/pages/pagesAdd";
import ContentLayout from "src/dashboard/modules/content/ContentLayout";
import ContentEditor from "src/dashboard/modules/content/ContentEditor";
import MenuLayout from "src/dashboard/modules/menu/MenuLayout";
import MenuList from "src/dashboard/modules/menu/MenuList";
import MenuEditor from "src/dashboard/modules/menu/MenuEditor";
import FooterLayout from "src/dashboard/modules/footer/FooterLayout";
import FooterList from "src/dashboard/modules/footer/FooterList";
import FooterEditor from "src/dashboard/modules/footer/FooterEditor";
import BlogLayout from "src/dashboard/modules/blog/BlogLayout";
import BlogList from "src/dashboard/modules/blog/BlogList";
import BlogEditor from "src/dashboard/modules/blog/BlogEditor";
import FileLayout from "src/dashboard/modules/file/FileLayout";
import FileList from "src/dashboard/modules/file/FileList";
import FileEditor from "src/dashboard/modules/file/FileEditor";
import CustomizeLayout from "src/dashboard/modules/customize/CustomizeLayout";
import CustomizeEditor from "src/dashboard/modules/customize/CustomizeEdit";
import MenuDesigner from "src/dashboard/modules/menu/MenuDesigner";
import ContentItems from "src/dashboard/modules/content/ContentItems";
import BlocksLayout from "src/dashboard/modules/blocks/BlockLayout";
import BlocksList from "src/dashboard/modules/blocks/BlockList";
import Editor from "src/dashboard/modules/pages/designer/editor";
import { AnimatePresence } from "framer-motion";
import ContentList from "src/dashboard/modules/content/ContentList";
import ContentAdd from "src/dashboard/modules/content/ContentAdd";
import Register from "src/auth/pages/Register";
import ProductsPage from "src/components/Pages/Projects";
import ProjectDetailsPage from "src/components/Pages/Projects/ProjectDetails";

function WebsiteRoutes() {
  //CONTEXTS
  const { theme, setTheme, language, setLanguage, user, setUser } =
    useContext(AppGlobalsContext);

  //HOOKS
  const location = useLocation();

  //STATE
  const [mounted, setMounted] = useState(false);

  //EFFECTS
  useEffect(() => {
    setMounted(true);
  }, []);

  /* if (!mounted) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <PreLoader />
      </div>
    );
  } */

  return (
    //wrap the routes with "AnimatePresence" to enable page transitions
    <AnimatePresence mode="popLayout">
      {/* Root Layout - <App />
      --contains the context providers and Outlet for nested routes
      */}
      <Routes>
        <Route path="/" element={<Layout darkMode />}>
          <Route index element={<DigitalAgencyPage />} />

          <Route path="services">
            <Route index element={<ServicePage />} />
            <Route
              path="service-details/:serviceId"
              element={<ServiceDetailsPage />}
            />
          </Route>

          <Route path="about" element={<AboutPage />} />

          <Route path="articles">
            <Route index element={<BlogListPage />} />
            <Route path=":postId" element={<BlogDetailsPage />} />
          </Route>

          <Route path="portfolio">
            <Route index element={<PortfolioPage />} />
            <Route path=":postId" element={<PortfolioDetailsPage />} />
          </Route>

          <Route path="project">
            <Route index element={<ProductsPage />} />
            <Route path=":postId" element={<ProjectDetailsPage />} />
          </Route>

          <Route path="case-studies"></Route>

          <Route path="shop">
            <Route index element={<Shop />} />
            <Route path="shop/:productId" element={<ProductDetails />} />
            <Route path="cart" element={<Cart />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="payment-gateway" element={<></>} />
            <Route path="success" element={<Success />} />
            <Route path="wishlist" element={<Wishlist />} />
          </Route>
        </Route>

        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamId" element={<TeamDetailsPage />} />

        <Route path="contact-us" element={<ContactPage />} />

        <Route path="auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>

        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardOverview />} />

          <Route path="pages" element={<PagesLayout />}>
            <Route index element={<PagesList />} />

            <Route path="add" element={<PagesAdd />} />
            <Route path="edit/:pageId" element={<Editor />} />
          </Route>

          <Route path="content" element={<ContentLayout />}>
            <Route index element={<ContentItems />} />

            <Route
              path="edit/:contentType/:contentId"
              element={<ContentEditor />}
            />

            <Route path="list/:contentType">
              <Route index element={<ContentList />} />
            </Route>

            <Route path="add/:contentType">
              <Route index element={<ContentAdd />} />
            </Route>
          </Route>

          <Route path="menu" element={<MenuLayout />}>
            <Route index element={<MenuList />} />
            <Route path="edit/:menuId" element={<MenuEditor />} />
            <Route path="designer/:menuId" element={<MenuDesigner />} />
          </Route>

          <Route path="footer" element={<FooterLayout />}>
            <Route index element={<FooterList />} />
            <Route path="edit/:footerId" element={<FooterEditor />} />
          </Route>

          <Route path="blog" element={<BlogLayout />}>
            <Route index element={<BlogList />} />
            <Route path="edit/:postId" element={<BlogEditor />} />
          </Route>

          <Route path="blocks" element={<BlocksLayout />}>
            <Route index element={<BlocksList />} />
          </Route>
          <Route path="file" element={<FileLayout />}>
            <Route index element={<FileList />} />
            <Route path="edit/:fileId" element={<FileEditor />} />
          </Route>
          <Route path="customize" element={<CustomizeLayout />}>
            <Route index element={<CustomizeEditor />} />
          </Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
}

/* Generate Routes From CMS data */
const CMSRoutes = () => {
  //CONSTANTS

  //CONTEXTS

  //STATES

  //HOOKS

  //FUNCTIONS
  //--using the list of pages, create a route for each page
  const generateRoutes = () => {};

  //EFFECTS

  //RENDER
  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default WebsiteRoutes;
