import React, { useEffect, useState } from 'react';
import VideoModal from '../VideoModal';
import TextTransition, { presets } from 'react-text-transition';
import { RotateLogo } from '../reactThree/RotateLogo';
import AnimatedBackground from '../reactThree/BgScene1';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function Hero({ title, subtitle, videoSrc, bgUrl }) {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(prevIndex => prevIndex + 1),
      3000, // every 3 seconds
    );

    return () => clearInterval(intervalId); // Use clearInterval here
  }, []);
  return (
    <section className="cs_hero cs_style_1 cs_primary_bg position-relative">
      <AnimatedBackground>
        
      </AnimatedBackground>

      <div className="cs_video_block_wrap position-relative">
        <div className="container">
          <VideoModal videoSrc={videoSrc} bgUrl={bgUrl} />
        </div>
      </div>
    </section>
  );
}


// Define the editable version of your component
// Replace Hero1 with your component's name
export const EditableHero1 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        //padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}

      <Hero {...props} />
      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    title,
    subtitle,
    videoSrc,
    bgUrl,
  } = useNode((node) => ({
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    videoSrc: node.data.props.videoSrc,
    bgUrl: node.data.props.bgUrl,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >

        <Input
            label="Title"
            value={title}
            onChange={(e) => setProp((props) => (props.title = e.target.value))}
          />
          <Input
            label="Subtitle"
            value={subtitle}
            onChange={(e) => setProp((props) => (props.subtitle = e.target.value))}
          />
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Media</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FilePickerOpener
              label="Background Image"
              onSelected={(file) =>
                setProp((props) => (props.bgUrl = file.url))
              }
            />

            <FilePickerOpener
              label="Video Source"
              onSelected={(file) =>
                setProp((props) => (props.videoSrc = file.url))
              }
            />

          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditableHero1.craft = {
  displayName: 'Hero1',
  props: {
    title: 'Hero Title',
    subtitle: 'Hero Subtitle',
    videoSrc: 'https://www.youtube.com/watch?v=6v2L2UGZJAM',
    bgUrl: 'https://images.unsplash.com/photo-1623863980916-3f5a2b0b2d8c',
  },
  related: {
    settings: ComponentSettings,
  },
};