/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState } from "react";
//react-three-fiber
import { Canvas, useFrame } from "@react-three/fiber";
//react-three-drei
import {
  //controls
  PresentationControls,
  //staging
  Center,
  Float,
  Stage,
  //hooks
  useGLTF,
  useAnimations,
} from "@react-three/drei";


export function EveBot_charecter({ onAnimate, modelPath = "/soimagine/Eve.glb", ...props }) {

  //PROPS
  const { position, scale } = props;

  //CONSTANTS
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(modelPath);
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    if (onAnimate && actions[onAnimate]) {
      actions[onAnimate].reset().fadeIn(0.5).play();
      return () => {
        if (actions[onAnimate]) {
          actions[onAnimate].fadeOut(0.5);
        }
      };
    } else if (onAnimate) {
      console.warn(`Animation "${onAnimate}" not found.`);
    }
  }, [onAnimate, actions]);

  //EFFECTS
  //These is
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Body"
          castShadow
          receiveShadow
          geometry={nodes.Body.geometry}
          material={materials["Material.001"]}
          position={position ? position : [0, 0, 5]}
          scale={scale ? scale : [1, 1, 1]}
          userData={{ name: "Body" }}
        >
          <group
            name="Head"
            position={[0, 1.095, -0.001]}
            scale={[0.919, 0.768, 0.919]}
            userData={{ name: "Head" }}
          >
            <mesh
              name="Sphere001"
              castShadow
              receiveShadow
              geometry={nodes.Sphere001.geometry}
              material={materials["Material.001"]}
            />
            <mesh
              name="Sphere001_1"
              castShadow
              receiveShadow
              geometry={nodes.Sphere001_1.geometry}
              material={materials["Material.002"]}
            />
            <mesh
              name="Sphere001_2"
              castShadow
              receiveShadow
              geometry={nodes.Sphere001_2.geometry}
              material={materials["Material.003"]}
            />
            <mesh
              name="L_eye"
              castShadow
              receiveShadow
              geometry={nodes.L_eye.geometry}
              material={materials["Material.004"]}
              morphTargetDictionary={nodes.L_eye.morphTargetDictionary}
              morphTargetInfluences={nodes.L_eye.morphTargetInfluences}
              position={[0.001, 1.037, 1.294]}
              rotation={[1.57, 0, 0]}
              scale={[0.933, 0.933, 0.804]}
              userData={{
                targetNames: [
                  "Wide_open",
                  "Grin",
                  "Blink",
                  "Sad",
                  "Sad_2",
                  "Mad",
                  "Mad_2",
                  "Mad_3",
                  "Uninterested",
                  "Surprised",
                ],
                name: "L_eye",
              }}
            />
            <mesh
              name="R_eye"
              castShadow
              receiveShadow
              geometry={nodes.R_eye.geometry}
              material={materials["Material.004"]}
              morphTargetDictionary={nodes.R_eye.morphTargetDictionary}
              morphTargetInfluences={nodes.R_eye.morphTargetInfluences}
              position={[0.001, 1.037, 1.294]}
              rotation={[-1.571, 0, 0]}
              scale={[-0.933, -0.933, -0.804]}
              userData={{
                targetNames: [
                  "Wide_open",
                  "Grin",
                  "Blink",
                  "Sad",
                  "Sad_2",
                  "Mad",
                  "Mad_2",
                  "Mad_3",
                  "Uninterested",
                  "Surprised",
                ],
                name: "R_eye",
              }}
            />
          </group>
          <mesh
            name="L_Arm"
            castShadow
            receiveShadow
            geometry={nodes.L_Arm.geometry}
            material={materials["Material.001"]}
            position={[1.049, 1.012, -0.01]}
            userData={{ name: "L_Arm" }}
          />
          <mesh
            name="R_Arm"
            castShadow
            receiveShadow
            geometry={nodes.R_Arm.geometry}
            material={materials["Material.001"]}
            position={[-1.052, 1.029, -0.001]}
            userData={{ name: "R_Arm" }}
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("/soimagine/Eve.glb");
