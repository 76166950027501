import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import WebsiteRoutes from './routes/WebsiteRoutes';

// <Route element={<Layout darkMode />}>
//         <Route
//           path=""
//           element={<DigitalAgencyPage darkMode />}
//           key={location.pathname}
//         />

//         <Route
//           path="about"
//           element={<AboutPage darkMode />}
//           key={location.pathname}
//         />

//         <Route
//           path="service"
//           element={<ServicePage />}
//           key={location.pathname}
//         />

//         <Route
//           path="service/:serviceDetailsId"
//           element={<ServiceDetailsPage />}
//         />
//         <Route path="blog" element={<BlogPage />} key={location.pathname} />

//         <Route
//           path="blog-list"
//           element={<BlogListPage />}
//           key={location.pathname}
//         />
//         <Route
//           path="blog/:blogDetailsId"
//           element={<BlogDetailsPage />}
//           key={location.pathname}
//         />

//         <Route
//           path="portfolio"
//           element={<PortfolioPage />}
//           key={location.pathname}
//         />
//         <Route
//           path="portfolio/:portfolioDetailsId"
//           element={<PortfolioDetailsPage />}
//           key={location.pathname}
//         />

//         <Route path="shop" element={<Shop />} key={location.pathname} />
//         <Route
//           path="shop/:productId"
//           element={<ProductDetails />}
//           key={location.pathname}
//         />
//         <Route path="shop/cart" element={<Cart />} key={location.pathname} />
//         <Route
//           path="shop/checkout"
//           element={<Checkout />}
//           key={location.pathname}
//         />
//         <Route
//           path="shop/success"
//           element={<Success />}
//           key={location.pathname}
//         />
//         <Route
//           path="shop/wishlist"
//           element={<Wishlist />}
//           key={location.pathname}
//         />

//         <Route
//           path="case-study-details"
//           element={<CaseStudyDetailsPage />}
//           key={location.pathname}
//         />

//         <Route path="team" element={<TeamPage />} key={location.pathname} />

//         <Route
//           path="team/:teamDetailsId"
//           element={<TeamDetailsPage />}
//           key={location.pathname}
//         />

//         <Route
//           path="contact-us"
//           element={<ContactPage />}
//           key={location.pathname}
//         />
//       </Route>

//       {/* Auth Routes */}
//       <Route path="auth" element={<AuthLayout />}>
//         <Route path="login" element={<Login />} />
//       </Route>

//       {/*Dashboard Routes */}
//       <Route path="dashboard" element={<DashboardLayout />}>
//         {/* Dashboard Overview */}
//         <Route index element={<DashboardOverview />} />

//         {/* Pages */}
//         <Route path="pages" element={<PagesLayout />}>
//           <Route index element={<PagesList />} />
//           <Route path="add" element={<PagesAdd />} />
//           <Route path="edit/:pageId" element={<Editor />} />
//         </Route>

//         {/*Blocks*/}
//         <Route path="blocks" element={<BlocksLayout />}>
//           <Route index element={<BlocksList />} />
//         </Route>

//         {/* Blog */}
//         <Route path="blog" element={<BlogLayout />}>
//           <Route index element={<BlogList />} />
//           <Route path="edit/:pageId" element={<BlogEditor />} />
//         </Route>

//         {/* Content */}
//         <Route path="content" element={<ContentLayout />}>
//           <Route index element={<ContentItems />} />
//           <Route path="content-list/:contentType" element={<ContentEditor />} />
//           <Route path="edit/:pageId" element={<ContentEditor />} />
//         </Route>

//         {/*Appearence - Menu*/}
//         <Route path="menu" element={<MenuLayout />}>
//           <Route index element={<MenuList />} />
//           <Route path="edit/:menuId" element={<MenuEditor />} />
//           <Route path="designer/:menuId" element={<MenuDesigner />} />
//         </Route>

//         {/*Appearence - Footer*/}
//         <Route path="footer" element={<FooterLayout />}>
//           <Route index element={<FooterList />} />
//           <Route path="edit/:pageId" element={<FooterEditor />} />
//         </Route>

//         {/*Appearence - Customize*/}
//         <Route path="customizer" element={<CustomizeLayout />}>
//           <Route index element={<CustomizeEditor />} />
//         </Route>

//         {/*Files*/}
//         <Route path="files" element={<FileLayout />}>
//           <Route index element={<FileList />} />
//           <Route path="edit/:pageId" element={<FileEditor />} />
//         </Route>
//       </Route>

//       <Route path="*" element={<ErrorPage />} />
const customRouter = createBrowserRouter([
  {
    path: '*',
    element: <App />,
    children: createRoutesFromElements(WebsiteRoutes),
  },
]
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={customRouter} />,
);