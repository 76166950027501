/*This File Defines All the API Requests for the Portfolio Endpoint. This Includes GET, POST, PUT, DELETE Requests.
The Endpoints have Been Defined As Wordpress custom REST API Endpoints.
the rest routes are defined in the wordpress plugin soimagine-rest-api
*/

/*
namespace: soimagine/portfolio
- get-all : get all portfolio
- get-single : get single article
- create : create new article
- update : update existing article
- delete : delete existing article
*/

import api from "./index";

/**
 * @typedef {Object} Portfolio
 * @property {number} id - The Portfolio ID
 * @property {string} title - The Portfolio Title
 * @property {string} content - The Portfolio Content
 * @property {string} excerpt - The Portfolio Excerpt
 * @property {string} date - The Portfolio Date
 * @property {string} author - The Portfolio Author
 * @property {string} featured_image - The Portfolio Featured Image
 * @property {Array} categories - The Portfolio Categories
 * @property {Array} tags - The Portfolio Tags
 */

/*GET - retrieves all portfolio
 * @param {number} page - The Page Number
 * @typedef {Object} Portfolio || id title content excerpt date author featured_image categories tags
 * @returns {Promise<Portfolio[]>} - A Promise that Resolves to an Array of Portfolio
 */
export const getPortfolio = async (params) => {
  api.post("soimagine/portfolio/get-all", {
      page: params ? params.page : 1,
      category: params ? params.category : "",
      tag: params ? params.tag : "",
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

/*GET - retrieves a single article
 * @param {number} id - The Portfolio ID
 * @typedef {Object} Portfolio || id title content excerpt date author featured_image categories tags
 * @returns {Promise<Portfolio>} - A Promise that Resolves to an Portfolio
 */
export const getSinglePortfolio = async (params) => {
  api
    .post("soimagine/portfolio/get-single", {
      id: params ? params.id : "",
      slug: params ? params.slug : "",
      title: params ? params.title : "",
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

/*POST - creates a new article
 * @param {Portfolio} article - The Portfolio Object
 * @typedef {Object} Portfolio || id title content excerpt date author featured_image categories tags
 * @returns {Promise<Portfolio>} - A Promise that Resolves to the New Portfolio
 */
export const createPortfolio = (article) => {
  api
    .post("soimagine/portfolio/create", article)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

/*POST - deletes an article
 * @param {number} id - The Portfolio ID
 * @param {Portfolio} article - The Portfolio Object
 * @typedef {Object} Portfolio || id title content excerpt date author featured_image categories tags
 * @returns {Promise<Portfolio>} - A Promise that Resolves to the Updated Portfolio
 */
export const updatePortfolio = (article) => {
  api
    .post(`soimagine/portfolio/update`, article)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

/*POST - deletes an article
 * @param {number} id - The Portfolio ID
 * @typedef {Object} Portfolio || id title content excerpt date author featured_image categories tags
 * @returns {Promise<Portfolio>} - A Promise that Resolves to the Deleted Portfolio
 */
export const deletePortfolio = (article) => {
  api
    .post(`soimagine/portfolio/delete`, article)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
