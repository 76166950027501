import * as React from "react";
import Box from "@mui/joy/Box";

import { Outlet } from "react-router-dom";

import { Editor } from "@craftjs/core";
/* CRAFT-JS EDITOR COMPONENTS */
//--VIEWPORT
import EditorViewport from "./designer/editorComponents/EditorViewport";

//--CONTAINERS

//--STANDALONE COMPONENTS
import Text from "./designer/editorComponents/Text";

//--SECTION COMPONENTS
//--ABOUT
import { EditableAboutStyle2 } from "src/components/About/AboutStyle2";
import { EditableAboutStyle3 } from "src/components/About/AboutStyle3";
import { EditableAboutStyle4 } from "src/components/About/AboutStyle4";
//HERO
import { EditableHero1 } from "src/components/Hero/";
import { EditableHero2 } from "src/components/Hero/HeroStyle2";
import { EditableHero3 } from "src/components/Hero/HeroStyle3";
import { EditableHero4 } from "src/components/Hero/HeroStyle4";
import { EditableHero5 } from "src/components/Hero/HeroStyle5";
//Slider 
import { EditableCarousel1 } from "src/components/Slider/BrandCarousel";
import { EditableSlider1 } from "src/components/Slider/PortfolioSlider";
import { EditableSlider2 } from "src/components/Slider/PostCarousel";
import { EditableSlider3 } from "src/components/Slider/ServiceSlider";
import { EditableSlider4 } from "src/components/Slider/TeamSlider";
import { EditableSlider5 } from "src/components/Slider/TestimonialSlider";
import { EditableSlider6 } from "src/components/Slider/TestimonialSliderStyle2";
import { EditorProvider } from "./designer/context/EditorContext";
import { RenderNode } from "./designer/editorComponents/RenderNode";

export default function PagesLayout() {
  const currentTheme = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = React.useState(currentTheme);

  React.useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <EditorProvider>
    <Editor
      resolver={{
        /*VIEWPORT */
        EditorViewport,

        /* CONTAINERS */

        /* STANDALONE COMPONENTS */
        Text,

        /* SECTION COMPONENTS */
        //--about
        EditableAboutStyle2,
        EditableAboutStyle3,
        EditableAboutStyle4,
        //--hero
        EditableHero1,
        EditableHero2,
        EditableHero3,
        EditableHero4,
        EditableHero5,
        //--slider
        EditableCarousel1,
        EditableSlider1,
        EditableSlider2,
        EditableSlider3,
        EditableSlider4,
        EditableSlider5,
        EditableSlider6,
      }}
      onRender={RenderNode}
    >
      
      <Outlet />
    </Editor>
    </EditorProvider>
    
  );
}