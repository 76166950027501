import React from 'react';
import { Link } from 'react-router-dom';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton, Card, CardContent, CardActions, Stack } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function ServiceStyle2({ data, variantColor }) {
  return (
    <ul
      className={`cs_image_box_1_list ${
        variantColor ? variantColor : ''
      } cs_mp0`}
    >
      {data?.map((item, index) => (
        <li key={index}>
          <div className="cs_image_box cs_style_1">
            <div className="cs_image_box_number cs_primary_color cs_primary_font cs_fs_38 cs_semibold">
              {item.number}
            </div>
            <Link
              to={item.href}
              state={{ service: item }}
              className="cs_image_box_img cs_radius_15 overflow-hidden"
            >
              <img src={item.content.media[0]} alt="Service" />
            </Link>
            <div className="cs_image_box_info position-relative">
              <h2 className="cs_image_box_title cs_fs_29 cs_semibold">
                <Link to={item.href} state={{ service: item }}>
                  {item.title}
                </Link>
              </h2>
              <p className="cs_image_box_subtitle mb-0">{item.subTitle}</p>
              <Link
                to={item.href}
                state={{ service: item }}
                className="cs_image_box_btn cs_center position-absolute rounded-circle"
              >
                <svg
                  width={30}
                  height={29}
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.3803 3.05172C29.4089 1.94752 28.537 1.02921 27.4328 1.00062L9.43879 0.534749C8.33459 0.506159 7.41628 1.37811 7.38769 2.48231C7.35911 3.58651 8.23106 4.50482 9.33526 4.53341L25.3299 4.94752L24.9158 20.9422C24.8872 22.0464 25.7592 22.9647 26.8634 22.9933C27.9676 23.0218 28.8859 22.1499 28.9144 21.0457L29.3803 3.05172ZM3.37714 28.5502L28.7581 4.4503L26.0039 1.54961L0.622863 25.6495L3.37714 28.5502Z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  width={30}
                  height={29}
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.3803 3.05172C29.4089 1.94752 28.537 1.02921 27.4328 1.00062L9.43879 0.534749C8.33459 0.506159 7.41628 1.37811 7.38769 2.48231C7.35911 3.58651 8.23106 4.50482 9.33526 4.53341L25.3299 4.94752L24.9158 20.9422C24.8872 22.0464 25.7592 22.9647 26.8634 22.9933C27.9676 23.0218 28.8859 22.1499 28.9144 21.0457L29.3803 3.05172ZM3.37714 28.5502L28.7581 4.4503L26.0039 1.54961L0.622863 25.6495L3.37714 28.5502Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}


// Define the editable version of your component
// Replace Service2 with your component's name
export const EditableService2 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}


      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
// This is where you can add UI elements for editing component properties
const _ItemForm = ({ item, index, onChange, onRemove }) => {
  return (
    <Card key={index} sx={{ margin: 1 }}>
      <CardContent>
        <Stack spacing={2}>
          <Input
            label="Brand"
            value={item.brand}
            onChange={(e) => onChange('brand', e.target.value)}
          />

          <Input
            label="Title"
            value={item.title}
            onChange={(e) => onChange('title', e.target.value)}
          />

          <Input
            label="Sub Title"
            value={item.subTitle}
            onChange={(e) => onChange('subTitle', e.target.value)}
          />

          <Input
            type="date"
            label="Date"
            value={item.date}
            onChange={(e) => onChange('date', e.target.value)}
          />

          <FilePickerOpener
          onSelected={(file) => {
            onChange('awardImgUrl', file.url);
          }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <JButton size="sm" color="error" onClick={onRemove}>
          Remove
        </JButton>
      </CardActions>
    </Card>
  );
};

const _ItemRepeater = ({ items, onChange }) => {
  const addItem = () => {
    onChange([...items, { title: '', url: '' }]);
  };

  const removeItem = (index) => {
    onChange(items.filter((item, i) => i !== index));
  };

  const updateItem = (index, key, value) => {
    onChange(
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }

        return item;
      }),
    );
  };

  return (
    <Stack>
      {items.map((item, index) => (
        <_ItemForm
          key={index}
          item={item}
          index={index}
          onChange={(key, value) => updateItem(index, key, value)}
          onRemove={() => removeItem(index)}
        />
      ))}

      <JButton
        size="sm"
        color="primary"
        onClick={addItem}
      >
        Add Item
      </JButton>
    </Stack>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    data
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <_ItemRepeater
            items={data}
            onChange={(value) => setProp((props) => (props.data = value))}
          /> 
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// Define CraftJS specific properties for your component
EditableService2.craft = {
  displayName: 'Service2',
  props: {
    
  },
  related: {
    settings: ComponentSettings,
  },
};
