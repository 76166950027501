import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { Icon } from "@iconify/react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import { useFormik } from "formik";
import * as yup from "yup";
import FileUploader from "src/dashboard/components/FileUploader";

/*COMPONENT*/
//--pagesList | default
export default function PagesAdd() {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      status: "draft",
      visibility: "public",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
    }),
    onSubmit: (values) => {
      createNewPage(values);
    },
  });

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);

  //FUNCTIONS
  //--create new page
  //--use react-query to send a POST request to the server
  const createNewPage = (values) => {
    setIsBusy(true);
    console.log("Creating Page", values);
    setTimeout(() => {
      setIsBusy(false);
      console.log("Page Created", values);
    }, 2000);
  };
  
  

  //RETURN
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* Breadcrumbs / Actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            Pages
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create
          </Typography>
        </Breadcrumbs>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            color="primary"
            startDecorator={
              <Icon
                icon="carbon:document-add"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
            onClick={formik.handleSubmit}
            disabled={isBusy || !formik.isValid}
          >
            Create Page
          </Button>

          <Button
            color="info"
            startDecorator={
              <Icon
                icon="mdi:cancel"
                style={{ fontSize: "16px", color: "#fff" }}
              />
            }
            size="sm"
          >
            Cancel
          </Button>
        </Box>
      </Box>

      {/* Page Title */}
      {/* <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      > 
      </Box> */}

      {/* Page Content */}
      <Grid container spacing={2}>
        {/* Column 1 */}
        <Grid item xs={12} md={8}>
          <BasicInfoForm
            onFormValid={(values) => console.log("Form Valid", values)}
            onFormInvalid={() => console.log("Form Invalid")}
          />
        </Grid>

        {/* Column 2 */}
        <Grid item xs={12} md={4}>

          {/* Publish Settings */}
          <PublishSettingsForm />

          {/* Featured Image */}
          <FileUploader
            label="Featured Image"
            accept="image/*"
            multiple={false}
            maxFiles={1}
            onUpload={(files) => console.log("Uploaded Files", files)}
          />
        </Grid>
      </Grid>

      {/* Page Actions */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          gap: 1,
        }}
      >
        <Button
          color="primary"
          startDecorator={
            <Icon icon="carbon:document-add" style={{ fontSize: "16px" }} />
          }
          size="sm"
          onClick={formik.handleSubmit}
          disabled={isBusy || !formik.isValid}
        >
          Create Page
        </Button>
      </Box>
    </Box>
  );
}

//--Page Basic Info
function BasicInfoForm({ onFormValid, onFormInvalid }) {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  //EFFECTS
  //--This will be called when the Form is valid. it will push an object with the form values to the parent component
  React.useEffect(() => {
    if (formik.isValid) {
      //push form values to parent component
      onFormValid(formik.values);
    } else {
      onFormInvalid();
    }
  }, [formik.isValid]);

  //RETURN
  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "max-content",
        maxWidth: "100%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "horizontal",
      }}
    >
      <Typography
        level="title-lg"
        startDecorator={<Icon icon="mdi:file-document-edit" />}
      >
        Basic Information
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>Title</FormLabel>
          <Input
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </FormControl>

        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>Description</FormLabel>
          <Textarea
            //formik props
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            //component props
            rows={4}
            multiline
          />
        </FormControl>
      </CardContent>
    </Card>
  );
}

//--Page Publish Settings
//--for publishing, Page Status, Visibility, etc.
function PublishSettingsForm() {
  //FORMIK
  const formik = useFormik({
    initialValues: {
      status: "draft",
      visibility: "public",
    },
    validationSchema: yup.object({
      status: yup.string().required("Status is required"),
      visibility: yup.string().required("Visibility is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  //STATES
  const [isBusy, setIsBusy] = React.useState(false);

  //FUNCTIONS

  //RETURN
  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "max-content",
        maxWidth: "100%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "horizontal",
      }}
    >
      <Typography
        level="title-lg"
        startDecorator={<Icon icon="mdi:file-document-edit" />}
      >
        Publish Settings
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Status</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              //--component props
              endDecorator={
                formik.values.status === "published" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="published">Published</Option>
              <Option value="draft">Draft</Option>
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl>
            <FormLabel>Visibility</FormLabel>
          </FormControl>
          <FormControl>
            <Select
              //--formik props
              name="visibility"
              value={formik.values.visibility}
              onChange={formik.handleChange}
              error={
                formik.touched.visibility && Boolean(formik.errors.visibility)
              }
              helperText={formik.touched.visibility && formik.errors.visibility}
              //--component props
              endDecorator={
                formik.values.visibility === "public" ? (
                  <Icon icon="mdi:eye" />
                ) : (
                  <Icon icon="mdi:eye-off" />
                )
              }
            >
              <Option value="public">Public</Option>
              <Option value="private">Private</Option>
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
}
