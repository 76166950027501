import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

const svgVariants = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const PreLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading time for demonstration
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);

  if (!isLoading) return null;

  return (
    <motion.div
      className="d-flex justify-content-center align-items-center vh-100"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div variants={svgVariants}>
        <svg
          width="162.49132mm"
          height="101.58249mm"
          viewBox="0 0 162.49132 101.58249"
          version="1.1"
          id="svg1"
        >
          <defs id="defs1" />
          <g id="layer1" transform="translate(-23.130975,-98.276215)">
            <path
              style={{
                display: "inline",
                fill: "#010101",
                stroke: "#010101",
                strokeWidth: 0.5,
                strokeDasharray: "none",
                strokeOpacity: 1,
              
              }}
              d="m 132.39399,197.37198 c -0.60823,-1.74192 -0.082,-3.93672 -0.25818,-5.8511 l -0.52192,-37.16466 -10.66083,0.011 15.93722,-30.55155 c 5.65464,10.22669 8.99085,20.17111 14.27462,30.58722 l -9.30712,-0.0284 0.0428,30.60364 c 5.4239,-0.9485 10.59041,-3.10725 15.17674,-6.11498 3.45277,-2.31517 6.33827,-5.35111 8.96014,-8.54222 2.4701,-3.28786 4.25736,-7.03863 5.71899,-10.85553 0.83236,-3.14339 1.98384,-6.24462 1.59298,-9.54963 -0.15603,-3.97463 0.76312,-7.59052 -0.70605,-11.39198 -0.78608,-4.05471 -3.04293,-7.58348 -4.84261,-11.23739 -2.15407,-3.17379 -4.77743,-5.92717 -7.64719,-8.45981 -10.59346,-9.22986 -27.18922,-10.05494 -33.62085,-7.57632 -3.82378,0.82656 -7.34574,2.67424 -10.72257,4.57977 -3.48824,2.13344 -6.47272,5.00475 -9.17115,8.04248 -2.33623,2.63759 -3.91184,5.74632 -5.37798,8.92287 -1.537277,2.53108 -1.854532,5.5153 -2.681719,8.31012 H 87.041892 c 0.153327,-4.15156 1.719901,-8.14495 3.083133,-12.03251 2.312026,-5.15975 5.174164,-10.09442 9.046652,-14.24904 3.344423,-3.95296 7.620693,-7.02508 11.986493,-9.76189 3.69904,-1.9143 7.43435,-3.7385 11.49174,-4.76272 3.91543,-1.122135 7.96215,-1.94486 12.05607,-1.669633 3.7202,-0.01194 7.45945,-0.218765 11.07643,0.825164 4.6541,0.969169 9.18094,2.557499 13.35655,4.844359 4.62698,2.32655 8.73588,5.62683 12.42692,9.22715 3.88325,4.23548 7.23475,8.96252 9.52734,14.24968 2.19262,4.65582 3.39578,9.74658 4.18948,14.8132 -0.17794,5.09645 0.54845,10.2813 -0.82516,15.26087 -0.96917,4.65411 -2.5575,9.18094 -4.84436,13.35655 -2.32655,4.62698 -5.62683,8.73589 -9.22715,12.42692 -4.23548,3.88324 -8.96251,7.23476 -14.24967,9.52734 -4.54658,2.14795 -9.51206,3.33737 -14.46873,4.09762 -3.00515,0.12837 -6.0103,0.25675 -9.01545,0.38513 l -0.19213,-0.20225 z"
              id="path1103"
            />
            <path
              style={{
                display: "inline",
                fill: "#f90000",
                stroke: "#f90000",
                strokeWidth: 0.5,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
              d="m 67.937215,199.24991 c -6.787478,-0.67978 -13.440544,-2.76516 -19.354686,-6.18215 -3.179708,-1.64213 -6.054669,-3.90442 -8.689608,-6.31009 -3.009037,-2.77169 -5.759995,-5.76881 -8.055916,-9.16341 -2.645731,-4.0484 -4.805938,-8.47731 -6.243533,-13.10819 -1.133224,-4.01292 -1.875522,-8.09193 -2.092622,-12.25973 -0.413155,-3.64677 0.350544,-7.32823 0.71754,-10.95292 1.101363,-5.35084 2.776504,-10.59369 5.570946,-15.31643 1.649529,-3.28354 3.972016,-6.23079 6.437103,-8.93702 2.771686,-3.00904 5.768815,-5.75999 9.163413,-8.05592 4.048401,-2.64571 8.477299,-4.80596 13.108191,-6.24352 3.945893,-1.0971 7.942905,-1.88709 12.043247,-2.0563 2.070155,-0.35409 4.126094,-0.21607 6.173749,0.20886 v 42.21949 c 3.673493,-0.006 7.346985,-0.0123 11.020478,-0.0185 l -16.140749,30.7337 C 67.786401,162.92227 62.28809,153.38812 57.093889,143.08834 l 9.638526,0.0388 2e-6,-30.57526 c -3.574684,0.58713 -6.997267,1.9701 -10.36987,3.22464 -3.315021,1.70964 -6.383443,3.77975 -9.167749,6.26518 -2.440754,2.04675 -4.302494,4.75961 -6.179717,7.31202 -2.064405,3.13407 -3.440848,6.58483 -4.580538,10.14458 -1.184832,3.95841 -1.547041,8.11017 -1.37114,12.23524 0.013,4.21756 1.030676,8.39621 2.594748,12.3028 1.513898,4.5019 4.365191,8.39471 7.432453,11.97035 2.044167,2.31232 4.64739,4.15236 7.136084,5.96233 3.134072,2.0644 6.584825,3.44085 10.144567,4.58053 3.958413,1.18485 8.11017,1.54705 12.235242,1.37115 4.217563,-0.013 8.396215,-1.03068 12.302807,-2.59475 4.83527,-1.5974 8.877028,-4.81326 12.747483,-8.02755 2.857343,-3.07984 5.261193,-6.51112 7.305463,-10.18126 1.44425,-3.25023 2.68643,-6.6491 3.28507,-10.177 h 11.56164 c -0.15037,3.01456 -1.19846,5.96545 -1.97364,8.87442 -1.39977,4.06664 -3.35893,7.95254 -5.65808,11.57025 -2.34015,3.42145 -5.07327,6.49965 -8.13232,9.29314 -3.12494,2.90827 -6.625296,5.47894 -10.505209,7.29684 -6.215943,3.41205 -13.272137,5.04567 -20.312588,5.51441 -2.420127,0.27808 -4.877051,0.016 -7.289906,-0.23933 z"
              id="path1104"
            />
          </g>
        </svg>
      </motion.div>
    </motion.div>
  );
};

export default PreLoader;
