import React from 'react';
import Button from '../Button';
//Editor
import { useNode } from "@craftjs/core";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import { Box, Input, Stack, Typography, Button as JButton, Card, CardContent, CardActions } from '@mui/joy';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import RichTextEditor from 'src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor';
import { FilePickerOpener } from 'src/dashboard/components/FilePicker';

export default function AboutStyle3({ title, subTitle, btnText, btnUrl }) {
  return (
    <div className="container">
      <div className="cs_cta cs_style_2">
        <h2 className="cs_cta_title cs_fs_68">{title}</h2>
        <div className="cs_cta_in">
          <div className="cs_cta_left">
            <span className="cs_cta_icon">
              <svg
                width="121"
                height="121"
                viewBox="0 0 121 121"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.25"
                  d="M0 54.3207C0.346705 54.3523 0.693411 54.3838 1.04012 54.3838C15.5702 54.3838 30.1003 54.3838 44.6619 54.3838C44.9771 54.3838 45.2923 54.3838 45.5444 54.3838C34.765 43.6016 23.9857 32.8194 13.2378 22.0688C16.2951 19.0422 19.1003 16.2994 21.9685 13.462C32.5903 24.0865 43.3696 34.8687 54.4327 45.9346C54.4327 30.3919 54.4327 15.1959 54.4327 0C58.5301 0 62.5014 0 66.5673 0C66.5673 15.2275 66.5673 30.3603 66.5673 45.4617C77.3467 34.6795 88.1261 23.8973 98.8109 13.2097C101.868 16.2678 104.642 19.0422 107.479 21.8796C96.8567 32.5042 86.0774 43.2863 75.298 54.0685C75.3295 54.1631 75.3925 54.2577 75.4241 54.3523C90.5845 54.3523 105.777 54.3523 121 54.3523C121 58.4508 121 62.4231 121 66.5216C105.777 66.5216 90.6161 66.5216 75.3295 66.5216C86.235 77.4299 96.9828 88.1806 107.731 98.9312C104.768 101.832 101.994 104.575 99.1576 107.38C88.5358 96.7559 77.7564 85.9737 66.788 74.9708C66.7249 75.5698 66.6619 75.822 66.6619 76.0743C66.6619 90.7658 66.6619 105.457 66.6619 120.18C66.6619 120.464 66.7249 120.716 66.7249 121C62.6275 121 58.5301 121 54.4327 121C54.4642 120.653 54.4957 120.306 54.4957 119.96C54.4957 105.426 54.4957 90.8919 54.4957 76.358C54.4957 76.0427 54.4327 75.6959 54.4011 75.0023C43.3381 86.0052 32.5587 96.7874 21.937 107.412C19.1003 104.606 16.2951 101.863 13.2063 98.8366C23.9542 88.086 34.702 77.3353 45.4814 66.5531C45.2607 66.5531 44.9456 66.5531 44.6304 66.5531C30.1003 66.5531 15.5702 66.5531 1.04012 66.5531C0.693411 66.5531 0.346705 66.6162 0 66.6162C0 62.5177 0 58.4192 0 54.3207Z"
                  fill="#4F4747"
                />
              </svg>
            </span>
          </div>
          <div className="cs_cta_right">
            <h3 className="cs_cta_subtitle cs_fs_21 cs_semibold cs_secondary_color cs_fs_21">
              {subTitle}
            </h3>
            <Button btnText={btnText} btnUrl={btnUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditableAboutStyle3({
  title,
  subTitle,
  btnText,
  btnUrl,
  svgElement,
}) {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* COMPONENT STARTS HERE */}
      <div className="container">
      <div className="cs_cta cs_style_2">
        <h2 className="cs_cta_title cs_fs_68">{title}</h2>
        <div className="cs_cta_in">
          <div className="cs_cta_left">
            <span className="cs_cta_icon">
              <svg
                width={svgElement?.width || "121"}
                height={svgElement?.height || "121"}
                viewBox="0 0 121 121"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.25"
                  d="M0 54.3207C0.346705 54.3523 0.693411 54.3838 1.04012 54.3838C15.5702 54.3838 30.1003 54.3838 44.6619 54.3838C44.9771 54.3838 45.2923 54.3838 45.5444 54.3838C34.765 43.6016 23.9857 32.8194 13.2378 22.0688C16.2951 19.0422 19.1003 16.2994 21.9685 13.462C32.5903 24.0865 43.3696 34.8687 54.4327 45.9346C54.4327 30.3919 54.4327 15.1959 54.4327 0C58.5301 0 62.5014 0 66.5673 0C66.5673 15.2275 66.5673 30.3603 66.5673 45.4617C77.3467 34.6795 88.1261 23.8973 98.8109 13.2097C101.868 16.2678 104.642 19.0422 107.479 21.8796C96.8567 32.5042 86.0774 43.2863 75.298 54.0685C75.3295 54.1631 75.3925 54.2577 75.4241 54.3523C90.5845 54.3523 105.777 54.3523 121 54.3523C121 58.4508 121 62.4231 121 66.5216C105.777 66.5216 90.6161 66.5216 75.3295 66.5216C86.235 77.4299 96.9828 88.1806 107.731 98.9312C104.768 101.832 101.994 104.575 99.1576 107.38C88.5358 96.7559 77.7564 85.9737 66.788 74.9708C66.7249 75.5698 66.6619 75.822 66.6619 76.0743C66.6619 90.7658 66.6619 105.457 66.6619 120.18C66.6619 120.464 66.7249 120.716 66.7249 121C62.6275 121 58.5301 121 54.4327 121C54.4642 120.653 54.4957 120.306 54.4957 119.96C54.4957 105.426 54.4957 90.8919 54.4957 76.358C54.4957 76.0427 54.4327 75.6959 54.4011 75.0023C43.3381 86.0052 32.5587 96.7874 21.937 107.412C19.1003 104.606 16.2951 101.863 13.2063 98.8366C23.9542 88.086 34.702 77.3353 45.4814 66.5531C45.2607 66.5531 44.9456 66.5531 44.6304 66.5531C30.1003 66.5531 15.5702 66.5531 1.04012 66.5531C0.693411 66.5531 0.346705 66.6162 0 66.6162C0 62.5177 0 58.4192 0 54.3207Z"
                  fill={svgElement?.fill || "#4F4747"}
                />
              </svg>
            </span>
          </div>
          <div className="cs_cta_right">
            <h3 className="cs_cta_subtitle cs_fs_21 cs_semibold cs_secondary_color cs_fs_21">
              {subTitle}
            </h3>
            <Button btnText={btnText} btnUrl={btnUrl} />
          </div>
        </div>
      </div>
    </div>
      {/* COMPONENT ENDS HERE */}
      
    </Box>
  );
}

const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    title,
    subTitle,
    btnText,
    btnUrl,
    svgElement,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    btnText: node.data.props.btnText,
    btnUrl: node.data.props.btnUrl,
    svgElement: node.data.props.svgElement,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);
  const [dataItems, setDataItems] = React.useState();
  //color
  const [color, setColor] = useColor(svgElement?.fill); 

  //COMPONENT HANDLERS
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //REPEATABLE DATA HANDLERS
  //--add growth data item
  const addDataItem = () => {
    setDataItems((items) => [
      ...items,
      {
        title: "Title",
        percentage: "0",
      },
    ]);
  };
  //--remove growth data item
  const removeDataItem = (index) => {
    setDataItems((items) => items.filter((item, i) => i !== index));
  };
  //--update growth data item
  const updateDataItem = (index, key, value) => {
    setDataItems((items) =>
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  //COMPONENT EFFECTS
  React.useEffect(() => {
    setProp((prop) => (prop.featureList = dataItems));
  }, [dataItems]);

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handlePanelChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2} justifyContent="space-between">
            <Input
              size="sm"
              label="Title"
              value={title}
              onChange={(e) => {
                setProp((prop) => (prop.title = e.target.value));
              }}
            />
            <Input
              size="sm"
              label="Sub Title"
              value={subTitle}
              onChange={(e) => {
                setProp((prop) => (prop.subTitle = e.target.value));
              }}
            />
            <Input
              size="sm"
              label="Button Text"
              value={btnText}
              onChange={(e) => {
                setProp((prop) => (prop.btnText = e.target.value));
              }}
            />
            <Input
              size="sm"
              label="Button URL"
              value={btnUrl}
              onChange={(e) => {
                setProp((prop) => (prop.btnUrl = e.target.value));
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handlePanelChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>SVG Icon</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2} justifyContent="space-between">
            <Input
              size="sm"
              label="Width"
              type="number"
              value={svgElement?.width}
              onChange={(e) => {
                setProp((prop) => (prop.svgElement.width = e.target.value));
              }}
            />
            <Input
              size="sm"
              label="Height"
              type="number"
              value={svgElement?.height}
              onChange={(e) => {
                setProp((prop) => (prop.svgElement.height = e.target.value));
              }}
            />
            
            <Typography>Fill Color</Typography>
            <ColorPicker 
            color={color}
            onChange={(color)=>{
              setProp((prop) => (prop.svgElement.fill = color.hex));
            }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion> 
    </>
  )
}

EditableAboutStyle3.craft = {
  rules: {
    canDrag: () => true,
  },
  props: {
    title: "About Us",
    subTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum id, varius mi. Nunc efficitur ex nunc, in laoreet justo scelerisque ac.",
    btnText: "Read More",
    btnUrl: "#",
    svgElement: {
      width: "121",
      height: "121",
      fill: "#4F4747",
    },
  },
  related: {
    settings: ComponentSettings,
  },
};
