import axios from "axios";
import { update } from "lodash";

const PortfolioApi = {
  //--url
  baseUrl: "https://api.soimagine.co.za/wp-json",
  namespace: "/sa9ecms/v1/",
  endpoint: "portfolio",

  //--get all
  getAll: async () => {
    try {
      return axios
        .get(
          `${PortfolioApi.baseUrl}${PortfolioApi.namespace}${PortfolioApi.endpoint}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("PORTFOLIO ALL", JSON.stringify(response.data, null, 2));
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--get one
  getOne: async (id) => {
    try {
      return axios
        .get(
          `${PortfolioApi.baseUrl}${PortfolioApi.namespace}${PortfolioApi.endpoint}/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("PORTFOLIO SINGLE", JSON.stringify(response.data, null, 2));
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--create
  create : async (data) => {

    let bodyParams = {
      title: data?.title,
      content: data?.content,
      status: data?.status,
      category: data?.category,
      author: data?.author,
      featured_image: data?.featured_image,
      client: data?.client,
      tech_stack: data?.tech_stack,
      project_url: data?.project_url,
      project_completion: data?.project_completion,
      project_gallery: data?.project_gallery,
    };

    try {
      let req = axios.post(
        `${PortfolioApi.baseUrl}${PortfolioApi.namespace}${PortfolioApi.endpoint}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--update
  update: async (id, data) => {

    let bodyParams = {
      title: data?.title,
      content: data?.content,
      status: data?.status,
      category: data?.category,
      author: data?.author,
      featured_image: data?.featured_image,
      client: data?.client,
      tech_stack: data?.tech_stack,
      project_url: data?.project_url,
      project_completion: data?.project_completion,
      project_gallery: data?.project_gallery,
    };

    try {
      let req = axios.put(
        `${PortfolioApi.baseUrl}${PortfolioApi.namespace}${PortfolioApi.endpoint}/${id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("UPDATE", (await req).data);
      return req;
    } catch (error) {
      console.log(error);
    }
  }

};

export const ServiceApi = {
  //--url
  baseUrl: "https://api.soimagine.co.za/wp-json",
  namespace: "/sa9ecms/v1/",
  endpoint: "service",

  //--get all
  getAll: async () => {
    try {
      return axios
        .get(
          `${ServiceApi.baseUrl}${ServiceApi.namespace}${ServiceApi.endpoint}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("SERVICES ALL", JSON.stringify(response.data, null, 2));
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--get one
  getOne: async (id) => {
    try {
      return axios
        .get(
          `${ServiceApi.baseUrl}${ServiceApi.namespace}${ServiceApi.endpoint}/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("SERVICES SINGLE", JSON.stringify(response.data, null, 2));
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--create
  create : async (data) => {

    let bodyParams = {
      title: data?.title,
      content: data?.content,
      status: data?.status,
      category: data?.category,
      author: data?.author,
      featured_image: data?.featured_image,

      listing: data?.listing,
      key_points: data?.key_points,
      summary: data?.summary,

    };

    try {
      let req = axios.post(
        `${ServiceApi.baseUrl}${ServiceApi.namespace}${ServiceApi.endpoint}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--update
  update: async (id, data) => {

    let bodyParams = {
      title: data?.title,
      content: data?.content,
      status: data?.status,
      category: data?.category,
      author: data?.author,
      featured_image: data?.featured_image,
      
      listing: data?.listing,
      key_points: data?.key_points,
      summary: data?.summary,
    };

    try {
      let req = axios.put(
        `${ServiceApi.baseUrl}${ServiceApi.namespace}${ServiceApi.endpoint}/${id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("UPDATE", (await req).data);
      return req;
    } catch (error) {
      console.log(error);
    }
  }

};

export default PortfolioApi;
