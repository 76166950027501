import React from "react";
import { Outlet } from "react-router-dom";
import Header, { ModernHeader } from "../Header";
import Footer from "../Footer";
import ThemeSwitcher from "../Misc/ThemeSwitcher";
import Sa9eAssistant from "../ChatBot";
import PageTransition from "../Framer/PageTransitions";
import { motion } from "framer-motion";

export default function Layout({ darkMode }) {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 100,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 100,
      }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: "easeInOut",
      }}
    >
      <div className={`${darkMode ? "cs_dark" : ""}`}>
        <Header
          logoUrl={
            darkMode ? "/soimagine/logo-white.png" : "/soimagine/logo-black.png"
          }
          actionBtnText="Login"
          actionBtnUrl="/auth/login"
        />

        <Outlet />

        <Sa9eAssistant />
        <Footer />
      </div>
    </motion.div>
  );
}
