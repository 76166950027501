/* 
  This is a CraftJS component for the that allows the user to add and edit 
  using a custom rich text editor.
*/

import React, { memo, useEffect, useMemo } from "react";
//import sanitizeHtml from "sanitize-html";
//craft-js
import { useNode } from "@craftjs/core";
import { motion } from "framer-motion";
/*SETTINGS PANEL*/
import { ComponentActions } from "./ComponentActions";
import { Button, Textarea, ToggleButtonGroup } from "@mui/joy";
import ContentEditable from "react-contenteditable";
import { Icon } from "@iconify/react";
import LayoutSettings from "./fieldSettings/LayoutSettings";
import TypographySettings from "./fieldSettings/TypographySettings";
import BackgroundSettings from "./fieldSettings/BackgroundSettings";

/* EDITOR COMPONENT */
const Text = ({
  content,
  //elementProps
  id,
  classes,
  //Styling Props
  layout,
  size,
  spacing,
  position,
  borders,
  background,
  effects,
  typography,
  states,
  //Actions
  onAction,
  onEvent,
}) => {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,

  }));

  return (
    //This is the layout container for the text component
    <div
      ref={(ref) => connect(drag(ref))}
      className={classes}
      style={{
        display: layout.display,
        flexDirection: layout.flexDirection,
        justifyContent: layout.justifyContent,
        alignItems: layout.alignItems,
        width: size.width,
        height: size.height,
        margin: spacing.margin,
        padding: spacing.padding,
        position: position.position,
        top: position.top,
        right: position.right,
        bottom: position.bottom,
        left: position.left,
        border: borders.border,
        borderRadius: borders.borderRadius,
        backgroundColor: background.bgcolor,
        color: background.color,
      }}
    >
      <ContentEditable
        html={content}
        onChange={(e) => setProp((prop) => (prop.content = e.target.value))}
        tagName="p"
        style={{
          fontSize: typography.fontSize,
          fontWeight: typography.fontWeight,
          lineHeight: typography.lineHeight,
          letterSpacing: typography.letterSpacing,
          textAlign: typography.textAlign,
        }}
      />
    </div>
    
  );
};

//CONTENT FIELD
const TextSettings = ({ id, classes }) => {
  const {
    actions: { setProp },
    content,
  } = useNode((node) => ({
    content: node.data.props.content,
  }));

  return (
    <Textarea
      label="Content"
      value={content}
      onChange={(e) => setProp((prop) => (prop.content = e.target.value))}
      fullWidth
      size="small"
      rows={4}
    />
  );
};

const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp, setCustom, setHidden },
    
    //componentProps
    content,
    //elementProps
    id, classes, 
    //General Styling Props
    layout, size, spacing, position, borders, background, effects, typography,
    //States
  } = useNode((node) => ({
    //componentProps
    content: node.data.props.content,
    //elementProps
    id: node.id,
    classes: node.data.props.classes,
    //General Styling Props
    layout: node.data.props.layout,
    size: node.data.props.size,
    spacing: node.data.props.spacing,
    position: node.data.props.position,
    borders: node.data.props.borders,
    background: node.data.props.background,
    effects: node.data.props.effects,
    typography: node.data.props.typography,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //--handle Props Change
  const handleLayoutProps = (prop) => {
    setProp((props) => {
      props.layout = { ...props.layout, ...prop };
    });
  };

  const handleTypographyProps = (prop) => {
    setProp((props) => {
      props.typography = { ...props.typography, ...prop };
    });
  };

  //RENDER
  return (
    <>
      {/*--Tab Buttons*/}
      <ToggleButtonGroup
        variant="outlined"
        size="sm"
        value={currentTab}
        exclusive
        buttonFlex={1}
        onChange={(e, value) => setCurrentTab(value)}
      >
        {/*--Content*/}
        <Button
          fullWidth
          value={0}
          aria-label="content"
          sx={{ borderRadius: "0px" }}
        >
          <Icon icon="bi:card-text" />
        </Button>

        {/*--Layout*/}
        <Button
          fullWidth
          value={1}
          aria-label="layout"
          sx={{ borderRadius: "0px" }}
        >
          <Icon icon="bi:aspect-ratio" />
        </Button>

        {/*--Typography*/}
        <Button
          fullWidth
          value={2}
          aria-label="style"
          sx={{ borderRadius: "0px" }}
        >
          <Icon icon="bi:type" />
        </Button>

        {/*--Background*/}
        <Button
          fullWidth
          value={3}
          aria-label="background"
          sx={{ borderRadius: "0px" }}
        >
          <Icon icon="bi:brush" />
        </Button>

        {/*--Borders*/}
        <Button
          fullWidth
          value={4}
          aria-label="borders"
          sx={{ borderRadius: "0px" }}
        >
          <Icon icon="bi:border-all" />
        </Button>


      </ToggleButtonGroup>

      {currentTab === 0 ? (
        <TextSettings />
      ) : currentTab === 1 ? (
        <LayoutSettings
          settings={layout}
          onSettingsChange={handleLayoutProps} />
      ) : currentTab === 2 ? (
        <TypographySettings
          settings={typography}
          onSettingsChange={handleTypographyProps} />
      ) : currentTab === 3 ? (
        <BackgroundSettings
          settings={background}
          onSettingsChange={(prop, value) => setProp((props) => (props.background[prop] = value))}
        />
      ) : currentTab === 4 ? (
        <>
        </>
      ) : currentTab === 5 ? (
        <>
        </>
      ) : currentTab === 6 ? (
        <>
        </>
      ) : null}
    </>
  );
};

//EDITOR SETTINGS
Text.craft = {
  details: {
    name: "Text",
    description: "Text",
    image: "https://img.icons8.com/ios/452/text.png",
  },
  props: {
    content: "Type something",
    //elementProps
    classes: "text-component",
    //Styling Props
    layout: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    size: {
      width: "auto",
      height: "auto",
    },
    spacing: {
      margin: "0px",
      padding: "0px",
    },
    position: {
      position: "relative",
      top: "auto",
      right: "auto",
      bottom: "auto",
      left: "auto",
    },
    borders: {
      border: "none",
      borderRadius: "0px",
    },
    background: {
      bgcolor: "transparent",
      color: "black",
    },
    effects: {
      boxShadow: "none",
    },
    typography: {
      fontSize: "16",
      fontWeight: "normal",
      lineHeight: "1.5",
      letterSpacing: "0",
      textAlign: "left",
    },
    states: {
      hover: {
        color: "black",
        bgcolor: "transparent",
        border: "none",
        boxShadow: "none",
      },
      focus: {
        color: "black",
        bgcolor: "transparent",
        border: "none",
        boxShadow: "none",
      },
      active: {
        color: "black",
        bgcolor: "transparent",
        border: "none",
        boxShadow: "none",
      },
    },
  },
  custom: {
    settings: TextSettings,
  },
  rules: {
    canDrag: (node) => node.data.props.text != "Drag",
    //can be moved in and out of the layout/flex container
    canMoveIn: (node) => true,
    canMoveOut: (node) => true,
  },
  related: {
    settings: ComponentSettings,
  },
};

export default Text;
