import React, { useState, useEffect } from "react";
import { Frame, Element } from "@craftjs/core";
import _ from "lodash";
import lz from "lzutf8";
import { useLocation } from "react-router-dom";
import MenusApi from "src/dashboard/modules/menu/MenuApi";

function MenuDesigner() {
  //CONSTANTS
  const location = useLocation();

  //CONTEXTS

  //STATES
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  //HOOKS

  //FUNCTIONS
  function handleContentLoad() {
    setLoading(true);
    setIsError(false);

    const pageId = location.pathname.split("/").pop();

    /* PagesApi.getSingle(pageId)
      .then((res) => {
        const pageData = res.data;
        const decompressedPage = lz.decompress(
          lz.decodeBase64(pageData.page_content)
        );
        //actions.deserialize(decompressedPage);
        setPageContent(decompressedPage);
        setLoading(false);

        console.log("Page Content Loaded");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setIsError(true);
      }); */
  }

  //EFFECTS

  //RENDER
  return (
    
    <Frame json={editorContent}>
      
    </Frame>
  );
}

export default MenuDesigner;
