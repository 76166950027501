import { useEffect, useMemo, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { WP_API_TOKEN } from "src/api/config";
import { ListItemIcon, MenuItem } from "@mui/material";

/*CONSTANTS*/
const columns = [
  {
    accessorKey: 'page_title',
    header: 'Title',
  },
  {
    accessorKey: 'page_author  ',
    header: 'Author',
  },
  {
    accessorKey: 'page_status',
    header: 'Status',
  },
  {
    accessorKey: 'page_last_modified',
    header: 'Last Modified',
  },
];
const fetchSize = 25;

/*COMPONENT*/
//--contentList | default
export default function BlocksList() {

  //CONSTANTS
  const navigate = useNavigate()

  //STATES
  //--app states

  //FUNCTIONS
  //--navigation functions
  function handlePageClick(pageId) {
    navigate(`/dashboard/content/edit/${pageId}`);
  }
  //__go to add new page
  function handleAddNewPageClick() {
    navigate(`/dashboard/content/add`);
  }
  
  return (
    <Box sx={{ minHeight: "100vh" }}>

      {/* Breadcrumbs / Actions */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:page-number"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Content
          </Typography>
        </Breadcrumbs>

        <Button
          color="primary"
          onClick={handleAddNewPageClick}
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Add New Page
        </Button>
      </Box>

      {/* Page Title */}
      {/* <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      > 
      </Box> */}

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
        <ContentTable />
      </Box>
      
    </Box>
  );
}

//--content Table | ContentTable

const ContentTable = () => {

  //CONSTANTS
  const navigate = useNavigate();

  //STATES
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //FUNCTIONS
  //--navigation functions
  //--go to edit page with pageId
  function handlePageClick(pageId) {
    navigate(`/dashboard/content/edit/${pageId}`);
  }

  //EFFECTS
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await fetch(
          'https://api.soimagine.co.za/wp-json/sa9ecms/v1/content',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${WP_API_TOKEN}`,
            },
          },
        )
        const json = await response.json();
        setData(json);
        setRowCount( json ? Object.keys(json).length : 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'page_title',
        header: 'Title',
      },
      //column definitions...
      {
        accessorKey: 'page_author',
        header: 'Author',
      },
      {
        accessorKey: 'page_status',
        header: 'Status',
      },
      {
        accessorKey: 'page_last_modified',
        header: 'Last Modified',
      },
      //end
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: false},
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        onClick={() => {
          closeMenu();
          handlePageClick(row.id);
        }}
      >
        <ListItemIcon>
          <Icon icon="carbon:edit" />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem>
        <ListItemIcon>
          <Icon icon="carbon:delete" />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    //--Apply Styling
    muiTablePaperProps:{
      variant: 'outlined',
      elevation: 0,
      sx:{
        border: '1px solid #3c424d',
        borderRadius: '4px',
        boxShadow: 'none',
      }
    }
  });

  return <MaterialReactTable table={table} />;
};
