/*This File Defines the Products for the SoImagine Portfolio

Products:
 - YourMove
 - DigimCast
 - BoardFlow
 - WorkWize360
*/

const defaultProductsData = [
  {
    id: "workwize360",
    title: "WorkWize360",
    subtitle: "Project Management Tool",
    image: "soimagine/products/WorkWize360Feature.jpg",
    link: "https://www.soimagine.com/workwize360",
    details: {
      platform: "Web App",
      tech: "React, NodeJS, PostgreSQL",
      service: "Project Management, Collaboration",
    },
    article:
      "WorkWize360 is a project management tool that helps you organize and track your projects with ease. With our web app, you can create tasks, assign team members, and set deadlines to keep your projects on track. Our intuitive interface allows you to visualize your project progress, communicate with your team, and monitor performance in real-time. WorkWize360 also provides powerful reporting and analytics tools, so you can gain valuable insights into your project data. Whether you're a project manager, team leader, or individual contributor, WorkWize360 is the perfect solution for efficient and effective project management. Try it today and take your projects to the next level!",
  },
  {
    id: "yourmove",
    title: "YourMove",
    subtitle: "On-Demand Courier Service",
    image: "soimagine/products/YourMoveFeature.jpg",
    link: "https://www.soimagine.com/yourmove",
    details: {
      platform: "Mobile App",
      tech: "React Native, NodeJS, .NET Core",
      service: "Transportation, Logistics",
    },
    article:
      "YourMove is an on-demand courier service that provides a convenient and reliable solution for all your delivery needs. With our mobile app, available for both drivers and users, you can easily schedule a delivery and track it in real-time. Our user-friendly interface allows you to rate and review your delivery experience, ensuring that we consistently provide top-notch service. Additionally, our secure online payment system allows you to conveniently pay for your delivery right from the app. Whether you need to send documents, packages, or even food, YourMove is here to make your life easier. Try it today and experience the future of courier services!",
  },
  {
    id: "digimcast",
    title: "DigimCast",
    subtitle: "Digital Signage Solution",
    image: "soimagine/products/DigimCastFeature.jpg",
    link: "https://www.soimagine.com/digimcast",
    details: {
      platform: "Web App",
      tech: "React, NodeJS, MongoDB",
      service: "Digital Signage",
    },
    article:
      "DigimCast is a digital signage solution that allows you to create and manage dynamic content for your displays. With our easy-to-use web app, you can design custom layouts, schedule content, and monitor your displays in real-time. Whether you need to display advertisements, announcements, or event information, DigimCast has you covered. Our cloud-based platform ensures that your content is always up-to-date and accessible from anywhere. With DigimCast, you can captivate your audience and enhance your brand image with stunning digital displays. Try it today and take your signage to the next level!",
  },
  {
    id: "boardflow",
    title: "BoardFlow",
    subtitle: "Board Meeting Management",
    image: "soimagine/products/BoardFlowFeature.jpg",
    link: "https://www.soimagine.com/boardflow",
    details: {
      platform: "Web App",
      tech: "React, NodeJS, MongoDB",
      service: "Board Management",
    },
    article:
      "BoardFlow is a board meeting management platform that streamlines the entire meeting process, from agenda creation to minutes distribution. With our web app, you can easily create and share meeting agendas, track action items, and collaborate with board members in real-time. Our intuitive interface allows you to manage multiple boards and meetings with ease, ensuring that everyone is on the same page. BoardFlow also provides secure document storage and version control, so you can keep all your board-related files organized and accessible. Whether you're a board member, executive, or administrator, BoardFlow is the perfect solution for efficient and effective board governance. Try it today and revolutionize your board meetings!",
  },
];

export const OurProducts = [
  {
    title: "DigimCast",
    intro: `
    DigiM is an innovative and versatile digital signage application designed to help you create, manage, and
    serve engaging content across various platforms. Built by digital signage experts with years of field-proven
    experience, DigiM provides a robust suite of features tailored for professionals in the digital advertising
    industry.`,
    content: `
    DigiM stands as the optimal choice for businesses aiming to amplify their digital advertising
    with compelling, high-quality content. Its user-friendly interface, coupled with an extensive set
    of features, positions it as the go-to tool for digital signage professionals.
    Ideal for crafting robust and impactful campaigns, DigiM provides reliability and versatility in
    one package. Currently, DigiM's capabilities are showcased on over 400 screens,
    demonstrating its effectiveness and widespread adoption in enhancing digital signage
    experiences. This extensive reach is a testament to its ability to meet diverse advertising
    needs and to its standing as a trusted tool in the digital signage industry.`,
    posts: [
      {
        title: "Seamless Integration",
        content: `DigiM is designed to work effortlessly with
    different display sizes, from small 7"
    tablets to large outdoor LED screens,
    ensuring consistent performance and
    visual appeal.`,
      },
      {
        title: "Dynamic Content",
        content: `DigiM allows you to create dynamic
    content that can be updated in real-time
    to keep your audience engaged and
    informed.`,
      },
      {
        title: "Customizable Templates",
        content: `DigiM offers a wide range of customizable
    templates to help you create stunning
    content that aligns with your brand
    identity.`,
      },
      {
        title: "Remote Management",
        content: `DigiM's cloud-based platform enables
    you to manage your content remotely,
    ensuring that your displays are always
    up-to-date and relevant.`,
      },
      {
        title: "Analytics and Reporting",
        content: `DigiM provides detailed analytics and
    reporting tools to help you track the
    performance of your content and make
    data-driven decisions.`,
      },
    ],
  },
  {
    title: "WorkWize 360",
    intro: `
    WorkWize 360 is an innovative productivity tool designed to streamline workforce management and enhance collaboration within your organization. With its comprehensive set of features, WorkWize 360 empowers businesses to efficiently manage their human resources and optimize productivity. Currently being used by over 120 users.`,
    content: `
    WorkWize 360 now includes an advanced feature for configuring public holidays and calculating overtime. This function allows for the automatic recognition of public holidays and the seamless calculation of overtime hours. It ensures accurate compensation for employees working during these periods and simplifies the administrative process, making it easier for managers to maintain compliance with labor laws and company policies. This feature further enhances WorkWize 360's capabilities in managing complex workforce schedules and payroll calculations.`,
    posts: [
      {
        title: "Dashboards",
        content: `WorkWize 360 provides visually appealing and interactive dashboards that present key performance metrics and data in a user-friendly manner. These dashboards offer a holistic view of employee attendance, shift fulfillment statistics, and other important workforce-related information, enabling managers to monitor and optimize resource utilization effectively.`,
      },
      {
        title: "User Management",
        content: `WorkWize 360 offers a flexible user management system, categorizing users into three roles: Admin, Managers, and Employees. This hierarchical structure enables efficient delegation of responsibilities and ensures smooth coordination among team members.`,
      },
      {
        title: "Progressive Web Application (PWA)",
        content: `WorkWize 360 is built as a Progressive Web Application, commonly referred to as PWA. As a PWA, it combines the best of both web and mobile applications, providing a seamless user experience across devices without the need for installation. This allows users to access WorkWize 360 anytime, anywhere, directly from their web browsers.`,
      },
      {
        title: "Time Logging and Approval",
        content: `Employees can conveniently log their working hours within WorkWize 360, simplifying time tracking and attendance management. Managers can then review and approve or reject time logs, ensuring accurate records and efficient payroll processing.`,
      },
      {
        title: "Employee Attendance and Shift Fulfillment Stats",
        content: `Comprehensive tools for monitoring employee attendance and shift fulfillment. The system provides real-time data on attendance records, enabling managers to track employee punctuality and identify any deviations or patterns that require attention. This feature ensures compliance with schedules and enhances operational efficiency.`,
      },
      {
        title: "Push Notification Reminders",
        content: `WorkWize 360 keeps users informed and on track with push notification reminders. These notifications help employees stay updated on important deadlines, meetings, and tasks, boosting productivity and reducing the chances of missing crucial events.`,
      },
      {
        title: "In-App Chat and Collaboration",
        content: `WorkWize 360 includes an intuitive in-app chat feature that facilitates seamless communication and collaboration within teams. Employees and managers can exchange messages, share files, and discuss project details in real-time, fostering efficient teamwork and reducing the reliance on external communication tools.`,
      },
      {
        title: "AI Driven Reporting And NLP",
        content: `Leveraging the power of Artificial Intelligence (AI), WorkWize 360 offers advanced reporting capabilities. The system utilizes Natural Language Processing (NLP) to analyze data, extract insights, and generate meaningful reports. These AI-driven reports provide valuable analytics, helping businesses make informed decisions and identify areas for improvement.`,
      },
      {
        title: "Disciplinary Reporting",
        content: `Disciplinary Reporting: WorkWize 360 includes a module for disciplinary reporting, enabling managers to document and track disciplinary actions taken within the organization. This feature helps maintain a record of incidents, investigations, and disciplinary measures, fostering a fair and accountable work environment.`,
      },
    ],
  },
  {
    title: "BoardFlow",
    intro: `
  BoardFlow is a comprehensive solution designed to streamline the organization and management of meetings, making it easier for teams to collaborate effectively and efficiently.`,
    content: `
  BoardFlow is a powerful tool that simplifies the process of organizing and managing board meetings. With its intuitive interface and robust features, BoardFlow enables teams to create and share meeting agendas, assign action items, and track progress in real-time. The platform also offers secure document storage and version control, ensuring that all board-related files are organized and accessible. Whether you're a board member, executive, or administrator, BoardFlow is the ideal solution for efficient and effective board governance. Try it today and revolutionize your board meetings!`,
    posts: [
      {
        title: "User Management",
        content: `The platform requires mandatory information for each user, such as name, email, department, position, title, and role, ensuring accurate identification and proper access control.`,
      },
      {
        title: "Agenda Building",
        content: `Authorized users can create meeting agendas with details like meeting title, agenda items, meeting description, meeting lead/chairperson, date, attendees, and associated committee.`,
      },
      {
        title: "Committee Management",
        content: `Administrators can create and manage committees, assigning users to specific committees based on their roles. Required fields for committee creation include committee name, description, and purpose, while members can be added later as needed.`,
      },
      {
        title: "Resolutions",
        content: `Users can capture resolutions as outcomes of meetings, which remain in draft/pending status until approved.`,
      },
      {
        title: "Meeting Management",
        content: `The platform enables users to set up meetings with ease, including sending invites and specifying the location (online/physical - address or teams/zoom link).`,
      },
    ],
  },
  {
    title: "YourMove",
    intro: `
  YourMove is an innovative online
  marketplace designed to simplify the
  home moving process by providing
  users with a one-stop solution for all
  their relocation needs.`,
    content: `Whether you're looking to hire a moving company, rent a truck, or find packing supplies, YourMove has you covered. Our platform connects users with a network of trusted service providers, allowing you to compare prices, read reviews, and book services online. With YourMove, you can plan your move with confidence, knowing that you have access to reliable and affordable options. Try it today and experience the future of home moving!`,
    posts: [
      {
        title: "Access to Multiple Service Providers",
        content: `YourMove connects users with a wide
    range of service providers, including
    moving companies, packing services, and
    storage facilities, making it easy to find
    the right solutions for your unique
    requirements.`,
      },
      {
        title: "Efficient Scheduling",
        content: `The platform allows users to quickly and
    easily schedule moving dates, ensuring a
    seamless and well-coordinated relocation
    experience.`,
      },
      {
        title: "Instant Quotes Comparison",
        content: `YourMove enables users to receive and
    compare quotes from multiple services in
    less than a minute, helping them make
    informed decisions and choose the most
    cost-effective solutions for their needs.`,
      },
      {
        title: "Time and Effort",
        content: `By providing a centralized platform for all
    moving-based services, YourMove
    eliminates the need for users to research
    and contact-us multiple providers individually,
    saving them valuable time and effort.`,
      },
      {
        title: "Reliable and Trustworthy Providers",
        content: `YourMove carefully vets all service
    providers on the platform, ensuring that
    users have access to reliable, trustworthy,
    and professional services for a stress-free
    moving experience.`,
      },
    ],
  },
];

export default defaultProductsData;
