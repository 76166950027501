import React, { useRef, useEffect } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { Box } from "@react-three/drei";
import * as THREE from "three";

// Extend THREE to include the AudioAnalyser
extend({ AudioAnalyser: THREE.AudioAnalyser });

const AudioVisualizer = ({ audioBuffer }) => {
  const analyserRef = useRef();
  const bars = useRef([]);
  const { camera, scene } = useThree();
  const NUM_BARS = 32;
  const SPACING = 1.2;
  const BAR_WIDTH = 0.5;
  const MAX_BAR_HEIGHT = 5;

  useEffect(() => {
    // Create an audio listener and audio source
    const listener = new THREE.AudioListener();
    camera.add(listener);
    const audio = new THREE.Audio(listener);

    // Set the audio buffer
    audio.setBuffer(audioBuffer);

    // Create an analyser
    const analyser = new THREE.AudioAnalyser(audio, 32);
    analyserRef.current = analyser;

    // Start the audio
    audio.play();

    // Position the camera
    camera.position.z = 30;

    // Create bars
    for (let i = 0; i < NUM_BARS; i++) {
      const bar = new THREE.Mesh(
        new THREE.BoxGeometry(BAR_WIDTH, 1, 1),
        new THREE.MeshBasicMaterial({ color: 0x00ff00 })
      );
      bar.position.x = (i - NUM_BARS / 2) * SPACING;
      scene.add(bar);
      bars.current.push(bar);
    }

    return () => {
      // Stop the audio and clean up on component unmount
      audio.stop();
      bars.current.forEach((bar) => scene.remove(bar));
      camera.remove(listener);
    };
  }, [audioBuffer, camera, scene]);

  useFrame(() => {
    if (analyserRef.current) {
      const data = analyserRef.current.getFrequencyData();
      bars.current.forEach((bar, index) => {
        const scale = (data[index] / 256) * MAX_BAR_HEIGHT;
        bar.scale.y = Math.max(0.1, scale); // Avoid non-visible bars
      });
    }
  });

  return null; // This component does not render anything itself
};

export default AudioVisualizer;
