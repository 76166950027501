import axios from "axios";

const AuthApi = {
  //--url
  baseUrl: "https://api.soimagine.co.za/wp-json",
  namespace: "/sa9ecms/v1/",

  //--Login
  login: (data) => {
    let bodyParams = {
      email: data?.email,
      password: data?.password,
    };

    try {
      let req = axios.post(
        `${AuthApi.baseUrl}${AuthApi.namespace}users/login`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //--Register
  register: (data) => {
    let bodyParams = {
      username: data?.username,
      email: data?.email,
      password: data?.password,
    };

    try {
      let req = axios.post(
        `${AuthApi.baseUrl}${AuthApi.namespace}users/register`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return req;
    } catch (error) {
      console.log(error);
    }
  },

  //set user data in local storage
  setUser: (data) => {
    localStorage.setItem("currentUser", JSON.stringify(data));
  },
  
  //get user data from local storage
  getUser: () => {
    return JSON.parse(localStorage.getItem("currentUser"));
  },
};

export default AuthApi;
