import axios from "axios";

const BlogPostApi = {

  //--url
  baseUrl: 'https://api.soimagine.co.za/wp-json',
  namespace: '/sa9ecms/v1/',
  endpoint: 'blog-post',

  //--create
  create: (data) => {
    let bodyParams = {
      blog_title: data?.title,
      blog_content: data?.content,
      blog_status: data?.status,
      blog_author: data?.author,
      featured_image: data?.featured_image,
    };
    
    try {
      let req = axios.post(
        `${BlogPostApi.baseUrl}${BlogPostApi.namespace}${BlogPostApi.endpoint}`,
        bodyParams,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--get all
  getAll: async () => {
    try {
      return axios.get(
        `${BlogPostApi.baseUrl}${BlogPostApi.namespace}${BlogPostApi.endpoint}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.table(response.data);
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    }
    catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--get one
  getOne: async (id) => {
    try {
      return axios.get(
        `${BlogPostApi.baseUrl}${BlogPostApi.namespace}${BlogPostApi.endpoint}/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.table(response.data);
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error; // Propagate the error so it can be caught by the caller
        });
    }
    catch (error) {
      console.log(error);
      throw error; // Propagate the error so it can be caught by the caller
    }
  },

  //--update
  update: (data) => {
    let bodyParams = {
      blog_title: data?.title,
      blog_content: data?.content,
      blog_status: data?.status,
      blog_author: data?.author,
      featured_image: data?.featured_image,
    };
    
    try {
      let req = axios.put(
        `${BlogPostApi.baseUrl}${BlogPostApi.namespace}${BlogPostApi.endpoint}/${data?.id}`,
        bodyParams,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },

  //--delete
  delete: (id) => {
    try {
      let req = axios.delete(
        `${BlogPostApi.baseUrl}${BlogPostApi.namespace}${BlogPostApi.endpoint}/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
      return req;
    }
    catch (error) {
      console.log(error);
    }
  },
};

export default BlogPostApi;