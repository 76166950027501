import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Testimonial from '../Testimonial';
//Editor
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton, Card, CardContent, CardActions, Stack  } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";


export default function TestimonialSliderStyle2({ data }) {
  return (
    <div className="cs_testimonial_2_wrap cs_primary_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="cs_testimonial_thumb cs_bg_filed"
              style={{
                backgroundImage: `url(/images/tech-startup/testimonial_thumb.jpeg)`,
              }}
            />
          </div>
          <div className="col-lg-6">
            <div className="cs_height_150 cs_height_lg_80" />
            <Swiper
              slidesPerView={1}
              loop={true}
              speed={1000}
              modules={[Navigation]}
              navigation={{
                nextEl: '.cs_swiper_next',
                prevEl: '.cs_swiper_prev',
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Testimonial {...item} style2 />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="cs_swiper_navigation cs_style_1">
              <div className="cs_swiper_prev">
                <svg
                  width={42}
                  height={24}
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807612 11.0711 0.807612 10.4853 1.3934L0.93934 10.9393ZM42 10.5L2 10.5V13.5L42 13.5V10.5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="cs_swiper_next">
                <svg
                  width={42}
                  height={24}
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div className="cs_height_150 cs_height_lg_80" />
          </div>
        </div>
      </div>
    </div>
  );
}


// Define the editable version of your component
// Replace Slider6 with your component's name
export const EditableSlider6 = ({ props }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  // Component render
  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'transparent',
        cursor: hasDraggedNode ? 'grabbing' : 'grab',
        border: hasSelectedNode ? '2px solid #2196f3' : 'none',
        '&:hover': {
          border: !hasSelectedNode ? '1px dashed #2196f3' : '1px dashed #2196f3',
        },
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {
        // Show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* Component Start - Place your component's structure here */}


      {/* Component End */}

    </Box>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
// This is where you can add UI elements for editing component properties
const _ItemForm = ({ item, index, onChange, onRemove }) => {
  return (
    <Card key={index} sx={{ margin: 1 }}>
      <CardContent>
        <Stack spacing={2}>
          <Input
            label="Brand"
            value={item.brand}
            onChange={(e) => onChange('brand', e.target.value)}
          />

          <Input
            label="Title"
            value={item.title}
            onChange={(e) => onChange('title', e.target.value)}
          />

          <Input
            label="Sub Title"
            value={item.subTitle}
            onChange={(e) => onChange('subTitle', e.target.value)}
          />

          <Input
            type="date"
            label="Date"
            value={item.date}
            onChange={(e) => onChange('date', e.target.value)}
          />

          <FilePickerOpener
          onSelected={(file) => {
            onChange('awardImgUrl', file.url);
          }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <JButton size="sm" color="error" onClick={onRemove}>
          Remove
        </JButton>
      </CardActions>
    </Card>
  );
};

const _ItemRepeater = ({ items, onChange }) => {
  const addItem = () => {
    onChange([...items, { title: '', url: '' }]);
  };

  const removeItem = (index) => {
    onChange(items.filter((item, i) => i !== index));
  };

  const updateItem = (index, key, value) => {
    onChange(
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }

        return item;
      }),
    );
  };

  return (
    <Stack>
      {items.map((item, index) => (
        <_ItemForm
          key={index}
          item={item}
          index={index}
          onChange={(key, value) => updateItem(index, key, value)}
          onRemove={() => removeItem(index)}
        />
      ))}

      <JButton
        size="sm"
        color="primary"
        onClick={addItem}
      >
        Add Item
      </JButton>
    </Stack>
  );
};

// Define settings for your component if needed
// This is where you can add UI elements for editing component properties
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    data
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);

  //COMPONENT HANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Example setting: Changing text
  // This can be replaced or expanded based on the component's properties
  return (
    <>
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handleChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography level="title-sm">Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handleChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography level="title-sm">Data</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <_ItemRepeater
            items={data}
            onChange={(value) => setProp((props) => (props.data = value))}
          /> 
        </AccordionDetails>
      </Accordion>
    </>
  );
};
// Define CraftJS specific properties for your component
EditableSlider6.craft = {
  displayName: 'Slider6',
  props: {
    
  },
  related: {
    settings: ComponentSettings,
  },
};
