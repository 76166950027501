import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useCursor,
  MeshReflectorMaterial,
  Image,
  Text,
  Environment,
  Loader,
  Html,
  RoundedBox,
  useGLTF,
} from "@react-three/drei";
import { useRoute, useLocation } from "wouter";
import { easing } from "maath";
import getUuid from "uuid-by-string";
import { RotateLogo } from "./RotateLogo";
import defaultProductsData from "src/constants/products";

const GOLDENRATIO = 1.61803398875;

export default function GalleryScene() {
  //CONSTANTS
  const pexel = (id) =>
    `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`;

  const productIcons = [
    {
      name: "workwize",
      url: `${process.env.PUBLIC_URL}/soimagine/products/workwize_icon.png`,
    },
    {
      name: "boardflow",
      url: `${process.env.PUBLIC_URL}/soimagine/products/boardflow_icon.png`,
    },
    {
      name: "digim",
      url: `${process.env.PUBLIC_URL}/soimagine/products/digim_icon.png`,
    },
    {
      name: "yourmove",
      url: `${process.env.PUBLIC_URL}/soimagine/products/yourmove_icon.png`,
    },
  ];

  const product = (id) => `/soimagine/${id}`;

  const images = [
    // Front
    /* { position: [0, 0, 1.5], rotation: [0, 0, 0], url: product('BoardFlow_banner_sm.jpg') },
    // Back
    { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(416430) },
    { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(310452) }, */

    // Left
    {
      position: [-1.7, 0, 1.5],
      rotation: [0, Math.PI / 8, 0],
      url: productIcons[1].url,
    },
    {
      position: [-2, 0, 2.9],
      rotation: [0, Math.PI / 2.5, 0],
      url: productIcons[0].url,
    },

    // Right
    {
      position: [1.7, 0, 1.5],
      rotation: [0, -Math.PI / 8, 0],
      url: productIcons[3].url,
    },
    {
      position: [2, 0, 2.9],
      rotation: [0, -Math.PI / 2.5, 0],
      url: productIcons[2].url,
    },
  ];

  const soimagineProducts = [
    {
      id: "workwize",
      name: "Workwize",
      position: [-1.7, 0, 1.5],
      rotation: [0, Math.PI / 8, 0],
      scale: [0.03, 0.03, 0.03],
      content: {
        title: "Workwize",
        description: "Workwize is a platform that helps you manage your work.",
        image: productIcons[1].url,
        url: "https://workwize.soimagine.com",
      },
    },
    {
      id: "boardflow",
      name: "Boardflow",
      position: [-2, 0, 2.9],
      rotation: [0, Math.PI / 2.5, 0],
      scale: [0.03, 0.03, 0.03],
      content: {
        title: "Boardflow",
        description:
          "Boardflow is a platform that helps you manage your board and team.",
        image: productIcons[0].url,
        url: "https://boardflow.soimagine.com",
      },
    },
    {
      id: "digim",
      name: "Digim",
      position: [1.7, 0, 1.5],
      rotation: [0, -Math.PI / 8, 0],
      scale: [0.03, 0.03, 0.03],
      content: {
        title: "Digim",
        description:
          "Digim is a platform that helps you manage your digital assets.",
        image: productIcons[3].url,
        url: "https://digim.soimagine.com",
      },
    },
    {
      id: "yourmove",
      name: "Your Move",
      position: [2, 0, 2.9],
      rotation: [0, -Math.PI / 2.5, 0],
      scale: [0.1, 0.1, 0.1],
      content: {
        title: "Your Move",
        description:
          "Your Move is a platform that helps you manage your moves.",
        image: productIcons[2].url,
        url: "https://yourmove.soimagine.com",
      },
    },
  ];

  return (
    <>
      {/*The Canvas component is the root of the scene. It sets up the WebGL context and the render loop.*/}
      <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [4, 2, 15] }}>
        <color attach="background" args={["#0E121E"]} />
        <fog attach="fog" args={["#191920", 0, 15]} />

        {/*Add Proper Lighting to the Scene */}
        <ambientLight intensity={0.5} />
        <directionalLight intensity={0.5} position={[0, 10, 0]} />

        {/* The group component is a container for other components. */}
        <group position={[0, -0.5, 0]}>
          {/* The Frames component is a container for the Frame components. */}
          {/* <Frames images={images} products={defaultProductsData} /> */}

          {/* The LaptopsGallery component is a container for the LaptopModel components. */}
          <LaptopsGallery soimagineProducts={soimagineProducts} />

          {/* The RotateLogo component is a 3D model of the logo. */}
          <RotateLogo position={[0, 4, -5.0]} scale={[0.1, 0.1, 0.1]} />

          {/* This is the floor of the scene. */}
          <mesh rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[50, 50]} />
            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={512}
              mixBlur={1}
              mixStrength={80}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color="#050505"
              metalness={0.5}
            />
          </mesh>
        </group>

        {/* The Environment component adds a background to the scene. */}
        <Environment preset="night" />
      </Canvas>
    </>
  );
}

/* This component is a container for the Frame components. */
// function Frames({
//   images,
//   products,
//   q = new THREE.Quaternion(),
//   p = new THREE.Vector3(),
// }) {
//   //The ref hook is used to access the group object.
//   const ref = useRef();

//   //The clicked ref is used to store the object that was clicked.
//   const clicked = useRef();

//   const infoPanelRef = useRef();

//   //The useRoute hook is used to access the route parameters.
//   const [, params] = useRoute("/item/:id");

//   //The useLocation hook is used to access the location object.
//   const [, setLocation] = useLocation();

//   //The useEffect hook is used to update the clicked ref.
//   useEffect(() => {
//     //When the id parameter is present, the clicked ref is updated with the object that has the same name as the id.
//     clicked.current = ref.current.getObjectByName(params?.id);
//     if (clicked.current) {
//       //The updateWorldMatrix method is called to update the world matrix of the object.
//       clicked.current.parent.updateWorldMatrix(true, true);

//       //The localToWorld method is called to convert the local position of the object to world position.
//       clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25));

//       //The getWorldQuaternion method is called to get the world quaternion of the object.
//       clicked.current.parent.getWorldQuaternion(q);
//     } else {
//       //Otherwise, the position and quaternion are reset.
//       p.set(0, 0.5, 5);

//       //The identity method is called to reset the quaternion.
//       q.identity();
//     }
//   });

//   //The useFrame hook is used to animate the camera object. It is called every frame.
//   //The callback function is called with the state and dt parameters.
//   //The state parameter is an object that contains the clock, camera, scene, and gl objects.
//   //The dt parameter is the time in seconds since the last frame.
//   useFrame((state, dt) => {
//     //The damp3 method is called to animate the position of the camera object.
//     easing.damp3(state.camera.position, p, 0.4, dt);

//     //The dampQ method is called to animate the quaternion of the camera object.
//     easing.dampQ(state.camera.quaternion, q, 0.4, dt);
//   });
//   return (
//     <group
//       ref={ref}
//       onClick={(e) => (
//         //When the object is clicked, the location is updated with the name of the object.
//         e.stopPropagation(),
//         setLocation(
//           clicked.current === e.object ? "/" : "/item/" + e.object.name
//         )
//       )}
//       //If the pointer misses the object, the location is updated with the root path.
//       //Hence, the object is deselected and changes the camera position and quaternion to the default values.
//       onPointerMissed={() => setLocation("/")}
//     >
//       {images.map(
//         (props) => <Frame key={props.url} {...props} /> /* prettier-ignore */
//       )}

//       {/* If the clicked ref is not null, the InfoPanel component is rendered. */}
//       {/* {clicked.current && (
//         <InfoPanel
//           ref={infoPanelRef}
//           product={products.find((p) => p.id === clicked.current.name)}
//         />
//       )} */}
//     </group>
//   );
// }

function LaptopsGallery({
  soimagineProducts,
  q = new THREE.Quaternion(),
  p = new THREE.Vector3(),
}) {
  //The ref hook is used to access the group object.
  const ref = useRef();

  //The clicked ref is used to store the object that was clicked.
  const clicked = useRef();

  const infoPanelRef = useRef();

  //The useRoute hook is used to access the route parameters.
  const [, params] = useRoute("/item/:id");

  //The useLocation hook is used to access the location object.
  const [, setLocation] = useLocation();

  //The useEffect hook is used to update the clicked ref.
  useEffect(() => {
    //When the id parameter is present, the clicked ref is updated with the object that has the same name as the id.
    clicked.current = ref.current.getObjectByName(params?.id);
    if (clicked.current) {
      //The updateWorldMatrix method is called to update the world matrix of the object.
      clicked.current.parent.updateWorldMatrix(true, true);

      //The localToWorld method is called to convert the local position of the object to world position.
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25));

      //The getWorldQuaternion method is called to get the world quaternion of the object.
      clicked.current.parent.getWorldQuaternion(q);
    } else {
      //Otherwise, the position and quaternion are reset.
      p.set(0, 0.5, 5);

      //The identity method is called to reset the quaternion.
      q.identity();
    }
  });

  //The useFrame hook is used to animate the camera object. It is called every frame.
  //The callback function is called with the state and dt parameters.
  //The state parameter is an object that contains the clock, camera, scene, and gl objects.
  //The dt parameter is the time in seconds since the last frame.
  useFrame((state, dt) => {
    //The damp3 method is called to animate the position of the camera object.
    easing.damp3(state.camera.position, p, 0.4, dt);

    //The dampQ method is called to animate the quaternion of the camera object.
    easing.dampQ(state.camera.quaternion, q, 0.4, dt);
  });
  return (
    <group
      ref={ref}
      onClick={(e) => (
        //When the object is clicked, the location is updated with the name of the object.
        e.stopPropagation(),
        setLocation(
          clicked.current === e.object ? "/" : "/item/" + e.object.name
        )
      )}
      //If the pointer misses the object, the location is updated with the root path.
      //Hence, the object is deselected and changes the camera position and quaternion to the default values.
      onPointerMissed={() => setLocation("/")}
    >
      {soimagineProducts.map((product) => (
        <LaptopModel key={product.id} product={product} />
      ))}

      {/* If the clicked ref is not null, the InfoPanel component is rendered. */}
      {/* {clicked.current && (
        <InfoPanel
          ref={infoPanelRef}
          product={products.find((p) => p.id === clicked.current.name)}
        />
      )} */}
    </group>
  );
}

/* This component is a container for the Image component. */
// function Frame({ url, c = new THREE.Color(), ...props }) {
//   //The useRef hook is used to access the image and frame objects.
//   const image = useRef();
//   const frame = useRef();

//   //The useRoute hook is used to access the route parameters.
//   const [, params] = useRoute("/item/:id");

//   //The useState hook is used to store the hovered state.
//   const [hovered, hover] = useState(false);

//   //The useState hook is used to store the random number.
//   const [rnd] = useState(() => Math.random());

//   //The getUuid function is used to generate a UUID from the url.
//   const name = getUuid(url);

//   //The isActive variable is used to reference the object that is active.
//   const isActive = params?.id === name;

//   //The useCursor hook is used to check cursor events.
//   //in this case, the cursor is changed when the object is hovered.
//   useCursor(hovered);

//   //The useFrame hook is used to animate the image and frame objects. It is called every frame.
//   //In this case, the image is zoomed in and out, and the frame is colored when the object is hovered.
//   //the callback function is called with the state and dt parameters.
//   //The state parameter is an object that contains the clock, camera, scene, and gl objects.
//   //The dt parameter is the time in seconds since the last frame.
//   useFrame((state, dt) => {
//     //The zoom property of the material object is updated with a sine wave.
//     //image.current.material.zoom = 0 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2;

//     //The damp3 method is called to animate the scale of the image object.
//     //The scale is set to 0.85, 0.9, or 1 depending on the isActive and hovered states.
//     easing.damp3(
//       image.current.scale,
//       [
//         0.85 * (!isActive && hovered ? 0.85 : 1), // x
//         0.9 * (!isActive && hovered ? 0.905 : 1), // y
//         1, // z
//       ],
//       0.1, // speed
//       dt
//     );

//     //The dampC method is called to animate the color of the frame object.
//     //The color is set to red or white depending on the hovered state.
//     easing.dampC(
//       frame.current.material.color,
//       hovered ? "red" : "white",
//       0.1, // speed
//       dt // delta time
//     );
//   });

//   //The return statement renders the Frame component.
//   return (
//     <group {...props}>
//       {/* The mesh component is a container for the boxGeometry and meshStandardMaterial components. */}
//       <mesh
//         name={name}
//         onPointerOver={(e) => (e.stopPropagation(), hover(true))}
//         onPointerOut={() => hover(false)}
//         scale={[1, GOLDENRATIO, 0.05]}
//         position={[0, GOLDENRATIO / 2, 0]}
//       >
//         {/* The boxGeometry component is a cube with a width, height, and depth of 1. 
//       The meshStandardMaterial component is a material with a color of #151515, a metalness of 0.5, and a roughness of 0.5.
//       */}
//         <RoundedBox
//           args={[1, GOLDENRATIO, 0.05]}
//           radius={0.02}
//           smoothness={4}
//         />
//         <meshStandardMaterial
//           color="#151515"
//           metalness={0.5}
//           roughness={0.5}
//           envMapIntensity={2}
//         />
//         {/* The mesh component is a container for the boxGeometry and meshBasicMaterial components. */}
//         <mesh
//           ref={frame}
//           raycast={() => null}
//           scale={[0.9, 0.93, 0.9]}
//           position={[0, 0, 0.2]}
//         >
//           <RoundedBox
//             args={[1, GOLDENRATIO, 0.05]}
//             radius={0.02}
//             smoothness={4}
//           />
//           <meshBasicMaterial toneMapped={false} fog={false} />
//         </mesh>
//         {/* The image component is a container for the planeGeometry and meshBasicMaterial components. */}
//         <Image
//           raycast={() => null}
//           transparent
//           ref={image}
//           position={[0, 0, 0.7]}
//           url={url}
//         />
//       </mesh>
//       {/* <Text
//         maxWidth={0.1}
//         anchorX="left"
//         anchorY="top"
//         position={[0.55, GOLDENRATIO, 0]}
//         fontSize={0.025}
//       >
//         {name.split("-").join(" ")}
//       </Text> */}
//     </group>
//   );
// }

/* This component is a container for the LaptopModel component. */
function LaptopModel({ product }) {

  //The position and rotation properties are used to set the position and rotation of the group object.
  const { position, rotation, scale } = product;

  //The useGLTF hook is used to load the gltf object.
  const { nodes, materials } = useGLTF(
    `/soimagine/models/mac-draco.glb`
  );

  //The useRef hook is used to access the group object.
  const ref = useRef();

  //The useState hook is used to store the hovered state.
  const [hovered, hover] = useState(false);

  //The useState hook is used to store the clicked state.
  const [clicked, click] = useState(false);

  //The useFrame hook is used to animate the group object. It is called every frame.
  //The callback function is called with the state and dt parameters.
  //The state parameter is an object that contains the clock, camera, scene, and gl objects.
  //The dt parameter is the time in seconds since the last frame.
  useFrame((state, dt) => {
    //The damp3 method is called to animate the scale of the group object.
    //The scale is set to 0.1 or 0.3 depending on the hovered and clicked states.
    easing.damp3(
      ref.current.scale,
      [
        0.1 * (!clicked && hovered ? 0.3 : 1), // x
        0.1 * (!clicked && hovered ? 0.3 : 1), // y
        0.1 * (!clicked && hovered ? 0.3 : 1), // z
      ],
      0.1, // speed
      dt // delta time
    );

    //The dampQ method is called to animate the quaternion of the group object.
    //The quaternion is set to the identity quaternion or the world quaternion depending on the hovered and clicked states.
    easing.dampQ(
      ref.current.quaternion,
      clicked ? state.camera.quaternion : new THREE.Quaternion(),
      0.1, // speed
      dt // delta time
    );
  });

  //The return statement renders the LaptopModel component.
  return (
    <group 
    ref={ref}  
    dispose={null}
    //onPointerOver={(e) => (e.stopPropagation(), hover(true))}
    onPointerOut={() => hover(false)}
    onPointerDown={(e) => (e.stopPropagation(), click(!clicked))}

    position={position}
    scale={scale}
    >

    <group rotation={rotation}>
    {/* Laptop Screen */}
    <group>
      <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          material={materials.aluminium}
          geometry={nodes["Cube008"].geometry}
        />
        <mesh
          material={materials["matte.001"]}
          geometry={nodes["Cube008_1"].geometry}
        />
        <mesh geometry={nodes["Cube008_2"].geometry}>
          {/* Drei's HTML component can "hide behind" canvas geometry */}
          <Html
            className="content"
            rotation-x={-Math.PI / 2}
            position={[0, 0.05, -0.09]}
            transform
            occlude
          >
            <div
              className="wrapper"
              onPointerDown={(e) => e.stopPropagation()}
            >
              <ProductCoverHTML product={product} />
            </div>
          </Html>
        </mesh>
      </group>
    </group>

    {/* Laptop Keyboard: Mesh */}
    <mesh
      material={materials.keys}
      geometry={nodes.keyboard.geometry}
      position={[1.79, 0, 3.45]}
    />

    {/* Laptop Body: (Aluminium cover & Trackpad) */}
    <group position={[0, -0.1, 3.39]}>
      <mesh
        material={materials.aluminium}
        geometry={nodes["Cube002"].geometry}
      />
      <mesh
        material={materials.trackpad}
        geometry={nodes["Cube002_1"].geometry}
      />
    </group>

    {/* Laptop Body: Mesh (Aluminium cover & Trackpad) */}
    <mesh
      material={materials.touchbar}
      geometry={nodes.touchbar.geometry}
      position={[0, -0.03, 1.2]}
    />
    </group>
  </group>
  );
}

/* This Component is The Information Panel
- It appears when an object is clicked. 
- It Render HTML content (drei HTML component) That contains the information about the object.
*/
function InfoPanel({ product }) {
  return (
    <group position={[0, 0.5, 0]}>
      <Html center>
        <div style={{ width: "100%", height: "100%", padding: "20px" }}>
          <h1>{product.name}</h1>
          <p>{product.description}</p>
        </div>
      </Html>
    </group>
  );
}

/* This Component Renders a Laptop Model With a Screen That Displays the Image */

const ProductCoverHTML = ({ product }) => {
  return (
    <div
      className="product-cover"
      style={{
        backgroundImage: `url(${product.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};
