import React, { useState, useEffect, Suspense } from "react";
import { Icon } from "@iconify/react";
import { Stack, TextField, Button, Box, Typography } from "@mui/material";
//Web Worker
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
//formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";
import AudioResponseScene from "../reactThree/AudioResponseScene";
import { Canvas } from "@react-three/fiber";
import AudioVisualizer from "../reactThree/AudioVisualizer";
import ChatBot, { EveBot_charecter } from "../reactThree/EveBot";
import { motion } from "framer-motion";
import { Stage } from "@react-three/drei";

//WORKER
const createAssistantWorker = createWorkerFactory(() =>
  import("../../workers/AIWorker")
);
const createTTSWorker = createWorkerFactory(() =>
  import("../../workers/TTSworker")
);

const ChatInterface = () => {
  //WEB WORKER
  const assistantWorker = useWorker(createAssistantWorker);
  const ttsWorker = useWorker(createTTSWorker);

  //FORMIK
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log(values);

      handleSendMessage(values);
    },
  });

  //STATES
  //assistant
  const [assistant, setAssistant] = useState(null);
  //--messages and message thread
  const [messageThread, setMessageThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  //--run function
  const [currentRunSteps, setCurrentRunSteps] = useState([]);
  //--settings
  const [ttsMode, setTTSMode] = useState(false);
  const [ttsVoice, setTTSVoice] = useState("alloy");
  const [ttsSpeed, setTTSSpeed] = useState(1);
  //--audio
  const [audioUrl, setAudioUrl] = useState(null);

  //--animation
  const [animationToPlay, setAnimationToPlay] = useState("idle");

  const handleUserWelcome = () => {
    const welcomeMessage = "Hello, I am Eve. How can I help you today?";
    //1: generate TTS
    let speech = ttsWorker.generateSpeech(welcomeMessage);
    //2: set the audio url
    setAudioUrl(speech);
    //3:
  };

  //FUNCTIONS
  //--handle animation
  const handleAnimation = () => {
    setAnimationToPlay("BodyAction");
  };

  //--handle message sending
  const handleSendMessage = async (values) => {
    //1: set isSendingMessage to true
    setIsSendingMessage(true);
    console.log("Sending Message");

    //2: check if the message thread is null or not
    if (messageThread === null) {
      console.log("Creating New Thread");

      //2.1: if null, create a new message thread
      let newThread = await assistantWorker.CreateNewThread();
      setMessageThread(newThread);

      //2.2: send the message to the thread
      let newMessage = await assistantWorker.CreateNewMessage(
        newThread.id,
        values.message
      );
      setMessages([...messages, newMessage]);

      //2.3: set isSendingMessage to false
      setIsSendingMessage(false);

      //wait for response
      //2.4.1: set isResponding to true
      setIsResponding(true);
      //2.4.2: start the Assistant Run Function
      assistantWorker.CreateNewRun(newThread.id, assistant.id)
      .then((run) => {
        handleRunResponse(newThread, run);
      })
      .catch((error) => {
        console.log(error);
      });
      
    } else {
      //3: if not null, send the message to the thread

      console.log("Sending Message to Thread");

      let newMessage = await assistantWorker.CreateNewMessage(
        messageThread.id,
        values.message
      );
      setMessages([...messages, newMessage]);

      //3.1: set isSendingMessage to false
      setIsSendingMessage(false);

      //wait for response
      //3.2.1: set isResponding to true
      setIsResponding(true);
      //3.2.2: start the Assistant Run Function
      assistantWorker.CreateNewRun(
        messageThread.id,
        assistant.id
      )
      .then((run) => {
        handleRunResponse(messageThread, run);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  //--handle run response
  //----periodically get the run and Check if the run is complete
  //----if Not, Update the run steps if any new are found
  //----if complete, stop checking and handle the response
  const handleRunResponse = async (thread, run) => {
    let currentRun = await assistantWorker.GetRunByID(thread.id, run.id);

    if (currentRun === null) {
      //stop checking
      return;
    }

    //setup interval to check the run
    let interval = setInterval(() => {
      //check if the run is complete
      if (currentRun.status !== "complete") {
        //stop checking
        clearInterval(interval);

        //List the steps
        handleRunSteps(currentRun);

      } else {
        //update the run steps
        setCurrentRunSteps([...currentRun.steps]);
      }
    }, 3500);
  };

  //--handle message response
  const handleRunSteps = (run) => {

    assistantWorker.GetStepsByRunID(run.id)
    .then((steps) => {
      setCurrentRunSteps(steps);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  //EFFECTS
  useEffect(() => {
    //1: create assistant
    assistantWorker
      .CheckAndCreateAssistant()
      .then((default_asst) => {
        setAssistant(default_asst);
      })
      .catch((error) => {
        alert("No Assistant Found");
      });
  }, []);

  //handle Run Step Changes
  useEffect(() => {
    if (currentRunSteps.length > 0) {
      //runsteps is an array of steps for openAi assistant
      //1: check if the last step has a propeprty called "type" with value "message_creation
      let lastStep = currentRunSteps[currentRunSteps.length - 1];

      //2: we get a human readable by getting the message from the step - from step_details
      //  2.1: get the message id
      let message = lastStep.step_details.message_creation.message_id;
      //  2.2: get the message
      let messageDetails = assistantWorker.GetMessageByID(message);
      //  2.3: get the message content
      let messageValue = message.content.text.value;

      //3: Set the message to the current message
      setCurrentMessage(messageValue);

      //Now we can use the message to do Generate TTS and Play the TTS
      //3: generate TTS
      let ttsFile = ttsWorker.generateSpeech(messageValue);

      //4: set the audio url
      setAudioUrl(ttsFile);
    }
  }, [currentRunSteps]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255,255,255,0.1)",
        padding: "10px",
        borderRadius: "3px",
      }}
    >
      <Stack direction="row" spacing={2}>
        {/*  Chatbot Model */}
        <div
          style={{
            height: "180px",
            width: "100px",
            position: "relative",
          }}
        >
          <Canvas camera={{ position: [0, 0, 1], fov: 50 }}>
            <Stage
              intensity={1}
              contactShadow
              shadows
              adjustCamera
              environment="city"
              preset={"portrait"}
            >
              <EveBot_charecter onAnimate={animationToPlay} />
            </Stage>
          </Canvas>
        </div>

        {/* Response Message Box */}
        <div
          style={{
            height: "180px",
            width: "400px",
            backgroundColor: "rgba(255,255,255,0.2)",
            border: "1px solid white",
            color: "white",
            padding: "10px",
            borderRadius: "3px",
            overflow: "auto",
            margin: "10px 2px",
          }}
        >
          <Typography variant="h6">
            {
              currentMessage
                ? currentMessage
                : "Hello, I am Eve. How can I help you today?"
            } 
          </Typography>
        </div>
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          id="message"
          variant="outlined"
          size="small"
          multiline
          rows={1}
          placeholder="Type your message"
          fullWidth
          sx={{
            //White border
            borderColor: "white",
            //white text
            color: "white",
            //white placeholder
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
              bgcolor: "rgba(255,255,255,0.2)",
            },

            //white border when focused
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "white",
                bgcolor: "rgba(255,255,255,0.2)",
              },
            //white text when focused
            "& .MuiOutlinedInput-input.Mui-focused .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                color: "white",
              },
            //white placeholder when focused
            "& .MuiInputLabel-outlined.Mui-focused .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                color: "white",
              },
          }}
          onChange={formik.handleChange}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
          sx={{
            //red Outline
            bgcolor: "rgba(255,255,255,0.2)",
            borderColor: "white",


            //white border when focused
            "& .MuiButton-contained": {
              bgcolor: "rgba(255,255,255,0.2)",
            },
          }}
        >
          <Icon
            icon={
              isResponding
                ? "svg-spinners-3-dots-move"
                : "carbon:send-alt-filled"
            }
            color="#f90000"
            width="24"
            height="24"
          />
        </Button>
      </Stack>
    </Box>
  );
};

const CurrentRunViewer = ({ currentRun }) => {
  return (
    <div>
      <h3>Current Run</h3>
      <p>{currentRun.id}</p>
      <p>{currentRun.isComplete ? "Complete" : "Not Complete"}</p>
    </div>
  );
};

export default ChatInterface;
