import React from "react";
import PageTransition from "src/components/Framer/PageTransitions";
import TestimonialSliderStyle2 from "src/components/Slider/TestimonialSliderStyle2";
import Spacing from "src/components/Spacing";
import { pageTitle } from "src/helpers/PageTitle";
import defaultProductData from "src/constants/products";
import { ProjectSlider1 } from "./Components/ProjectSlider";


export default function ProductsPage() {

  pageTitle('Products');

  //CONSTANTS

  //STATES
  const [loading, setLoading] = React.useState(true);
  const [productData, setProductData] = React.useState(defaultProductData);

  //FUNCTIONS
  //Get the data from the API
  const getData = async () => {
    try {
      //Simulate a delay
      setTimeout(() => {
        setProductData(defaultProductData);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  
  //EFFECTS
  React.useEffect(() => {
    getData();
  }, []);

  //RENDER
  return (
    <PageTransition>
      <Spacing lg="140" md="80" />

      <>
      {
        loading 
        ? 
        <div>Loading...</div> 
        :
        <ProjectSlider1 data={1}/>
      }
      </>
    </PageTransition>
  );
}