import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

import { Icon } from "@iconify/react";

export default function DashboardOverview() {
  return (
    <Box sx={{ minHeight: "100vh" }}>

      {/* Breadcrumbs / Actions */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={
            <Icon
              icon="carbon:chevron-right"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          }
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <Icon
              icon="carbon:home"
              style={{ fontSize: "12px", color: "#6B7280" }}
            />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href="#some-link"
            fontSize={12}
            fontWeight={500}
          >
            Dashboard
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Overview
          </Typography>
        </Breadcrumbs>

        <Button
          color="primary"
          startDecorator={
            <Icon
              icon="mdi:download"
              style={{ fontSize: "16px", color: "#fff" }}
            />
          }
          size="sm"
        >
          Export
        </Button>
      </Box>

      {/* Page Title */}
      {/* <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      > 
      </Box> */}

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
        }}
      >
        {/* Content */}
      </Box>
      
    </Box>
  );
}
