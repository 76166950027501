import { Icon } from "@iconify/react";
import React from "react";
import Button from "../Button";
import Spacing from "../Spacing";
import { Parallax } from "react-parallax";

//Editor 
import { useNode } from "@craftjs/core";
import { Box, Divider, Input, Typography, Button as JButton } from "@mui/joy";
import { ComponentActions } from "src/dashboard/modules/pages/designer/editorComponents/ComponentActions";
import { motion } from "framer-motion";
import FileUploader from "src/dashboard/components/FileUploader";
import { FilePickerOpener } from "src/dashboard/components/FilePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "src/dashboard/modules/pages/designer/components/EditorAccordion";
import RichTextEditor from "src/dashboard/modules/pages/designer/editorComponents/editorFields/RichTextEditor";
import LinkComponentSettings from "src/dashboard/modules/pages/designer/editorComponents/fieldSettings/LinkComponentSettings";

export default function AboutStyle2({
  thumbnailSrc1,
  thumbnailSrc2,
  uperTitle,
  title,
  subTitle,
  featureList,
  btnText,
  btnUrl,
}) {
  return (
    <div className="container">
      <div className="row cs_gap_y_40 align-items-center">
        <div className="col-lg-6">
          <div className="cs_section_heading cs_style_1">
            <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
              {uperTitle}
            </p>
            <Spacing lg="10" md="5" />
            <h2 className="cs_section_title cs_fs_50 mb-0">{title}</h2>
            <Spacing lg="30" md="30" />
            <p className="m-0">{subTitle}</p>
          </div>
          <Spacing lg="40" md="30" />
          <ul className="cs_list cs_style_1 cs_primary_font cs_primary_color cs_semibold cs_mp0 cs_fs_21">
            {featureList?.map((item, index) => (
              <li key={index}>
                <i className="d-flex">
                  <Icon icon="fa6-regular:circle-check" />
                </i>
                {item}
              </li>
            ))}
          </ul>
          <Spacing lg="40" md="30" />
          <Button btnText={btnText} btnUrl={btnUrl} />
        </div>
        <div className="col-lg-5 offset-lg-1">
          <div className="cs_img_card_3_wrap cs_parallax">
            <div className="row">
              <div className="col-6">
                <div className="cs_img_card cs_style_3 cs_radius_15 overflow-hidden">
                  <Parallax
                    bgImage={thumbnailSrc1}
                    bgImageAlt="Thumb"
                    strength={-100}
                    className="h-100"
                  ></Parallax>
                </div>
              </div>
              <div className="col-6">
                <div className="cs_img_card cs_style_3 cs_radius_15 overflow-hidden">
                  <Parallax
                    bgImage={thumbnailSrc2}
                    bgImageAlt="Thumb"
                    strength={100}
                    className="h-100"
                  ></Parallax>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Editable Version
export function EditableAboutStyle2({
  thumbnailSrc1,
  thumbnailSrc2,
  uperTitle,
  title,
  subTitle,
  featureList,
  btnText,
  btnUrl,
}) {
  //CONSTANTS
  const {
    connectors: { connect, drag },
    actions: { setProp },
    hasSelectedNode,
    hasDraggedNode,
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <Box
      className="editor-component"
      ref={(ref) => connect(drag(ref))}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "transparent",
        cursor: hasDraggedNode ? "grabbing" : "grab",
        border: hasSelectedNode ? "2px solid #2196f3" : "none",
        "&:hover": {
          border: !hasSelectedNode
            ? "1px dashed #2196f3"
            : "1px dashed #2196f3",
        },
        transition: "border 0.3s ease-in-out",
      }}
    >
      {
        //--show the component actions if the component is selected
        hasSelectedNode && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <ComponentActions />
          </motion.div>
        )
      }

      {/* COMPONENT STARTS HERE */}
      <div className="container">
        <div className="row cs_gap_y_40 align-items-center">
          <div className="col-lg-6">
            <div className="cs_section_heading cs_style_1">
              <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
                {uperTitle}
              </p>
              <Spacing lg="10" md="5" />
              <h2 className="cs_section_title cs_fs_50 mb-0">{title}</h2>
              <Spacing lg="30" md="30" />
              <p className="m-0">{subTitle}</p>
            </div>
            <Spacing lg="40" md="30" />
            <ul className="cs_list cs_style_1 cs_primary_font cs_primary_color cs_semibold cs_mp0 cs_fs_21">
              {featureList?.map((item, index) => (
                <li key={index}>
                  <i className="d-flex">
                    <Icon icon="fa6-regular:circle-check" />
                  </i>
                  {item}
                </li>
              ))}
            </ul>
            <Spacing lg="40" md="30" />
            <Button btnText={btnText} btnUrl={btnUrl} />
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="cs_img_card_3_wrap cs_parallax">
              <div className="row">
                <div className="col-6">
                  <div className="cs_img_card cs_style_3 cs_radius_15 overflow-hidden">
                    <Parallax
                      bgImage={thumbnailSrc1}
                      bgImageAlt="Thumb"
                      strength={-100}
                      className="h-100"
                    ></Parallax>
                  </div>
                </div>
                <div className="col-6">
                  <div className="cs_img_card cs_style_3 cs_radius_15 overflow-hidden">
                    <Parallax
                      bgImage={thumbnailSrc2}
                      bgImageAlt="Thumb"
                      strength={100}
                      className="h-100"
                    ></Parallax>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

// Block Settings
const ComponentSettings = () => {
  //CRAFTS - NODE
  const {
    actions: { setProp },
    thumbnailSrc1,
    thumbnailSrc2,
    uperTitle,
    title,
    subTitle,
    featureList,
    btnText,
    btnUrl,
  } = useNode((node) => ({
    thumbnailSrc1: node.data.props.thumbnailSrc1,
    thumbnailSrc2: node.data.props.thumbnailSrc2,
    uperTitle: node.data.props.uperTitle,
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    featureList: node.data.props.featureList,
    btnText: node.data.props.btnText,
    btnUrl: node.data.props.btnUrl,
  }));

  //COMPONENT STATES
  const [expanded, setExpanded] = React.useState(false);
  const [dataItems, setDataItems] = React.useState();

  //COMPONENT HANDLERS
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //REPEATABLE DATA HANDLERS
  //--add growth data item
  const addDataItem = () => {
    setDataItems((items) => [
      ...items,
      {
        title: "Title",
        percentage: "0",
      },
    ]);
  };
  //--remove growth data item
  const removeDataItem = (index) => {
    setDataItems((items) => items.filter((item, i) => i !== index));
  };
  //--update growth data item
  const updateDataItem = (index, key, value) => {
    setDataItems((items) =>
      items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  //COMPONENT EFFECTS
  React.useEffect(() => {
    setProp((prop) => (prop.featureList = dataItems));
  }, [dataItems]);

  return (
    <>
      {/*MEDIA SETTINGS*/}
      <Accordion
        expanded={expanded === `panel-${0}`}
        onChange={handlePanelChange(`panel-${0}`)}
      >
        <AccordionSummary
          aria-controls={`panel${0}-content`}
          id={`panel${0}-header`}
        >
          <Typography>Media</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <FilePickerOpener
            onSelected={(file) => {
              setProp((prop) => (prop.thumbnailSrc1 = file.url));
            }}
          />

          <Divider />

          <FilePickerOpener
            onSelected={(file) => {
              setProp((prop) => (prop.thumbnailSrc2 = file.url));
            }}
          />
        </AccordionDetails>
      </Accordion>

      {/*TYPOGRAPHY SETTINGS*/}
      <Accordion
        expanded={expanded === `panel-${1}`}
        onChange={handlePanelChange(`panel-${1}`)}
      >
        <AccordionSummary
          aria-controls={`panel${1}-content`}
          id={`panel${1}-header`}
        >
          <Typography>Typography</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 1,
            }}
          >
            <Typography level="title-sm">Upper Title</Typography>
            <Input
              size="sm"
              placeholder="Upper Title"
              value={uperTitle}
              onChange={(e) =>
                setProp((prop) => (prop.uperTitle = e.target.value))
              }
            />

            <Divider />

            <Typography level="title-sm">Title</Typography>
            <Input
              size="sm"
              placeholder="Title"
              value={title}
              onChange={(e) => setProp((prop) => (prop.title = e.target.value))}
            />

            <Divider />

            <Typography level="title-sm">Subtitle</Typography>
            <Input
              size="sm"
              placeholder="Subtitle"
              value={subTitle}
              onChange={(e) =>
                setProp((prop) => (prop.subTitle = e.target.value))
              }
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*FEATURE LIST SETTINGS*/}
      <Accordion
        expanded={expanded === `panel-${3}`}
        onChange={handlePanelChange(`panel-${3}`)}
      >
        <AccordionSummary
          aria-controls={`panel${3}-content`}
          id={`panel${3}-header`}
        >
          <Typography>Feature List</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
        >
          {dataItems?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 1,
              }}
            >
              <Typography level="title-sm">Feature {index + 1}</Typography>
              <Input
                size="sm"
                placeholder="Feature Title"
                value={item.title}
                onChange={(e) => updateDataItem(index, "title", e.target.value)}
              />

              <Divider />

              <Button
                size="sm"
                onClick={() => removeDataItem(index)}
                sx={{ alignSelf: "flex-end" }}
              >
                Remove
              </Button>
            </Box>
          ))}

          <JButton size="sm" fullWidth onClick={addDataItem}>
            Add Feature
          </JButton>
        </AccordionDetails>
      </Accordion>

      {/*BUTTON SETTINGS*/}
      <Accordion
        expanded={expanded === `panel-${4}`}
        onChange={handlePanelChange(`panel-${4}`)}
      >
        <AccordionSummary
          aria-controls={`panel${4}-content`}
          id={`panel${4}-header`}
        >
          <Typography>Button</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
        >
          <Typography level="title-sm">Button Text</Typography>
          <Input
            size="sm"
            placeholder="Button Text"
            value={btnText}
            onChange={(e) => setProp((prop) => (prop.btnText = e.target.value))}
          />

          <Divider />

          <Typography level="title-sm">Navigation URL</Typography>
          <LinkComponentSettings
            onSettingsChange={(settings) => {
              setProp((prop) => (prop.btnUrl = settings?.url));
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

EditableAboutStyle2.craft = {
  displayName: "About Style 2",

  props: {
    thumbnailSrc1: "/images/placeholder.jpg",
    thumbnailSrc2: "/images/placeholder.jpg",
    uperTitle: "About Us",
    title: "We are a creative agency",
    subTitle:
      "We are a team of talented designers making websites with Bootstrap",
    featureList: [
      "We are a team of talented designers making websites with Bootstrap",
      "We are a team of talented designers making websites with Bootstrap",
      "We are a team of talented designers making websites with Bootstrap",
    ],
    btnText: "Learn More",
    btnUrl: "/about",
  },

  related: {
    settings: ComponentSettings,
  },

  rules: {
    canDrag: () => true,
  },
};
